import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Button,
  ClassInformationForm,
  EstablishmentForm,
  Header,
  ResponsibleInformartionForm,
  StudentInformationForm
} from '../../components';

import {
  getOrganizationDetailForAdmin,
  getWSFRegionalInformation,
  putRegistrationOfSchoolForAdmin
} from '../../service';

import {
  OtherGroupSchoolRegistrationSchema,
  SchoolRegistrationSchema,
  classRegistrationTeacherSchema,
  otherGroupClassRegistrationTeacherSchema
} from '../../utils/schema';

import {
  departmentDropdownOptions,
  manualFormFieldsGroups,
  otherGroupForms,
  typeEstablishmentDropdown,
  typeQuries
} from '../../utils/values';

const getSchema = (organizationDetails, isOtherGroupForm) => {
  // Define a mapping object
  const schemaMapping = {
    'class-registration': {
      true: otherGroupClassRegistrationTeacherSchema,
      false: classRegistrationTeacherSchema
    },
    default: {
      true: OtherGroupSchoolRegistrationSchema,
      false: SchoolRegistrationSchema
    }
  };

  // Determine the key for the mapping object
  const key =
    organizationDetails?.registrationPurpose === 'class-registration'
      ? 'class-registration'
      : 'default';

  // Return the corresponding schema based on `isOtherGroupForm` value
  return schemaMapping[key][isOtherGroupForm];
};

function EditOrganizationAccount() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const translations = t('editOrganizationAccount', { returnObjects: true });
  const toastMessages = t('toastMessages', { returnObjects: true });

  const [postalcodeOptions, setPostalcodeOptions] = useState([]);
  const [establishmentNameoptions, setEstablishmentNameoptions] = useState([]);
  const [postalcodeSearch, setPostalcodeSearch] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);
  const [organizationDetails, setOrganizationDetails] = useState({});

  const classTranslations = t(`classRegistrationTeacher.${organizationDetails?.registrationType}`, {
    returnObjects: true
  });

  const isManualField = manualFormFieldsGroups.includes(organizationDetails?.registrationType);
  const manualPostalcode = isManualField;
  const manualName = isManualField;
  const manualCity = isManualField;
  const manualAddress = isManualField;
  const isDepartmentDropdown = isManualField;

  const isOtherGroupForm = otherGroupForms.includes(organizationDetails?.registrationType);

  const handleSubmit = async (values) => {
    try {
      const {
        underContract: underContractTemp,
        rep: repTemp,
        studentInformation: studentInformationTemp
      } = values;

      const rep = repTemp.split('-')[1];
      const underContract = underContractTemp.split('-')[1];

      const studentInformation = studentInformationTemp.map((student) => {
        const { visitDay, studentCount, studentLevel } = student;
        return {
          visitDay: visitDay.split('-')[0],
          studentCount,
          studentLevel: studentLevel.split('-')[0]
        };
      });

      await putRegistrationOfSchoolForAdmin(values._id, {
        ...values,
        ...organizationDetails,
        studentInformation,
        rep,
        underContract
      });

      toast.success(toastMessages.recordUpdated);
      getPageData();
    } catch (err) {
      const message = err?.response?.data?.message || toastMessages.error;
      console.log(err);
      toast.error(message);
    }
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues:
      organizationDetails?.registrationPurpose === 'class-registration'
        ? {
            postalcode: '',
            name: '',
            city: '',
            address: '',
            department: '',
            type: '',
            underContract: '',
            rep: '',
            responsible: [
              {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                position: '',
                classes: [
                  {
                    classLevel: '',
                    classCount: '',
                    supervisorCount: '',
                    visitorsCount: '',
                    classNumber: '',
                    visitDay: '',
                    underContract: '',
                    estimatedArrivalTime: '',
                    estimatedTimeOnSite: ''
                  }
                ]
              }
            ]
          }
        : {
            postalcode: '',
            name: '',
            city: '',
            address: '',
            department: '',
            type: '',
            underContract: '',
            rep: '',
            // responsible1
            responsible: [
              {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                position: ''
              },
              {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                position: ''
              }
            ],
            studentInformation: [
              {
                studentLevel: '',
                studentCount: '',
                visitDay: ''
              }
            ]
          },
    validationSchema: getSchema(organizationDetails, isOtherGroupForm)
  });

  const getPageData = async () => {
    try {
      const {
        organization: {
          underContract: tempUnderContract = '',
          rep: tempRep = '',
          studentInformation: tempStudentInformation = [],
          responsible: tempResponsible = [],
          registrationGroup,
          registrationType,
          registrationPurpose,
          ...data
        }
      } = await getOrganizationDetailForAdmin(id);

      setOrganizationDetails({ registrationGroup, registrationType, registrationPurpose });

      const underContract = 'underContract-' + tempUnderContract;
      const rep = 'rep-' + tempRep;

      const studentInformation = tempStudentInformation.map((student, index) => {
        return {
          studentLevel: student.studentLevel,
          studentCount: student.studentCount,
          visitDay: student.visitDay + '-' + index
        };
      });

      setPostalcodeSearch(data.postalcode);

      formik.setValues({
        ...data,
        studentInformation,
        underContract,
        responsible: tempResponsible.filter((responsible) => responsible.role === 'responsible'),
        rep
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(() => {
    if (isEmpty(formik.values.type) || manualPostalcode) return;

    const where = typeQuries[formik.values.type];

    if (!firstLoad) {
      setPostalcodeSearch('');
      formik.setFieldValue('postalcode', '');
      formik.setFieldValue('name', '');
      formik.setFieldValue('city', '');
      formik.setFieldValue('address', '');
      formik.setFieldValue('department', '');
    }

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({ where });

        results.forEach((result) => {
          setPostalcodeOptions((prev) => [
            ...prev,
            {
              value: result.code_postal,
              label: result.code_postal
            }
          ]);
        });
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [formik.values.type]);

  useEffect(() => {
    if (isEmpty(postalcodeSearch) || isEmpty(formik.values.type) || manualPostalcode) return;

    const where = typeQuries[formik.values.type];

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({
          where: `startswith(code_postal,'${postalcodeSearch}') AND ${where}`
        });
        const currentPostalcodeOptions = [];

        for (const result of results) {
          if (!currentPostalcodeOptions.some((option) => option.value === result.code_postal))
            currentPostalcodeOptions.push({
              value: result.code_postal,
              label: result.code_postal
            });
        }
        setPostalcodeOptions(currentPostalcodeOptions);
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [postalcodeSearch]);

  useEffect(() => {
    if (isEmpty(formik.values.postalcode) || isEmpty(formik.values.type) || manualName) return;

    const where = typeQuries[formik.values.type];

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({
          where: `code_postal="${postalcodeSearch}" AND ${where}`
        });
        setEstablishmentNameoptions([]);

        results.forEach((result) => {
          setEstablishmentNameoptions((prev) => [
            ...prev,
            {
              value: result.nom_etablissement,
              label: result.nom_etablissement,
              ...result
            }
          ]);
        });
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [formik.values.postalcode]);

  useEffect(() => {
    if (
      isEmpty(formik.values.postalcode) ||
      isEmpty(formik.values.type) ||
      isEmpty(formik.values.name) ||
      isEmpty(establishmentNameoptions)
    )
      return;

    const slectedObject = establishmentNameoptions.find(
      (option) => option.value === formik.values.name
    );

    formik.setFieldValue('city', slectedObject?.nom_commune || '');
    formik.setFieldValue('address', slectedObject?.adresse_1 || '');
    formik.setFieldValue('department', slectedObject?.code_departement);
    setFirstLoad(false);
  }, [formik.values.name, establishmentNameoptions]);

  return (
    <main className="min-h-screen bg-white pb-10">
      <Header isAuthenticated adminHeader backgroundColor="bg-white" />
      <div className="container mt-5 mx-auto">
        <Button
          className="text-black flex items-center justify-center px-4"
          backgroundColor="bg-gray-200"
          type="button"
          internalLink
          // to={`/admin/organization/${id}`}
          onClick={() => navigate(-1)}>
          <FaAngleLeft className="text-xl" />
        </Button>
      </div>
      <form onSubmit={formik.handleSubmit} className="container mt-5 mx-auto">
        <EstablishmentForm
          inputLight
          formik={formik}
          manualPostalcode={manualPostalcode}
          manualName={manualName}
          manualCity={manualCity}
          manualAddress={manualAddress}
          isDepartmentDropdown={isDepartmentDropdown}
          departmentDropdownOptions={
            departmentDropdownOptions[organizationDetails?.registrationType]
          }
          typeDropdown={
            organizationDetails?.registrationType === 'foreign-group'
              ? typeEstablishmentDropdown[
                  `${organizationDetails?.registrationType}-${i18n.language}`
                ]
              : typeEstablishmentDropdown[organizationDetails?.registrationType]
          }
          postalcodeOptions={postalcodeOptions}
          postalcodeSearch={postalcodeSearch}
          setPostalcodeSearch={setPostalcodeSearch}
          establishmentNameoptions={establishmentNameoptions}
          otherGroupForm={isOtherGroupForm}
          foreignGroupForm={organizationDetails?.registrationType === 'foreign-group'}
        />

        {isOtherGroupForm && organizationDetails?.registrationPurpose === 'class-registration' ? (
          formik.values.responsible.map((_, index) => (
            <div key={index}>
              <ResponsibleInformartionForm
                formik={formik}
                index={index}
                title={classTranslations?.responsibleInformartionForm?.title}
                infoHelperText={classTranslations?.responsibleInformartionForm?.infoHelperText}
                inputLight
                darkText
              />

              <ClassInformationForm
                title={classTranslations?.classInformationFormTitle}
                responsibleIndex={index}
                formik={formik}
                otherGroupForm={isOtherGroupForm}
                inputLight
                darkText
              />
            </div>
          ))
        ) : (
          <>
            <StudentInformationForm
              formik={formik}
              otherGroupForm={isOtherGroupForm}
              inputLight
              darkText
            />
            <ResponsibleInformartionForm
              formik={formik}
              index={0}
              title={translations?.responsibleInformartionForm1?.title}
              inputLight
              darkText
            />
            <ResponsibleInformartionForm
              formik={formik}
              index={1}
              title={translations?.responsibleInformartionForm2?.title}
              inputLight
              darkText
            />
          </>
        )}
        <Button
          className="text-black px-4 font-normal uppercase"
          backgroundColor="bg-gray-200"
          type="submit">
          {translations?.submit}
        </Button>

        <section className="py-10" dangerouslySetInnerHTML={{ __html: translations?.bottomInfo }} />
      </form>
    </main>
  );
}

export default EditOrganizationAccount;
