import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaBell } from 'react-icons/fa';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import {
  Badge,
  Button,
  ColumnVisibilityModel,
  DeleteOrganizationConfirmationPopover,
  FilterModel,
  Header,
  InfoBox,
  Loading,
  OpenNotificationModel
} from '../../components';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IoCloseCircle } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { ExportIcon, FunnelIcon, VisibilityButtonIcon } from '../../assets';
import {
  addNotes,
  getAllOrganizations,
  getNotesByType,
  getAllClassOfOrganizations
} from '../../service';
import { exportData } from '../../utils/export';
import { otherGroupForms } from '../../utils/values';

import { initialTableHeaders as initialTableHeadersClass } from './organizationDetail';

const visitDays = [
  '11.09',
  'old_11.09',
  '12.09',
  'old_12.09',
  '13.09',
  'old_13.09',
  '14.09',
  'old_14.09'
];

const initialTableHeaders = [
  { key: 'name', name: 'Nom de l’établissement' },
  { key: 'createdAt', name: "Date d'enregistrement" },
  { key: 'type', name: 'Type d’établissement' },
  { key: 'postalcode', name: 'Code postal de l’établissement' },
  { key: 'city', name: 'Ville de l’établissement' },
  { key: 'address', name: 'Adresse de l’établissement' },
  { key: 'department', name: 'Département de l’établissement', foreignGroupTitle: 'PAYS' },
  { key: 'underContract', name: 'Etablissement privé SOUS CONTRAT', hideForOtherGroups: true },
  { key: 'rep', name: 'Rep / Rep+', hideForOtherGroups: true },
  // responsible details
  { key: 'responsibleName', name: 'PERSONNE DE CONTACT 1' },
  { key: 'email', name: 'Email' },
  { key: 'phone', name: 'TÉLÈPHONE' },
  { key: 'old_11.09', name: 'Prévisions 11.09', withOldClasses: true },
  { key: '11.09', name: 'Total actuel 11.09' },
  { key: 'old_12.09', name: 'Prévisions 12.09', withOldClasses: true },
  { key: '12.09', name: 'Total actuel 12.09' },
  { key: 'old_13.09', name: 'Prévisions 13.09', withOldClasses: true },
  { key: '13.09', name: 'Total actuel 13.09' },
  { key: 'old_14.09', name: 'Prévisions 14.09', withOldClasses: true },
  { key: '14.09', name: 'Total actuel 14.09' },
  {
    key: 'totalOldStudents',
    name: "Prévisions NOMBRE D'ÉLÈVES PRÉVUS TOTAL",
    otherGroupsTitle: 'Prévisions NOMBRE DE PERSONNES PRÉVUES',
    withOldClasses: true
  },
  {
    key: 'totalStudents',
    name: "NOMBRE D'ÉLÈVES PRÉVUS TOTAL",
    otherGroupsTitle: 'NOMBRE DE PERSONNES PRÉVUES'
  },
  { key: 'delete', name: 'Supprimer', excludeForExportAndFilters: true }
];

function OrganizationListing() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const topScrollbarRef = useRef(null);
  const tableContainerRef = useRef(null);
  const searchInput = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);
  const { t } = useTranslation();

  const [organizations, setOrganizations] = useState([]);
  const [filterModel, setFilterModel] = useState(false);
  const [columnsModel, setColumnsModel] = useState(false);
  const [notificationModel, setNotificationModel] = useState(false);
  const [pagination, setPagination] = useState({ page: 1 });
  const [isExporting, setIsExporting] = useState(false);
  const [isExportingClass, setIsExportingClass] = useState(false);

  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [notesData, setNotesData] = useState([]);

  const categoryName = searchParams.get('categoryName');
  const type = searchParams.get('type');
  const registrationPurpose = searchParams.get('registrationPurpose');
  const registrationType = searchParams.get('registrationType');
  const hasClasses = searchParams.get('hasClasses');
  const region = searchParams.get('region');

  const isOtherGroups = otherGroupForms.includes(registrationType);
  const isForeignGroup = registrationType === 'foreign-group';

  const tH = initialTableHeaders
    .filter((tableHeader) => {
      if (
        isOtherGroups && tableHeader.hideForOtherGroups
          ? null
          : // : registrationPurpose === 'class-registration'
            //   ? tableHeader
            registrationPurpose === 'school-registration' && hasClasses === 'true'
            ? tableHeader
            : !tableHeader.withOldClasses
      ) {
        return true;
      } else {
        return false;
      }
    })
    .map((tableHeader) => {
      const updatedHeaderName =
        isOtherGroups && tableHeader.otherGroupsTitle
          ? tableHeader.otherGroupsTitle
          : isForeignGroup && tableHeader.foreignGroupTitle
            ? tableHeader.foreignGroupTitle
            : tableHeader.name;

      const link =
        registrationPurpose === 'school-registration' && hasClasses === 'false'
          ? `/admin/organization-edit/`
          : null;

      return {
        ...tableHeader,
        name: updatedHeaderName,
        isSelected: true,
        link
      };
    });
  const [tableHeaders, setTableHeaders] = useState(tH);
  const [filteredHeaders, setFilteredHeaders] = useState([...tableHeaders]);

  useEffect(() => {
    if (isEmpty(categoryName) || isEmpty(type)) return navigate('/admin');
    getPageData();
  }, []);

  const getPageData = async (requestPage = 1, search = '', forExport = false) => {
    try {
      let typeLocal = type.toLocaleLowerCase();
      if (type.toLocaleLowerCase() === 'tous les groupes') typeLocal = undefined;
      const {
        data: organizations,
        page,
        totalPages
      } = await getAllOrganizations({
        type: typeLocal,
        registrationPurpose,
        registrationType,
        hasClasses,
        page: requestPage,
        region,
        search,
        forExport
      });
      if (forExport) {
        return organizations;
      }
      setPagination({ page, totalPages });
      setOrganizations(organizations);
      setFilteredOrganizations(organizations);
      const notes = await getNotesByType({
        type: type,
        registrationPurpose
      });
      setNotesData(notes);
    } catch (error) {
      console.log(error);
    }
  };

  const getClassPageData = async (requestPage = 1, search = '', forExport = false) => {
    try {
      let typeLocal = type.toLocaleLowerCase();
      if (type.toLocaleLowerCase() === 'tous les groupes') typeLocal = undefined;
      const { data } = await getAllClassOfOrganizations({
        type: typeLocal,
        registrationPurpose,
        registrationType,
        hasClasses,
        page: requestPage,
        region,
        search,
        forExport
      });

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterUpdate = (formValues) => {
    console.log({ formValues });

    // Convert formValues into an array of filter criteria
    const criteria = Object.keys(formValues).reduce((acc, key) => {
      if (key.startsWith('dropdown_')) {
        const index = key.split('_')[1];
        const valueKey = `searchField_${index}`;
        // Ensure we have a pair before adding
        if (formValues[valueKey] !== undefined) {
          acc.push({ key: formValues[key], value: formValues[valueKey] });
        }
      }
      return acc;
    }, []);

    // Filter based on criteria
    const filteredOrganizations = organizations.filter((classItem) =>
      criteria.every(({ key, value }) => {
        console.log(key, value);
        if (['email', 'phone'].includes(key)) {
          return classItem.responsible?.[key] === value;
        } else if (visitDays.includes(key)) {
          return classItem.visitDays?.[key] === value;
        } else if (key === 'responsibleName') {
          return `${classItem.responsible?.firstName} ${classItem.responsible?.lastName}` === value;
        } else return classItem[key] === value;
      })
    );

    setFilteredOrganizations(filteredOrganizations);

    setFilterModel(false);
  };

  const handleDataExport = async () => {
    try {
      setIsExporting(true);
      const exportItems = await getPageData(1, '', true);
      const filteredExportHeaders = tableHeaders.filter(
        (tableHeader) => !tableHeader.excludeForExportAndFilters
      );
      exportData(filteredExportHeaders, getOrganizationsForExportAndFilters(exportItems));
    } catch (error) {
      toast.error("Échec de l'exportation");
      console.error('Failed to export: ', error);
    } finally {
      setIsExporting(false);
    }
  };

  const getClassesForExportAndFilters = (classes) => {
    const formattedClasses = JSON.parse(JSON.stringify(classes));
    return formattedClasses.map((formattedClass) => {
      const {
        teacher: { firstName, lastName, position, email, phone },
        organization: { name: organizationName }
      } = formattedClass;
      return {
        ...formattedClass,
        organizationName,
        teacherName: `${firstName} ${lastName}`,
        position,
        email,
        phone
      };
    });
  };

  const handleClassDataExport = async () => {
    try {
      setIsExportingClass(true);
      const exportItems = await getClassPageData(1, '', true);

      exportData(initialTableHeadersClass, getClassesForExportAndFilters(exportItems));
    } catch (error) {
      toast.error("Échec de l'exportation");
      console.error('Failed to export: ', error);
    } finally {
      setIsExportingClass(false);
    }
  };

  const handleColumnVisibilityUpdate = (updatedColumns) => {
    setTableHeaders(updatedColumns);
    setFilteredHeaders(updatedColumns.filter((updatedColumn) => updatedColumn.isSelected));
    setColumnsModel(false);
  };

  const getOrganizationsForExportAndFilters = (organizations) => {
    const formattedItems = JSON.parse(JSON.stringify(organizations));

    return formattedItems.map((formattedItem) => {
      const {
        firstName = '',
        lastName = '',
        email = '',
        phone = ''
      } = formattedItem.responsible || {};
      // Ensuring visitDays includes specified dates with default value of 0
      const requiredVisitDays = visitDays;
      const updatedVisitDays = { ...formattedItem.visitDays };
      requiredVisitDays.forEach((day) => {
        if (!updatedVisitDays.hasOwnProperty(day)) {
          updatedVisitDays[day] = 0;
        }
      });

      return {
        ...formattedItem,
        responsibleName: `${firstName} ${lastName}`,
        email,
        phone,
        ...updatedVisitDays
      };
    });
  };

  const handleNotificationSubmit = async (values) => {
    console.log(values);
    try {
      const reqBody = {
        note: values,
        type: type,
        registrationPurpose: registrationPurpose
      };
      const response = await addNotes(reqBody);
      console.log(response);
      const notes = await getNotesByType({
        type: type,
        registrationPurpose
      });
      setNotesData(notes);
      toast.success('Réponse envoyée avec succès');
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaginationClick = (type) => {
    let newRequestPage = pagination.page || 1;

    if (type === 'previous' && pagination.page > 1) {
      newRequestPage = pagination.page - 1;
    } else if (type === 'next' && pagination.page !== pagination.totalPages) {
      newRequestPage = pagination.page + 1;
    }

    getPageData(newRequestPage);
  };

  const handleSearch = ({ target: { value } }) => {
    getPageData(pagination.page, value);
  };

  const handleSearchInputClear = () => {
    if (searchInput?.current) {
      searchInput.current.value = '';
      getPageData();
    }
  };

  // Table Scroll
  useEffect(() => {
    // Ensure the table width is set for the top scrollbar's inner div
    const tableWidth = tableContainerRef.current ? tableContainerRef.current.scrollWidth : 0;
    setTableWidth(tableWidth);

    const syncScroll = (e) => {
      if (e.target === tableContainerRef.current && topScrollbarRef.current) {
        topScrollbarRef.current.scrollLeft = e.target.scrollLeft;
      } else if (e.target === topScrollbarRef.current && tableContainerRef.current) {
        tableContainerRef.current.scrollLeft = e.target.scrollLeft;
      }
    };

    const tableContainer = tableContainerRef.current;
    const topScrollbar = topScrollbarRef.current;

    // Listen for scroll events on both the top scrollbar and the table container
    tableContainer.addEventListener('scroll', syncScroll);
    topScrollbar.addEventListener('scroll', syncScroll);

    return () => {
      // Clean up event listeners
      tableContainer.removeEventListener('scroll', syncScroll);
      topScrollbar.removeEventListener('scroll', syncScroll);
    };
  }, [filteredHeaders, filteredOrganizations]);

  return (
    <main className="min-h-screen text-black pb-10">
      <Header isAuthenticated adminHeader backgroundColor="bg-white" />
      <section className="container py-10 mx-auto">
        <div className="flex items-center justify-between my-5">
          <div className="flex items-center">
            <Button
              className="text-black flex items-center justify-center px-4"
              backgroundColor="bg-gray-200"
              type="button"
              internalLink
              to="/admin">
              <FaAngleLeft className="text-xl" />
            </Button>
            <p className="text-2xl ml-2">
              {categoryName} ({type})
            </p>
          </div>
        </div>
        <InfoBox>
          En cliquant sur le bouton Colonnes, vous pouvez choisir quelles données vous voulez
          afficher à votre écran. Le bouton Filtrer vous permet de trier les données. Le bouton
          Exporter vous permet de télécharger les données dans un tableau Excel. En cliquant sur le
          nom de l'enseignant, vous ouvrez le champ de saisie des données pour cet enseignant et
          vous pouvez y apporter des modifications.
        </InfoBox>
        <section className="container py-10 mx-auto">
          <FilterModel
            tableHeaders={tableHeaders}
            data={getOrganizationsForExportAndFilters(filteredOrganizations)}
            isOpen={filterModel}
            setOpen={setFilterModel}
            onFilterUpdate={handleFilterUpdate}
          />
          <ColumnVisibilityModel
            tableHeaders={tableHeaders}
            isOpen={columnsModel}
            setOpen={setColumnsModel}
            onUpdate={handleColumnVisibilityUpdate}
          />
          <OpenNotificationModel
            notes={notesData.notes}
            isOpen={notificationModel}
            setOpen={setNotificationModel}
            onUpdate={handleNotificationSubmit}
          />

          <div className="flex items-center justify-between my-5">
            <div className="flex items-center gap-5">
              <Button
                className="text-black flex items-center justify-center px-4 gap-2"
                type="button"
                backgroundColor="bg-gray-200"
                onClick={() => setNotificationModel(true)}>
                {/* <NotificationButtonIcon /> */}
                <FaBell />
              </Button>
              <Button
                className="text-black flex items-center justify-center px-4 gap-2"
                type="button"
                backgroundColor="bg-gray-200"
                onClick={() => setColumnsModel(true)}>
                <VisibilityButtonIcon /> Colonnes
              </Button>
              <Button
                className="text-black flex items-center justify-center px-4 gap-2"
                type="button"
                backgroundColor="bg-gray-200"
                onClick={() => setFilterModel(true)}>
                <FunnelIcon />
                Filtrer
              </Button>
              <div className="relative">
                <input
                  ref={searchInput}
                  type="text"
                  placeholder={t('adminOrgSearch')}
                  onChange={handleSearch}
                  className="outline-none border py-2 rounded-md h-12 bg-white text-black px-4 w-72"
                />
                {searchInput?.current?.value && (
                  <IoCloseCircle
                    className="absolute right-2 top-[36%] cursor-pointer fill-gray-400"
                    onClick={handleSearchInputClear}
                  />
                )}
              </div>
            </div>
            <div className="flex items-center justify-center gap-4">
              <Button
                className={clsx('text-black flex items-center justify-center px-4', {
                  'cursor-not-allowed ': isExportingClass
                })}
                type="button"
                backgroundColor={isExportingClass ? 'bg-purple-50' : 'bg-gray-200'}
                onClick={() => (isExportingClass ? null : handleClassDataExport())}>
                <Loading loading={isExportingClass} />
                {!isExportingClass && <ExportIcon className="size-6 mr-1" />}
                Exporter les données de classe
              </Button>
              <Button
                className={clsx('text-black flex items-center justify-center px-4', {
                  'cursor-not-allowed ': isExporting
                })}
                type="button"
                backgroundColor={isExporting ? 'bg-purple-50' : 'bg-gray-200'}
                onClick={() => (isExporting ? null : handleDataExport())}>
                <Loading loading={isExporting} />
                {!isExporting && <ExportIcon className="size-6 mr-1" />}
                Export
              </Button>
            </div>
          </div>
          <section className="relative">
            <div className="overflow-x-auto" ref={topScrollbarRef} style={{ height: '13px' }}>
              <div className="h-px min-w-full" style={{ width: `${tableWidth}px` }}></div>
            </div>
            <section className="overflow-x-auto" ref={tableContainerRef}>
              <table className="min-w-full">
                <thead>
                  <tr className="bg-gray-200 ">
                    {filteredHeaders?.map((tableHeader, tableHeaderIndex) => (
                      <th
                        key={tableHeader.key}
                        scope="col"
                        className={clsx(
                          'py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 border-gray-400 whitespace-nowrap border-2',
                          {
                            'pl-4 pr-3': tableHeaderIndex === 0,
                            'px-3 border-2 ': tableHeaderIndex !== 0
                          }
                        )}>
                        {tableHeader.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {filteredOrganizations.map((classObj) => (
                    <tr key={classObj._id} className="text-gray-500">
                      {filteredHeaders.map(({ key, link }, index) => (
                        <td
                          key={key}
                          className={clsx('whitespace-nowrap text-sm border-2 border-gray-400', {
                            'font-medium sm:pl-0 bg-gray-200': key === 'name',
                            'px-3 py-4 text-black': !['name', 'delete'].includes(key)
                          })}>
                          <TableCellContent
                            link={link}
                            itemKey={key}
                            classObj={classObj}
                            callableFunctions={{ getPageData }}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </section>
          {pagination?.totalPages > 1 && (
            <div className="flex items-center justify-end gap-4 my-4">
              {pagination.page > 1 && (
                <Button
                  className="text-black flex items-center justify-center px-4 hover:bg-gray-300/80"
                  type="button"
                  backgroundColor="bg-gray-200"
                  onClick={() => handlePaginationClick('previous')}>
                  <FaAngleDoubleLeft className=" mr-1" /> {t('adminTablePaginationPreviousButton')}
                </Button>
              )}
              {pagination.page !== pagination.totalPages && (
                <Button
                  className="text-black flex items-center justify-center px-4 hover:bg-gray-300/80"
                  type="button"
                  backgroundColor="bg-gray-200"
                  onClick={() => handlePaginationClick('next')}>
                  {t('adminTablePaginationNextButton')} <FaAngleDoubleRight className="ml-1" />
                </Button>
              )}
            </div>
          )}
        </section>
      </section>
    </main>
  );
}

function TableCellContent({ itemKey, link, classObj = {}, callableFunctions = {} }) {
  if (itemKey === 'name') {
    const classes = 'flex min-h-full py-4 pl-4 pr-3';
    const navigateLink = link ? `${link}${classObj?._id}` : `/admin/organization/${classObj?._id}`;
    return (
      <Link to={navigateLink} className={clsx(classes, 'cursor-pointer')}>
        {classObj[itemKey]}
      </Link>
    );
  }

  if (itemKey === 'delete') {
    return (
      <DeleteOrganizationConfirmationPopover
        organizationId={classObj._id}
        onDelete={() => callableFunctions.getPageData()}
      />
    );
  }

  if (itemKey === 'createdAt') {
    return `${new Date(classObj?.createdAt).toLocaleString()}`;
  }

  if (itemKey === 'responsibleName') {
    return `${classObj.responsible?.firstName} ${classObj.responsible?.lastName}`;
  }

  if (['email', 'phone'].includes(itemKey)) {
    return classObj.responsible?.[itemKey];
  }

  if (['underContract', 'rep'].includes(itemKey)) {
    return <Badge label={classObj[itemKey]} />;
  }

  if (visitDays.includes(itemKey)) {
    return <Badge label={classObj.visitDays?.[itemKey] || '.'} type="default" />;
  }

  // Default rendering for other keys
  return classObj[itemKey];
}

export default OrganizationListing;
