import clsx from 'clsx';
import React, { useState } from 'react';
import Popup from 'reactjs-popup';

const overlayStyle = { background: 'rgba(0,0,0,0.5)' };

const sizeClasses = {
  xs: 'w-[20rem]',
  sm: 'w-[32rem]',
  md: 'w-[44rem]',
  lg: 'w-[56rem]'
};

export default function Popover({
  children,
  size = 'sm',
  button = <div>trigger button</div>,
  position = 'bottom center',
  onOpen = () => {},
  onClose = () => {},
  ...props
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  // This function will be passed to children to allow them to close the Popup
  const closePopup = () => handleClose();

  // Clone the React element(s) in children and inject the closePopup function as a prop
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { closePopup })
  );

  return (
    <Popup
      data-testid="popover"
      {...{ overlayStyle }}
      {...props}
      nested
      open={open}
      trigger={button}
      position={position}
      onOpen={handleOpen}
      onClose={handleClose}>
      <div className={clsx('bg-white shadow-md rounded-xl p-4', sizeClasses[size])}>
        {childrenWithProps}
      </div>
    </Popup>
  );
}
