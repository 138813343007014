export const typeQuries = {
  college:
    '(libelle_nature="COLLEGE" OR libelle_nature="COLLEGE CLIMATIQUE" OR libelle_nature="COLLEGE SPECIALISE")',
  'ETABLISSEMENT SPECIALISE': `(
    libelle_nature="ECOLE SECONDAIRE SPECIALISEE (2 D CYCLE)" 
    OR libelle_nature="ECOLES COMPOSEES UNIQT DE STS ET OU CPGE" 
    OR libelle_nature="ETAB REGIONAL/LYCEE ENSEIGNEMENT ADAPTE" 
    OR libelle_nature="ETABLISSEMENT DE REINSERTION SCOLAIRE" 
    OR libelle_nature="ETABLISSEMENT MEDICO-EXPERIMENTAL" 
    OR libelle_nature="ETABLISSEMENT POUR DEFICIENTS AUDITIFS" 
    OR libelle_nature="ETABLISSEMENT POUR DEFICIENTS VISUELS" 
    OR libelle_nature="ETABLISSEMENT POUR INFIRMES MOTEURS" 
    OR libelle_nature="ETABLISSEMENT POUR POLY-HANDICAPES" 
    OR libelle_nature="ETABLISSEMENT POUR SOURDS-AVEUGLES")`,
  LEGT: `(
      libelle_nature="LYCEE CLIMATIQUE" 
      OR libelle_nature="LYCEE D ENSEIGNEMENT GENERAL" 
      OR libelle_nature="LYCEE D ENSEIGNEMENT TECHNOLOGIQUE"
      OR libelle_nature="LYCEE ENSEIGNT GENERAL ET TECHNOLOGIQUE"
      OR libelle_nature="LYCEE EXPERIMENTAL"
      OR libelle_nature="SECTION ENSEIGT GENERAL ET TECHNOLOGIQUE")
      `,
  LP: '(libelle_nature="LYCEE PROFESSIONNEL")',
  LPO: '(libelle_nature="LYCEE POLYVALENT" OR libelle_nature="SECTION D ENSEIGNEMENT PROFESSIONNEL")',

  // Elementary School
  elementarySchool: '(ecole_elementaire="1")',

  // Agriculture School
  Lycée: '(ministere_tutelle="AGRICULTURE" AND type_etablissement="Lycée")',
  Autre: '(ministere_tutelle="AGRICULTURE" AND type_etablissement="Autre")'
};

export const typeDropdown = [
  { value: 'college', label: 'Collège' },
  { value: 'ETABLISSEMENT SPECIALISE', label: 'ETABLISSEMENT SPECIALISE' },
  { value: 'LEGT', label: 'LEGT' },
  { value: 'LP', label: 'LP' },
  { value: 'LPO', label: 'LPO' }
];

export const typeDropdownElementary = [
  { value: 'elementarySchool', label: '1er degré / Primaire' }
];

export const typeDropdownAgreculture = [
  { value: 'Lycée', label: 'Lycée' },
  { value: 'Autre', label: 'Autre' }
];

export const typeDropdownCfaMfr = [
  { value: 'CFA', label: 'CFA' },
  { value: 'MFR ', label: 'MFR ' }
];

const typeDropdownOtherGroup = [
  {
    value: 'france-travail',
    label: 'France Travail'
  },
  {
    value: 'mission-locale',
    label: 'Mission Locale'
  },
  {
    value: 'mife',
    label: 'MIFE'
  },
  {
    value: 'cidff',
    label: 'CIDFF'
  },
  {
    value: 'association',
    label: 'Association'
  },
  {
    value: 'group-de-fans/supporters',
    label: 'Group de fans/supporters'
  },
  {
    value: 'entreprise',
    label: 'Entreprise'
  },
  {
    value: 'sponsor',
    label: 'Sponsor'
  },
  {
    value: 'autre',
    label: 'Autre'
  }
];

const countriesDropdownList = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia, Plurinational State of' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: "Côte d'Ivoire" },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran, Islamic Republic of' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: "Korea, Democratic People's Republic of" },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: "Lao People's Democratic Republic" },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia, the former Yugoslav Republic of' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia, Federated States of' },
  { value: 'MD', label: 'Moldova, Republic of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestinian Territory, Occupied' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan, Province of China' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'VI', label: 'Virgin Islands, U.S.' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' }
];

const typeDropdownForeignGroupFR = [
  {
    label: 'École (formation générale : primaire, secondaire...)',
    value: 'ecole-formation-generale-primaire-secondaire'
  },
  {
    label: 'École de formation professionnelle',
    value: 'ecole-de-formation-professionnelle'
  },
  {
    label: 'Groupe de fans/supporters',
    value: 'group-de-fans/supporters'
  },
  {
    label: 'Entreprise',
    value: 'entreprise'
  },
  {
    label: 'Sponsor d’une organisation Skills',
    value: 'sponsor-dune-organisation-skills'
  },
  {
    label: 'Autre',
    value: 'autre'
  }
];
const typeDropdownForeignGroupEN = [
  {
    label: 'School (general education: primary, secondary...)',
    value: 'ecole-formation-generale-primaire-secondaire'
  },
  {
    label: 'School (VAT: Vocational Education & Training)',
    value: 'ecole-de-formation-professionnelle'
  },
  {
    label: 'Fans/supporters group',
    value: 'group-de-fans/supporters'
  },
  {
    label: 'Companies',
    value: 'entreprise'
  },
  {
    label: 'Sponsor of a Skills organization',
    value: 'sponsor-dune-organisation-skills'
  },
  {
    label: 'Other',
    value: 'autre'
  }
];

export const typeEstablishmentDropdown = {
  'primary-school': typeDropdown,
  'elementary-school': typeDropdownElementary,
  'agriculture-school': typeDropdownAgreculture,
  'cfa-mfr-school': typeDropdownCfaMfr,
  'other-group': typeDropdownOtherGroup,
  'foreign-group-fr': typeDropdownForeignGroupFR,
  'foreign-group-en': typeDropdownForeignGroupEN
};

export const declarationOfInterestEnabledRegistrationType = [
  'primary-school',
  'agriculture-school'
];
export const learningSectionEnable = ['primary-school', 'agriculture-school', 'cfa-mfr-school'];

export const declarationOfInterestEnabledRegion = ['63e5af4072a32fb6d70f3ad2'];
export const informationTicketEnabledRegion = [
  '63e5af4072a32fb6d70f3ad2',
  '63eb7727d4599476f4bcf798'
];
export const informationTicketEnabledGroups = [
  'primary-school',
  'elementary-school',
  'agriculture-school'
];

export const departmentDropdown = [
  { value: '01-ain-bourg-en-bresse', label: '01 - Ain - Bourg-en-bresse' },
  { value: '02-aisne-laon', label: '02 - Aisne - Laon' },
  { value: '03-allier-moulins', label: '03 - Allier - Moulins' },
  {
    value: '04-alpes-de-haute-provence-digne-les-bains',
    label: '04 - Alpes-de-Haute-Provence - Digne-les-bains'
  },
  { value: '05-hautes-alpes-gap', label: '05 - Hautes-alpes - Gap' },
  { value: '06-alpes-maritimes-nice', label: '06 - Alpes-maritimes - Nice' },
  { value: '07-ardèche-privas', label: '07 - Ardèche - Privas' },
  { value: '08-ardennes-charleville-mézières', label: '08 - Ardennes - Charleville-mézières' },
  { value: '09-ariège-foix', label: '09 - Ariège - Foix' },
  { value: '10-aube-troyes', label: '10 - Aube - Troyes' },
  { value: '11-aude-carcassonne', label: '11 - Aude - Carcassonne' },
  { value: '12-aveyron-rodez', label: '12 - Aveyron - Rodez' },
  { value: '13-bouches-du-rhône-marseille', label: '13 - Bouches-du-Rhône - Marseille' },
  { value: '14-calvados-caen', label: '14 - Calvados - Caen' },
  { value: '15-cantal-aurillac', label: '15 - Cantal - Aurillac' },
  { value: '16-charente-angoulême', label: '16 - Charente - Angoulême' },
  { value: '17-charente-maritime-la-rochelle', label: '17 - Charente-maritime - La rochelle' },
  { value: '18-cher-bourges', label: '18 - Cher - Bourges' },
  { value: '19-corrèze-tulle', label: '19 - Corrèze - Tulle' },
  { value: '2A-corse-du-sud-ajaccio', label: '2A - Corse-du-sud - Ajaccio' },
  { value: '2B-haute-corse-bastia', label: '2B - Haute-Corse - Bastia' },
  { value: '21-côte-dor-dijon', label: "21 - Côte-d'Or - Dijon" },
  { value: '22-côtes-darmor-saint-brieuc', label: "22 - Côtes-d'Armor - Saint-brieuc" },
  { value: '23-creuse-guéret', label: '23 - Creuse - Guéret' },
  { value: '24-dordogne-périgueux', label: '24 - Dordogne - Périgueux' },
  { value: '25-doubs-besançon', label: '25 - Doubs - Besançon' },
  { value: '26-drôme-valence', label: '26 - Drôme - Valence' },
  { value: '27-eure-évreux', label: '27 - Eure - Évreux' },
  { value: '28-eure-et-loir-chartres', label: '28 - Eure-et-loir - Chartres' },
  { value: '29-finistère-quimper', label: '29 - Finistère - Quimper' },
  { value: '30-gard-nîmes', label: '30 - Gard - Nîmes' },
  { value: '31-haute-garonne-toulouse', label: '31 - Haute-garonne - Toulouse' },
  { value: '32-gers-auch', label: '32 - Gers - Auch' },
  { value: '33-gironde-bordeaux', label: '33 - Gironde - Bordeaux' },
  { value: '34-hérault-montpellier', label: '34 - Hérault - Montpellier' },
  { value: '35-ille-et-vilaine-rennes', label: '35 - Ille-et-vilaine - Rennes' },
  { value: '36-indre-châteauroux', label: '36 - Indre - Châteauroux' },
  { value: '37-indre-et-loire-tours', label: '37 - Indre-et-loire - Tours' },
  { value: '38-isère-grenoble', label: '38 - Isère - Grenoble' },
  { value: '39-jura-lons-le-saunier', label: '39 - Jura - Lons-le-saunier' },
  { value: '40-landes-mont-de-marsan', label: '40 - Landes - Mont-de-marsan' },
  { value: '41-loir-et-cher-blois', label: '41 - Loir-et-cher - Blois' },
  { value: '42-loire-saint-étienne', label: '42 - Loire - Saint-étienne' },
  { value: '43-haute-loire-le-puy-en-velay', label: '43 - Haute-loire - Le puy-en-velay' },
  { value: '44-loire-atlantique-nantes', label: '44 - Loire-atlantique - Nantes' },
  { value: '45-loiret-orléans', label: '45 - Loiret - Orléans' },
  { value: '46-lot-cahors', label: '46 - Lot - Cahors' },
  { value: '47-lot-et-garonne-agen', label: '47 - Lot-et-garonne - Agen' },
  { value: '48-lozère-mende', label: '48 - Lozère - Mende' },
  { value: '49-maine-et-loire-angers', label: '49 - Maine-et-loire - Angers' },
  { value: '50-manche-saint-lô', label: '50 - Manche - Saint-lô' },
  { value: '51-marne-châlons-en-champagne', label: '51 - Marne - Châlons-en-champagne' },
  { value: '52-haute-marne-chaumont', label: '52 - Haute-marne - Chaumont' },
  { value: '53-mayenne-laval', label: '53 - Mayenne - Laval' },
  { value: '54-meurthe-et-moselle-nancy', label: '54 - Meurthe-et-moselle - Nancy' },
  { value: '55-meuse-bar-le-duc', label: '55 - Meuse - Bar-le-duc' },
  { value: '56-morbihan-vannes', label: '56 - Morbihan - Vannes' },
  { value: '57-moselle-metz', label: '57 - Moselle - Metz' },
  { value: '58-nièvre-nevers', label: '58 - Nièvre - Nevers' },
  { value: '59-nord-lille', label: '59 - Nord - Lille' },
  { value: '60-oise-beauvais', label: '60 - Oise - Beauvais' },
  { value: '61-orne-alençon', label: '61 - Orne - Alençon' },
  { value: '62-pas-de-calais-arras', label: '62 - Pas-de-calais - Arras' },
  { value: '63-puy-de-dôme-clermont-ferrand', label: '63 - Puy-de-dôme - Clermont-ferrand' },
  { value: '64-pyrénées-atlantiques-pau', label: '64 - Pyrénées-atlantiques - Pau' },
  { value: '65-hautes-pyrénées-tarbes', label: '65 - Hautes-Pyrénées - Tarbes' },
  { value: '66-pyrénées-orientales-perpignan', label: '66 - Pyrénées-orientales - Perpignan' },
  { value: '67-bas-rhin-strasbourg', label: '67 - Bas-rhin - Strasbourg' },
  { value: '68-haut-rhin-colmar', label: '68 - Haut-rhin - Colmar' },
  { value: '69-rhône-lyon', label: '69 - Rhône - Lyon' },
  { value: '70-haute-saône-vesoul', label: '70 - Haute-saône - Vesoul' },
  { value: '71-saône-et-loire-mâcon', label: '71 - Saône-et-loire - Mâcon' },
  { value: '72-sarthe-le-mans', label: '72 - Sarthe - Le mans' },
  { value: '73-savoie-chambéry', label: '73 - Savoie - Chambéry' },
  { value: '74-haute-savoie-annecy', label: '74 - Haute-savoie - Annecy' },
  { value: '75-paris-paris', label: '75 - Paris - Paris' },
  { value: '76-seine-maritime-rouen', label: '76 - Seine-maritime - Rouen' },
  { value: '77-seine-et-marne-melun', label: '77 - Seine-et-marne - Melun' },
  { value: '78-yvelines-versailles', label: '78 - Yvelines - Versailles' },
  { value: '79-deux-sèvres-niort', label: '79 - Deux-sèvres - Niort' },
  { value: '80-somme-amiens', label: '80 - Somme - Amiens' },
  { value: '81-tarn-albi', label: '81 - Tarn - Albi' },
  { value: '82-tarn-et-garonne-montauban', label: '82 - Tarn-et-Garonne - Montauban' },
  { value: '83-var-toulon', label: '83 - Var - Toulon' },
  { value: '84-vaucluse-avignon', label: '84 - Vaucluse - Avignon' },
  { value: '85-vendée-la-roche-sur-yon', label: '85 - Vendée - La roche-sur-yon' },
  { value: '86-vienne-poitiers', label: '86 - Vienne - Poitiers' },
  { value: '87-haute-vienne-limoges', label: '87 - Haute-vienne - Limoges' },
  { value: '88-vosges-épinal', label: '88 - Vosges - Épinal' },
  { value: '89-yonne-auxerre', label: '89 - Yonne - Auxerre' },
  { value: '90-territoire-de-belfort-belfort', label: '90 - Territoire de belfort - Belfort' },
  { value: '91-essonne-évry', label: '91 - Essonne - Évry' },
  { value: '92-hauts-de-seine-nanterre', label: '92 - Hauts-de-seine - Nanterre' },
  { value: '93-seine-saint-denis-bobigny', label: '93 - Seine-Saint-Denis - Bobigny' },
  { value: '94-val-de-marne-créteil', label: '94 - Val-de-marne - Créteil' },
  { value: '95-val-doise-cergy-pontoise', label: "95 - Val-d'Oise - Cergy Pontoise" },
  { value: '971-guadeloupe-basse-terre', label: '971 - Guadeloupe - Basse-terre' },
  { value: '972-martinique-fort-de-france', label: '972 - Martinique - Fort-de-france' },
  { value: '973-guyane-cayenne', label: '973 - Guyane - Cayenne' },
  { value: '974-la-réunion-saint-denis', label: '974 - La réunion - Saint-denis' },
  { value: '976-mayotte-mamoudzou', label: '976 - Mayotte - Mamoudzou' }
];

export const departmentDropdownOptions = {
  'cfa-mfr-school': departmentDropdown,
  'other-group': departmentDropdown,
  'foreign-group': countriesDropdownList
};

export const manualFormFieldsGroups = ['cfa-mfr-school', 'other-group', 'foreign-group'];

export const otherGroupForms = ['other-group', 'foreign-group'];

export const registrationTypes = [
  { _id: 'all', label: 'APERÇU' },
  {
    _id: 'primary-school',
    label: 'SCOLAIRE',
    heading:
      'ENREGISTREMENT ÉTABLISSEMENTS SCOLAIRES (Collège, LEGT, LP, LPO, Établissement Spécialisé)'
  },
  {
    _id: 'elementary-school',
    label: 'ÉLEMENTAIRE',
    heading: 'ENREGISTREMENT ÉTABLISSEMENTS SCOLAIRES ÉLÉMENTAIRES (1er degré / Primaire)'
  },
  {
    _id: 'agriculture-school',
    label: 'AGRICOLE',
    heading: 'ENREGISTREMENT ÉTABLISSEMENTS D’ENSEIGNEMENT AGRICOLE (Lycée, Autre)'
  },
  {
    _id: 'cfa-mfr-school',
    label: 'CFA & MFR',
    heading: 'ENREGISTREMENT ÉTABLISSEMENTS CFA & MFR (CFA, MFR)'
  },
  {
    _id: 'other-group',
    label: 'AUTRE GROUPE',
    heading:
      'ENREGISTREMENT AUTRES GROUPES (France Travail, Mission Locale, MIFE, CIDFF, Association, Groupe de fans, Enterprise, Sponsor, Autre)'
  },
  {
    _id: 'foreign-group',
    label: 'GROUPE ÉTRANGER',
    heading:
      'ENREGISTREMENT DES GROUPES ÉTRANGERS (École, École de formation professionnelle, Groupe de fans/supporters, Entreprise, Sponsor d’une organisation Skills, Autre)'
  }
];
