import { FaCheck } from 'react-icons/fa';

const Radio = ({
  isChecked,
  onChange = () => {},
  isInline = false,
  darkLabel = false,
  name = '',
  id,
  label,
  inputLight,
  value,
  disabled,
  ...props
}) => {
  return (
    <div className={`relative ${isInline ? 'flex items-center' : ''}`}>
      <p
        className={`text-center ${isInline ? ' ml-1' : 'ml-4'} ${
          darkLabel ? 'text-red-550' : 'text-white'
        }`}>
        {label}
      </p>

      <input
        type="radio"
        id={id}
        name={name}
        checked={value === id}
        onChange={() => onChange({ target: { name, value: id } })}
        className="hidden"
        disabled={disabled}
        {...props}
      />

      <label htmlFor={id} className="container-clip h-12 w-20">
        <div
          className={`clip-item ${inputLight ? 'bg-white' : 'bg-blue-999'} ${disabled && 'bg-gray-400 bg-opacity-90'} h-full`}>
          {value === id && (
            <FaCheck data-testid="fa-check" className="text-red-550 text-5xl mx-auto " />
          )}
        </div>
      </label>
    </div>
  );
};

export default Radio;
