import React from 'react';

import { CloseIcon } from '../../assets';

const Popup = ({ open, setOpen, bottomPadding = true, autoHeight = true, children }) => {
  return (
    <div
      className={`h-screen sm:py-[5%] sm:overflow-y-auto fixed top-0 left-0 z-50 w-full backdrop-blur-sm items-center justify-center ${open ? 'flex' : 'hidden'}`}>
      <div
        className={`max-h-[70%] ${autoHeight ? 'h-auto' : 'h-full sm:max-h-[100%]'} bg-white shadow-[0px_0px_15px_11px_#71717126] p-4 rounded-3xl rounded-b-none sm:rounded-b-3xl w-full absolute bottom-0 left-0 right-0 sm:max-w-md sm:static`}>
        <div className="relative flex items-center justify-between mb-4">
          <span />
          <span className="block sm:hidden h-[2px] w-20 rounded-lg bg-gray-100 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
          <span className="w-8 h-8 cursor-pointer" onClick={() => setOpen(false)}>
            <CloseIcon />
          </span>
        </div>

        <div
          className={`h-full max-h-[80%] overflow-y-auto sm:max-h-auto sm:h-fit sm:max-h-auto px-2 ${bottomPadding ? 'pb-40 sm:pb-10' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
