import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const MenuItem = ({ title, subMenuItems, isOpenByDefault, url = null }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(isOpenByDefault || false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsSubMenuOpen(true)}
      onMouseLeave={() => setIsSubMenuOpen(false)}>
      <a
        href={url}
        className="hover:text-gray-300 font-bold text-base lg:min-h-20 pt-4 flex items-center">
        {title}
      </a>
      {subMenuItems && isSubMenuOpen && (
        <ul className="relative lg:absolute min-w-72 lg:left-1/2 lg:transform lg:-translate-x-1/2 rotate-0 top-full  pl-3 lg:pl-0 lg:bg-gray-900 text-white py-2 lg:shadow list-none px-0">
          <span className="border-bg-blue-999 hidden lg:flex w-7 h-7  border-x-transparent absolute left-1/2 transform -translate-x-1/2 rotate-0 -top-7 border-t-[transparent] border-8"></span>
          {subMenuItems.map((item, index) => (
            <li key={index} className="py-1">
              <a
                href={item.link}
                className="hover:text-gray-300 text-blue-10 lg:text-white hover:underline font-bold text-base  lg:uppercase lg:py-4 flex lg:px-7">
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEnglish, setIsEnglish] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    window.location.href.includes('foreign') ? setIsEnglish(false) : setIsEnglish(true);
  }, []);

  const menuHeader = t('MenuHeader', { returnObjects: true });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const subMenuItems1 = [
    {
      name: menuHeader.menus.menu1.subMenu.menu1Title,
      link: menuHeader.menus.menu1.subMenu.menu1URL
    },
    {
      name: menuHeader.menus.menu1.subMenu.menu2Title,
      link: menuHeader.menus.menu1.subMenu.menu2URL
    },
    {
      name: menuHeader.menus.menu1.subMenu.menu3Title,
      link: menuHeader.menus.menu1.subMenu.menu3URL
    }
  ];

  const subMenuItems2 = [
    {
      name: menuHeader.menus.menu2.subMenu.menu1Title,
      link: menuHeader.menus.menu2.subMenu.menu1URL
    },
    {
      name: menuHeader.menus.menu2.subMenu.menu2Title,
      link: menuHeader.menus.menu2.subMenu.menu2URL
    },
    {
      name: menuHeader.menus.menu2.subMenu.menu3Title,
      link: menuHeader.menus.menu2.subMenu.menu3URL
    }
  ];

  const subMenuItems3 = [
    {
      name: menuHeader.menus.menu3.subMenu.menu1Title,
      link: menuHeader.menus.menu3.subMenu.menu1URL
    },
    {
      name: menuHeader.menus.menu3.subMenu.menu2Title,
      link: menuHeader.menus.menu3.subMenu.menu2URL
    },
    {
      name: menuHeader.menus.menu3.subMenu.menu3Title,
      link: menuHeader.menus.menu3.subMenu.menu3URL
    },
    {
      name: menuHeader.menus.menu3.subMenu.menu4Title,
      link: menuHeader.menus.menu3.subMenu.menu4URL
    },
    {
      name: menuHeader.menus.menu3.subMenu.menu5Title,
      link: menuHeader.menus.menu3.subMenu.menu5URL
    }
  ];

  const subMenuItems4 = [
    {
      name: menuHeader.menus.menu4.subMenu.menu1Title,
      link: menuHeader.menus.menu4.subMenu.menu1URL
    }
  ];

  const subMenuItems5 = [
    {
      name: menuHeader.menus.menu5.subMenu.menu1Title,
      link: menuHeader.menus.menu5.subMenu.menu1URL
    },
    {
      name: menuHeader.menus.menu5.subMenu.menu2Title,
      link: menuHeader.menus.menu5.subMenu.menu2URL
    },
    {
      name: menuHeader.menus.menu5.subMenu.menu3Title,
      link: menuHeader.menus.menu5.subMenu.menu3URL
    },
    {
      name: menuHeader.menus.menu5.subMenu.menu4Title,
      link: menuHeader.menus.menu5.subMenu.menu4URL
    }
  ];

  const subMenuItems6 = [
    {
      name: menuHeader.menus.menu6.subMenu.menu1Title,
      link: menuHeader.menus.menu6.subMenu.menu1URL
    },
    {
      name: menuHeader.menus.menu6.subMenu.menu2Title,
      link: menuHeader.menus.menu6.subMenu.menu2URL
    }
  ];
  const subMenuItems9 = [
    {
      name: menuHeader.menus.menu9.subMenu?.menu1Title,
      link: menuHeader.menus.menu9.subMenu?.menu1URL
    },
    {
      name: menuHeader.menus.menu9.subMenu?.menu2Title,
      link: menuHeader.menus.menu9.subMenu?.menu2URL
    }
  ];

  return (
    <>
      <header className="bg-purple-950 sticky">
        <div className="max-w-full px-12">
          <div className="flex items-center justify-between">
            <div className="">
              <img
                src="/wsl-logo.svg"
                alt="wsf-logo"
                className="max-h-20 relative h-full lg:max-h-40 py-4"
              />
            </div>
            {/* Desktop menu */}
            <div className="lg:w-[80%] hidden lg:flex flex-col">
              <div className="flex min-h-14 gap-x-1 items-center border-b border-blue-10 border-solid">
                <Link
                  to={'/registration'}
                  className={`${!isEnglish ? 'text-blue-10' : 'text-white'} uppercase text-sm`}
                  href={menuHeader.mainMenu.mainMenu1URL}>
                  {menuHeader.mainMenu.mainMenu1}
                </Link>
                <span className="text-blue-10">/</span>
                <Link
                  to={'/registration-foreign'}
                  className={`${isEnglish ? 'text-blue-10' : 'text-white'} uppercase text-sm`}
                  href={menuHeader.mainMenu.mainMenu2URL}>
                  {menuHeader.mainMenu.mainMenu2}
                </Link>
              </div>
              <div className="hidden lg:flex items-center justify-between">
                <nav className={`hidden lg:flex space-x-4 xl:space-x-10 text-white`}>
                  <MenuItem
                    title={menuHeader.menus.menu1.title}
                    subMenuItems={subMenuItems1}
                    isOpenByDefault={false}
                  />
                  <MenuItem
                    title={menuHeader.menus.menu2.title}
                    subMenuItems={subMenuItems2}
                    isOpenByDefault={false}
                  />
                  <MenuItem
                    title={menuHeader.menus.menu3.title}
                    subMenuItems={subMenuItems3}
                    isOpenByDefault={false}
                  />
                  <MenuItem
                    title={menuHeader.menus.menu4.title}
                    subMenuItems={subMenuItems4}
                    isOpenByDefault={false}
                  />
                  <MenuItem
                    title={menuHeader.menus.menu5.title}
                    subMenuItems={subMenuItems5}
                    isOpenByDefault={false}
                  />
                  <MenuItem
                    title={menuHeader.menus.menu6.title}
                    subMenuItems={subMenuItems6}
                    isOpenByDefault={false}
                  />
                  {/* <MenuItem
                    title={menuHeader.menus.menu7.title}
                    subMenuItems={false}
                    isOpenByDefault={false}
                    url={menuHeader.menus.menu7.url}
                  />
                  <MenuItem
                    title={menuHeader.menus.menu9?.title}
                    isOpenByDefault={false}
                    subMenuItems={subMenuItems9}
                  /> */}
                  <div className="flex items-center ml-12">
                    <a
                      className="hover:text-gray-300 text-white h-12 text-base xl:text-base uppercase font-bold tilted-border-left tilted-border-left-color-red flex items-center"
                      href={menuHeader.menus.menu8.url}>
                      {menuHeader.menus.menu8.title}
                    </a>
                  </div>
                </nav>
              </div>
            </div>
            {/* Hamburger menu */}
            <div className="lg:hidden">
              <button onClick={toggleMenu} className="text-white focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
      {/* Side panel menu */}
      <div
        className={`lg:hidden fixed inset-0 bg-gray-800 bg-opacity-75 z-10 ${
          isMenuOpen ? 'block' : 'hidden'
        }`}
        onClick={toggleMenu}></div>
      <nav
        className={`lg:hidden fixed inset-y-0 left-0 w-full bg-purple-950 text-white z-50 transform ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}>
        <div className="px-5 flex items-center justify-between">
          <img
            src="https://worldskills2024.com/wp-content/themes/webqam/assets/images/header/logos/wsl-samsung-logo.svg"
            alt="wsf-logo"
            className="max-h-[80px] relative h-full lg:max-h-[130px] py-4"
          />
          <div className="flex justify-end">
            <button onClick={toggleMenu} className="text-white focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="px-5 pt-5 pb-28 h-full bg-blue-999 relative z-50 min-h-[calc(100vh-75px)] overflow-y-scroll ">
          <MenuItem
            title={menuHeader.menus.menu1.title}
            subMenuItems={subMenuItems1}
            isOpenByDefault={true}
          />
          <MenuItem
            title={menuHeader.menus.menu2.title}
            subMenuItems={subMenuItems2}
            isOpenByDefault={true}
          />
          <MenuItem
            title={menuHeader.menus.menu3.title}
            subMenuItems={subMenuItems3}
            isOpenByDefault={true}
          />
          <MenuItem
            title={menuHeader.menus.menu4.title}
            subMenuItems={subMenuItems4}
            isOpenByDefault={true}
          />
          <MenuItem
            title={menuHeader.menus.menu5.title}
            subMenuItems={subMenuItems5}
            isOpenByDefault={true}
          />
          <MenuItem
            title={menuHeader.menus.menu6.title}
            subMenuItems={subMenuItems6}
            isOpenByDefault={true}
          />
          <MenuItem
            title={menuHeader.menus.menu7.title}
            subMenuItems={false}
            isOpenByDefault={false}
            url={menuHeader.menus.menu7.url}
          />
          <MenuItem
            title={menuHeader.menus.menu9?.title}
            isOpenByDefault={true}
            subMenuItems={subMenuItems9}
          />
        </div>
      </nav>
    </>
  );
};

export default Header;
