import { useTranslation } from 'react-i18next';
import { FaAngleLeft } from 'react-icons/fa';
import { Button } from '../common';

import React, { useEffect, useState } from 'react';
import { RadioIcon } from '../../assets';

function ColumnVisibilityModel({ isOpen = false, setOpen, tableHeaders = [], onUpdate }) {
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(tableHeaders);
  }, [tableHeaders]);

  const handleOptionClick = (optionIndex) => {
    const updatedOptions = JSON.parse(JSON.stringify(options));
    updatedOptions[optionIndex].isSelected = !updatedOptions[optionIndex].isSelected;
    setOptions(updatedOptions);
  };

  const handleApply = () => {
    onUpdate(options);
  };

  const handleReset = () => {
    const updatedOptions = JSON.parse(JSON.stringify(options));
    setOptions(
      updatedOptions.map((updatedOption) => ({
        ...updatedOption,
        isSelected: true
      }))
    );
  };

  return (
    isOpen && (
      <div
        data-testid="column-visibility-model"
        className="fixed inset-0 flex items-center justify-center max-w-xl min-w-64 mx-auto z-50">
        <div className="bg-white shadow rounded-2xl w-full">
          <div className="bg-gray-100 flex">
            <div className="flex items-center" data-testid="back-button-container">
              <Button
                className="flex items-center justify-center px-4 text-white"
                backgroundColor="bg-gray-800"
                type="button"
                onClick={() => setOpen(false)}>
                <FaAngleLeft className="text-xl" />
              </Button>
            </div>
          </div>
          <ul className="m-4 h-[450px] overflow-y-auto list-none px-0">
            {options.map((option, optionIndex) => (
              <li key={option.key}>
                <button
                  type="button"
                  onClick={() => {
                    handleOptionClick(optionIndex);
                  }}
                  className="w-full py-1.5 flex gap-2 rounded hover:bg-gray-100">
                  <RadioIcon className="w-4 ml-2" isChecked={option.isSelected} />
                  {option.name}
                </button>
              </li>
            ))}
          </ul>
          <div className="flex items-center justify-end">
            <Button
              className="px-4 py-2 m-4"
              backgroundColor="bg-gray-200"
              type="submit"
              onClick={handleReset}>
              {t('columnVisibilityModel.reset')}
            </Button>
            <Button
              className="px-4 py-2 m-4"
              backgroundColor="bg-gray-200"
              type="submit"
              onClick={handleApply}>
              {t('columnVisibilityModel.submit')}
            </Button>
          </div>
        </div>
      </div>
    )
  );
}

export default ColumnVisibilityModel;
