import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { Header, Button, BusSignupForm } from '../../components';

import { busSignupSchema } from '../../utils/schema';

import { busSignupSubmit } from '../../service';

function BusSignup() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const translations = t('busSignup', { returnObjects: true });
  const toastMessages = t('toastMessages', { returnObjects: true });

  const handleSubmit = async (values) => {
    try {
      console.log(values);
      await busSignupSubmit(values);
      navigate('/bus');
      toast.success(toastMessages?.busSignupSuccess ?? 'Signup successful');
      formik.resetForm();
    } catch (error) {
      const errorMessage = error.response.data.message || toastMessages.error;
      toast.error(errorMessage);
      console.log(error);
    }
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      companyName: '',
      companyAddress: '',
      companyPostalcode: '',
      companyCity: '',
      officePhone: '',
      email: '',
      eventWhatsappNumber: '',
      eventMobileNumber: '',
      carrierUIDNumber: '',
      dataStorageConfirmation: false,
      autherizationConfirmation: false
    },
    validationSchema: busSignupSchema
  });

  return (
    <main className="min-h-screen bg-blue-999 text-white pb-10">
      <Header />

      <section id="text-container" className="text-center py-10">
        <h1
          className="text-4xl font-bold uppercase"
          dangerouslySetInnerHTML={{ __html: translations?.title1 }}
        />
        <h1
          className="text-4xl uppercase outline-text font-bold"
          dangerouslySetInnerHTML={{ __html: translations?.title2 }}
        />
        <div dangerouslySetInnerHTML={{ __html: translations?.info }} />
      </section>

      <form onSubmit={formik.handleSubmit} className="container mt-5 mx-auto px-3">
        <h2
          className="text-2xl font-bold text-center"
          dangerouslySetInnerHTML={{ __html: translations?.formHeading }}
        />
        {/* Signup form */}
        <BusSignupForm formik={formik} translations={translations?.form} />

        <Button
          className="text-black flex items-center justify-center my-6 mt-12 uppercase"
          disabled={!formik.isValid}
          loading={formik.isSubmitting}
          type="submit">
          <p className="ml-1">{translations?.submit}</p>
        </Button>
        <div className="text-white mt-8">
          <h2 className="text-sm sm:text-2xl font-bold">{t('registration.support.title')}</h2>
          <p className="font-light mt-1">
            {t('registration.support.subheading')}
            <span className="font-bold">
              <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
            </span>
          </p>
        </div>
      </form>
    </main>
  );
}

export default BusSignup;
