import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircleLoader } from 'react-spinners';

import { validateMagic, validateAdminMagic, validateBusMagic, busAxios } from '../service';

function Magic({ fetchUserDetails = () => {} }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [magicLink, setMagicLink] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (window.location.search.includes('ref')) {
      const magic = window.location.search.split('ref=')[1];
      setMagicLink(magic);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const validateMagicLink = async (token) => {
      try {
        if (location.pathname === '/admin-magic') {
          const { token: magicToken } = await validateAdminMagic({ token });
          localStorage.setItem('adminToken', magicToken);
          navigate('/admin');
        } else if (location.pathname === '/driver-magic') {
          const { token: magicToken } = await validateBusMagic({ token });
          busAxios.defaults.headers.common['Authorization'] = `Bearer ${magicToken}`;
          try {
            localStorage.setItem('busToken', magicToken);
          } catch (e) {
            console.log(e);
          } finally {
            navigate('/bus/slots');
          }
        } else {
          const { token: magicToken, lang = 'fr' } = await validateMagic({ token });
          localStorage.setItem('token', magicToken);
          localStorage.setItem('lang', lang);
          i18n.changeLanguage(lang);
          fetchUserDetails();
          navigate('/school');
        }
      } catch (error) {
        if (location.pathname === '/admin-magic') navigate('/admin-login');
        else if (location.pathname === '/driver-magic') navigate('/bus');
        else navigate('/login');
      }
    };

    if (magicLink) validateMagicLink(magicLink);
  }, [magicLink, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <CircleLoader color="#3b0764" />
    </div>
  );
}

export default Magic;
