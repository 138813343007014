import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { PiWarningFill } from 'react-icons/pi';
import { FaAngleLeft, FaPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ProfileContext } from '../../context/profile';

import {
  Header,
  Button,
  ResponsibleInformartionForm,
  ClassInformationForm
} from '../../components';

import { createNewClassSchema, otherGroupCreateNewClassSchema } from '../../utils/schema';
import { createNewClass, getClassById, updateClassById } from '../../service';
import { otherGroupForms } from '../../utils/values';

function EditClass() {
  const { id } = useParams();

  const [profile] = useContext(ProfileContext);

  const { t } = useTranslation();

  let translations = t('editClass', { returnObjects: true });
  let createNewClassText = t('createNewClass', { returnObjects: true });
  const toastMessages = t('toastMessages', { returnObjects: true });

  const isOtherGroupForm = otherGroupForms.includes(profile.organization?.registrationType);
  translations = isOtherGroupForm ? translations?.['other-group'] : translations.default;
  createNewClassText = isOtherGroupForm
    ? createNewClassText?.['other-group'].addClass
    : createNewClassText.default.addClass;

  const getPageData = async () => {
    try {
      const classObj = await getClassById(id);

      const restructuredData = {
        responsible: [
          {
            firstName: classObj.teacher.firstName,
            lastName: classObj.teacher.lastName,
            email: classObj.teacher.email,
            phone: classObj.teacher.phone,
            position: classObj.teacher.position,
            _id: classObj.teacher._id,
            classes: [
              {
                _id: classObj._id,
                classLevel: classObj.classLevel,
                classCount: classObj.classCount,
                supervisorCount: classObj.supervisorCount,
                visitorsCount: classObj.visitorsCount,
                classNumber: classObj.classNumber,
                visitDay: `${classObj.visitDay}-responsible[${0}].classes[${0}].visitDay`,
                underContract: `responsible[${0}].classes[${0}].underContract-${classObj.underContract}`,
                estimatedArrivalTime: classObj.estimatedArrivalTime,
                estimatedTimeOnSite: classObj.estimatedTimeOnSite
              }
            ]
          }
        ]
      };

      formik.setValues(restructuredData);
      console.log(classObj);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const restructuredDataOfExistingClass = {
        teacher: {
          firstName: values.responsible[0].firstName,
          lastName: values.responsible[0].lastName,
          email: values.responsible[0].email,
          phone: values.responsible[0].phone,
          position: values.responsible[0].position,
          _id: values.responsible[0]._id
        },
        classLevel: values.responsible[0].classes[0].classLevel,
        classCount: values.responsible[0].classes[0].classCount,
        supervisorCount: values.responsible[0].classes[0].supervisorCount || '',
        visitorsCount: values.responsible[0].classes[0].visitorsCount || '',
        classNumber: values.responsible[0].classes[0].classNumber || '',
        visitDay: values.responsible[0].classes[0].visitDay.split('-')[0],
        underContract: values.responsible[0].classes[0].underContract.split('-')[1],
        estimatedArrivalTime: values.responsible[0].classes[0].estimatedArrivalTime,
        estimatedTimeOnSite: values.responsible[0].classes[0].estimatedTimeOnSite
      };

      const restructuredDataOfNewClasses = {
        responsible: [
          {
            firstName: values.responsible[0].firstName,
            lastName: values.responsible[0].lastName,
            email: values.responsible[0].email,
            phone: values.responsible[0].phone,
            position: values.responsible[0].position,
            teacherId: values.responsible[0]._id,
            classes: values.responsible[0].classes.slice(1).map((classItem) => ({
              ...classItem,
              visitDay: classItem.visitDay.split('-')[0],
              underContract: classItem.underContract.split('-')[1]
            }))
          }
        ]
      };

      await updateClassById(id, restructuredDataOfExistingClass);
      if (restructuredDataOfNewClasses.responsible[0].classes.length > 0)
        await createNewClass(restructuredDataOfNewClasses);
      toast.success(toastMessages.classGroupUpdated);
    } catch (error) {
      console.log(error);
      const errorMessage = error.response.data.message || toastMessages.error;
      toast.error(errorMessage);
    }
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      responsible: [
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          position: '',
          classes: [
            {
              classLevel: '',
              classCount: '',
              supervisorCount: '',
              visitorsCount: '',
              classNumber: '',
              visitDay: '',
              underContract: '',
              estimatedArrivalTime: '',
              estimatedTimeOnSite: ''
            }
          ]
        }
      ]
    },
    validationSchema: isOtherGroupForm ? otherGroupCreateNewClassSchema : createNewClassSchema
  });

  const addMoreClassToLastResponsible = () => {
    const lastResponsible = formik.values.responsible.length - 1;
    formik.setFieldValue(`responsible[${lastResponsible}].classes`, [
      ...formik.values.responsible[lastResponsible].classes,
      {
        classLevel: '',
        classCount: '',
        supervisorCount: '',
        visitorsCount: '',
        classNumber: '',
        visitDay: '',
        underContract: '',
        estimatedArrivalTime: '',
        estimatedTimeOnSite: ''
      }
    ]);
  };

  return (
    <main className="min-h-screen bg-blue-999 text-white pb-10">
      <Header isAuthenticated backgroundColor="bg-blue-999" title={translations?.headerTitle} />
      <div className="container mt-5 mx-auto">
        <Button
          className="text-black flex items-center justify-center px-4"
          type="button"
          internalLink
          to="/school/classes">
          <FaAngleLeft className="text-xl" />
        </Button>
      </div>
      <form onSubmit={formik.handleSubmit} className="container mt-5 mx-auto">
        {formik.values.responsible.map((_, index) => (
          <>
            <ResponsibleInformartionForm
              formik={formik}
              index={index}
              title={translations?.responsibleInformartionForm?.title}
            />

            <ClassInformationForm
              title={translations?.classInformationForm?.title}
              responsibleIndex={index}
              formik={formik}
              otherGroupForm={isOtherGroupForm}
            />
          </>
        ))}
        <Button
          className="text-black flex items-center justify-center my-6 uppercase"
          type="button"
          onClick={addMoreClassToLastResponsible}>
          <FaPlus />
          <p className="ml-1">{createNewClassText}</p>
        </Button>
        <section className="py-10">
          <p className="font-thin flex justify-start">
            <PiWarningFill className="text-yellow-500 mt-1 mr-1" />
            {translations?.warning}
          </p>
          {translations?.warning2 && <p>{translations?.warning2}</p>}
        </section>
        <Button
          className="text-black flex items-center justify-center my-6 uppercase"
          type="submit">
          <p className="ml-1">{translations?.submit}</p>
        </Button>
        <div
          className="grid grid-cols-1 gap-4"
          dangerouslySetInnerHTML={{ __html: translations?.bottomInfo }}
        />
      </form>
    </main>
  );
}

export default EditClass;
