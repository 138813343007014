import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Input, Button, Header } from '../components';
import { login, adminLogin, busLoginSubmit } from '../service';
import { LoginSchema } from '../utils/schema';

function Login() {
  const location = useLocation();

  const { t } = useTranslation();

  const { model } = t(`login`, { returnObjects: true });

  const handleSubmit = async (values) => {
    try {
      if (location.pathname.toLowerCase().includes('/admin-login')) await adminLogin(values);
      else if (location.pathname.toLowerCase().includes('/bus')) await busLoginSubmit(values);
      else await login(values);

      formik.resetForm();

      Swal.fire({
        icon: 'success',
        title: model.title,
        text: model.text
      });
    } catch (error) {
      const message = error.response?.data || 'Something went wrong';

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: message
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit
  });

  return (
    <div className="min-h-screen">
      <Header />
      <section
        id="login"
        className="bg-cover w-full h-screen justify-center items-center flex"
        style={{ backgroundImage: "url('/login-background.png')" }}>
        <div className="flex items-start justify-start max-w-3xl w-full">
          <div className="text-white">
            <h1 className="text-3xl md:text-5xl uppercase font-bold">
              {t('login.title.part1')} <br />
              <span className="outline-text mt-1">{t('login.title.part2')}</span>
            </h1>

            <p className="font-light mt-4">{t('login.subheading')}</p>
            <form
              className="mt-5 items-end flex md:grid grid-flow-col grid-cols-5"
              onSubmit={formik.handleSubmit}>
              <span className="col-span-3">
                <Input
                  label={t('login.form.email.label')}
                  placeholder={t('login.form.email.placeholder')}
                  className="px-4"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />
              </span>
              <Button
                clipedButton
                backgroundColor="bg-transparent -ml-6"
                loading={formik.isSubmitting}
                disabled={!formik.isValid}>
                <span className="text-white px-3">{t('login.form.submit')}</span>
              </Button>
            </form>
            <div className="text-white mt-8">
              <h2 className="text-sm sm:text-2xl font-bold">{t('registration.support.title')}</h2>
              <p className="font-light mt-1">
                {t('registration.support.subheading')}
                <span className="font-bold">
                  <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
