import React from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';

const FullScreenLoader = () => {
  return (
    <div
      data-testid="alert"
      className="fixed top-0 left-0 h-screen w-full flex items-center justify-center flex-row z-[1000] bg-white-100">
      <PacmanLoader color="#3b0764" />
    </div>
  );
};

export default FullScreenLoader;
