import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FaAngleLeft } from 'react-icons/fa';
import { Button, Dropdown, SearchField } from '../common';

import React, { useState } from 'react';
import { CrossButtonIcon, PlusButtonIcon } from '../../assets';

function AdminResponseModel({
  tableHeaders = [],
  data = [],
  isOpen = false,
  setOpen,
  onFilterUpdate
}) {
  const { t } = useTranslation();

  const translations = t('filterModel', { returnObjects: true });

  const [filterRows, setFilterRows] = useState([]);

  // Initialize formik with an empty object for initialValues
  const formik = useFormik({
    initialValues: {},
    onSubmit: onFilterUpdate
  });

  const handleAddNewFilter = () => {
    // Simply add a new filter row identifier
    const newFilterRow = {
      dropdownId: `dropdown_${filterRows.length}`,
      searchFieldId: `searchField_${filterRows.length}`
    };
    setFilterRows([...filterRows, newFilterRow]);
  };

  // Dynamically generate search field options based on the selected option in the dropdown
  const getSearchFieldOptions = (selectedOption) => {
    if (!selectedOption) {
      return [];
    }

    const uniqueValues = [...new Set(data.map((item) => item[selectedOption]).filter(Boolean))];

    return uniqueValues.map((value) => ({ label: value, value }));
  };

  const handleReset = () => {
    formik.resetForm();
    onFilterUpdate({});
    setFilterRows([]); // Reset filterRows on form reset
  };

  // Function to get the filtered options excluding the selected ones in other dropdowns
  const getFilteredOptions = (currentDropdownId) => {
    const selectedValues = filterRows
      .map(({ dropdownId }) => formik.values[dropdownId])
      .filter((val) => val);
    return tableHeaders
      .filter(
        ({ key }) => !selectedValues.includes(key) || formik.values[currentDropdownId] === key
      )
      .map((tableHeader) => ({
        label: tableHeader.name,
        value: tableHeader.key
      }));
  };

  const handleRemoveFilter = (indexToRemove) => {
    // Remove the specified filter row based on its index
    const updatedFilterRows = filterRows.filter((_, index) => index !== indexToRemove);
    setFilterRows(updatedFilterRows);

    // Also, remove the values from Formik's state to keep it in sync
    const valuesCopy = { ...formik.values };
    delete valuesCopy[filterRows[indexToRemove].dropdownId];
    delete valuesCopy[filterRows[indexToRemove].searchFieldId];
    formik.setValues(valuesCopy);
  };

  return (
    isOpen && (
      <div
        data-testid="filter-model"
        className="fixed inset-0 flex items-center justify-center max-w-xl min-w-64 mx-auto z-50">
        <form onSubmit={formik.handleSubmit} className="bg-white shadow rounded-2xl w-full">
          <div className="bg-gray-100 flex">
            <div className="flex items-center">
              <Button
                className="flex items-center justify-center px-4 text-white"
                backgroundColor="bg-gray-800"
                type="button"
                onClick={() => setOpen(false)}>
                <FaAngleLeft className="text-xl" />
              </Button>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6 gap-y-10 ">
            {filterRows.map(({ dropdownId, searchFieldId }, index) => {
              // Determine the selected option for the current dropdown
              const selectedOption = formik.values[dropdownId];
              // Generate search field options based on the selected dropdown option
              const searchFieldOptions = getSearchFieldOptions(selectedOption);
              return (
                <div key={index} className="flex items-center justify-between">
                  <div className="grid grid-cols-2">
                    <Dropdown
                      inputLight
                      name={dropdownId}
                      options={getFilteredOptions(dropdownId)}
                      onChange={(e) => {
                        // Update the dropdown selection in formik
                        formik.setFieldValue(dropdownId, e.target.value);
                        // Ensure the search field is cleared when changing the dropdown selection
                        formik.setFieldValue(searchFieldId, '');
                      }}
                      value={formik.values[dropdownId] || ''}
                    />
                    <SearchField
                      inputLight
                      openByDefault={true}
                      name={searchFieldId}
                      options={searchFieldOptions}
                      onChange={(e) => formik.setFieldValue(searchFieldId, e.target.value)}
                      value={formik.values[searchFieldId] || ''}
                      search={formik.values[searchFieldId] || ''}
                      disabled={!formik.values[dropdownId]}
                      setSearch={() => {}}
                    />
                  </div>
                  <CrossButtonIcon
                    data-testid={`cross-button-${index}`}
                    onClick={() => handleRemoveFilter(index)}
                    className="w-5"
                  />
                </div>
              );
            })}
          </div>
          <button
            data-testid="add-more-button"
            onClick={handleAddNewFilter}
            type="button"
            className="mx-4">
            <PlusButtonIcon />
          </button>
          <div className="flex items-end justify-end mr-4 mb-2">
            <Button
              className="px-4 py-2 mr-4"
              backgroundColor="bg-gray-200"
              type="button"
              onClick={handleReset}>
              {t('filterModel.reset')}
            </Button>
            <Button className="px-4 py-2" backgroundColor="bg-gray-200" type="submit">
              {t('filterModel.submit')}
            </Button>
          </div>
        </form>
      </div>
    )
  );
}

export default AdminResponseModel;
