import { useTranslation } from 'react-i18next';
import { Header, Button } from '../../components';

function OrientationGroup() {
  const { t } = useTranslation();

  const translations = t('orientationGroup', { returnObjects: true });

  return (
    <main className="min-h-screen">
      <Header />
      <section
        id="establishment-training-type-selection"
        className="bg-cover w-full min-h-screen justify-center items-center flex pt-10 md:pt-0"
        style={{
          backgroundImage: "url('/preparation-background.jpg')"
        }}>
        <div className="container w-full px-5">
          <div className="flex justify-end pb-10 absolute top-40 right-10">
            <Button
              className="uppercase px-4 font-bold self-end text-white"
              backgroundColor="bg-red-550"
              internalLink
              to="/">
              {translations?.login}
            </Button>
          </div>
          <div className="text-white text-center">
            <h1 className="text-3xl sm:text-5xl uppercase font-bold">
              <span dangerouslySetInnerHTML={{ __html: translations?.title?.part1 }} /> <br />
              <span
                className="outline-text"
                dangerouslySetInnerHTML={{ __html: translations?.title?.part2 }}
              />
            </h1>
          </div>
          <div className="my-8 text-white">
            <p
              className="font-light mt-4"
              dangerouslySetInnerHTML={{ __html: translations?.info }}
            />
            <ul className="list-disc list-outside ml-4">
              {translations?.list?.map((item, index) => (
                <li key={index} className="font-light" dangerouslySetInnerHTML={{ __html: item }} />
              ))}
            </ul>
            <p
              className="font-light mt-4"
              dangerouslySetInnerHTML={{ __html: translations?.warning }}
            />
          </div>
          <div className="flex justify-center">
            <a
              href="https://www.champions.worldskills-france.org/mf2024"
              className="border-red-550 border-2 flex items-center max-w-fit px-10 py-4 text-white">
              <span
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: translations?.mfLink?.part1
                }}
              />
              <span className="tilted-border-left ml-10">{translations?.mfLink?.part2}</span>
            </a>
          </div>
        </div>
      </section>
    </main>
  );
}

export default OrientationGroup;
