import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { CardButton, Button, HeaderWSF, FooterWSF, BookingCloseBox } from '../components';

function RegistrationForeign() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('en');
    localStorage.setItem('lang', 'en');
  }, [i18n]);

  return (
    <main className="min-h-screen">
      <HeaderWSF />
      <section
        id="registration"
        className="bg-cover w-full min-h-screen justify-center items-center flex"
        style={{ backgroundImage: "url('/registration-background.jpg')" }}>
        <div className="max-w-5xl w-full px-5">
          <div className="flex justify-end pb-10 absolute top-40 right-10">
            <Button
              className="uppercase px-4 font-bold self-end text-white"
              backgroundColor="bg-red-550"
              internalLink
              to="/login">
              {t('registration.login')}
            </Button>
          </div>
          <div className="text-white text-center">
            <h1 className="text-3xl sm:text-5xl uppercase font-bold">
              {t('registration.title.part1')}{' '}
              <span className="outline-text">{t('registration.title.part2')}</span>
            </h1>
            <p className="font-light mt-4">{t('registration.subheading')}</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-16">
            <CardButton
              title={t('registration.card1.title')}
              description={t('registration.card1.description')}
              ActionText={t('registration.card1.actionText')}
              to={`/school-group-selection?registration-group=foreign&region=null&registration-type=foreign-group`}
              internalLink
            />
            <CardButton
              title={t('registration.card2.title')}
              ActionText={t('registration.card2.actionText')}
              description={t('registration.card2.description')}
              to="/registration"
              internalLink
            />
            <BookingCloseBox isEnglish />
          </div>
          <div className="text-white">
            <h2 className="text-sm sm:text-2xl font-bold">{t('registration.support.title')}</h2>
            <p className="font-light mt-1">
              {t('registration.support.subheading')}
              <span className="font-bold">
                <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
              </span>
            </p>
          </div>
        </div>
      </section>
      <FooterWSF />
    </main>
  );
}

export default RegistrationForeign;
