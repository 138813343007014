import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FaPlus } from 'react-icons/fa';
import { PiWarningFill } from 'react-icons/pi';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import {
  Header,
  Button,
  ResponsibleInformartionForm,
  ClassInformationForm
} from '../../components';

import { createNewClassSchema, otherGroupCreateNewClassSchema } from '../../utils/schema';
import { createNewClass, getResponsiblesById } from '../../service';
import { ProfileContext } from '../../context/profile';
import { otherGroupForms } from '../../utils/values';

function CreateNewClass() {
  const { t } = useTranslation();

  let translations = t('createNewClass', { returnObjects: true });
  const toastMessages = t('toastMessages', { returnObjects: true });

  const [profile] = useContext(ProfileContext);
  const [searchParam] = useSearchParams();
  const [teacherId, setTeacherId] = useState(undefined);

  const isOtherGroupForm = otherGroupForms.includes(profile.organization?.registrationType);
  translations = isOtherGroupForm ? translations?.['other-group'] : translations.default;

  const handleSubmit = async (values) => {
    try {
      const restructuredData = {
        responsible: values.responsible.map((responsible) => ({
          ...responsible,
          teacherId,
          classes: responsible.classes.map((classItem) => ({
            ...classItem,
            visitDay: classItem.visitDay.split('-')[0],
            underContract: classItem.underContract.split('-')[1]
          }))
        }))
      };
      await createNewClass(restructuredData);
      toast.success(toastMessages.classGroupCreated);
      formik.resetForm();
    } catch (error) {
      const errorMessage = error.response.data.message || toastMessages.error;
      toast.error(errorMessage);
      console.log(error);
    }
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      responsible: [
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          function: '',
          classes: [
            {
              classLevel: '',
              classCount: '',
              supervisorCount: '',
              visitorsCount: '',
              classNumber: '',
              visitDay: '',
              underContract: '',
              estimatedArrivalTime: '',
              estimatedTimeOnSite: ''
            }
          ]
        }
      ]
    },
    validationSchema: isOtherGroupForm ? otherGroupCreateNewClassSchema : createNewClassSchema
  });

  const addMoreResponsible = () => {
    formik.setFieldValue('responsible', [
      ...formik.values.responsible,
      {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        function: '',
        classes: [
          {
            classLevel: '',
            classCount: '',
            supervisorCount: '',
            visitorsCount: '',
            classNumber: '',
            visitDay: '',
            underContract: '',
            estimatedArrivalTime: '',
            estimatedTimeOnSite: ''
          }
        ]
      }
    ]);
  };

  const addMoreClassToLastResponsible = () => {
    const lastResponsible = formik.values.responsible.length - 1;
    formik.setFieldValue(`responsible[${lastResponsible}].classes`, [
      ...formik.values.responsible[lastResponsible].classes,
      {
        classLevel: '',
        classCount: '',
        supervisorCount: '',
        visitorsCount: '',
        classNumber: '',
        visitDay: '',
        underContract: '',
        estimatedArrivalTime: '',
        estimatedTimeOnSite: ''
      }
    ]);
  };

  const getPageData = async () => {
    try {
      const id = searchParam.get('id');
      if (!(id ?? '').trim()) return;
      setTeacherId(id);
      const apiResponse = await getResponsiblesById(id);
      const { firstName, lastName, email, phone, position } = apiResponse;
      const bindValues = {
        responsible: [
          {
            firstName,
            lastName,
            email,
            phone,
            position: position,
            classes: [
              {
                classLevel: '',
                classCount: '',
                supervisorCount: '',
                visitorsCount: '',
                classNumber: '',
                visitDay: '',
                underContract: '',
                estimatedArrivalTime: '',
                estimatedTimeOnSite: ''
              }
            ]
          }
        ]
      };
      formik.setValues(bindValues);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <main className="min-h-screen bg-blue-999 text-white pb-10">
      <Header />
      <form onSubmit={formik.handleSubmit} className="container mt-5 mx-auto">
        <section id="text-container" className="text-center py-10">
          <h1 className="text-4xl font-bold uppercase">{translations?.title1}</h1>
          <h1 className="text-4xl uppercase outline-text font-bold">{translations?.title2}</h1>
          <div dangerouslySetInnerHTML={{ __html: translations?.info }} />
        </section>

        {formik.values.responsible.map((_, index) => (
          <>
            <ResponsibleInformartionForm
              isDisabledForm={!isEmpty(teacherId)}
              formik={formik}
              index={index}
              title={translations?.responsibleInformartionForm?.title}
              infoHelperText={translations?.responsibleInformartionForm?.infoHelperText}
            />

            <ClassInformationForm
              title={translations?.classInformationForm?.title}
              responsibleIndex={index}
              formik={formik}
              classCheckboxesLabel={translations?.classInformationForm?.classCheckboxesLabel}
              otherGroupForm={isOtherGroupForm}
            />
          </>
        ))}

        <section className="py-10">
          <p className="font-thin flex justify-start">
            <PiWarningFill className="text-yellow-500 mt-1 mr-1" />
            {translations?.warning}
          </p>

          {!isOtherGroupForm && (
            <Button
              className="text-black flex items-center justify-center my-6 uppercase"
              type="button"
              onClick={addMoreClassToLastResponsible}>
              <FaPlus />
              <p className="ml-1"> {translations?.addClass}</p>
            </Button>
          )}
        </section>
        <p>{translations?.bottomInfo}</p>
        <Button
          className="text-black flex items-center justify-center my-6 uppercase"
          type="submit">
          <p className="ml-1">{translations?.submit}</p>
        </Button>
        <div className="text-white mt-8">
          <h2 className="text-sm sm:text-2xl font-bold">{t('registration.support.title')}</h2>
          <p className="font-light mt-1">
            {t('registration.support.subheading')}
            <span className="font-bold">
              <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
            </span>{' '}
          </p>
        </div>
      </form>
    </main>
  );
}

export default CreateNewClass;
