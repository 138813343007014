import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { BookingCloseBox, Button, CardTitleButton, Header } from '../components';

import _ from 'lodash';
import {
  declarationOfInterestEnabledRegion,
  declarationOfInterestEnabledRegistrationType,
  informationTicketEnabledGroups,
  informationTicketEnabledRegion,
  learningSectionEnable
} from '../utils/values';

function SchoolGroupSelection() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const group = query.get('registration-group').trim();
  const region = query.get('region').trim();
  const type = query.get('registration-type').trim();

  const allTranslations = t(`schoolGroupSelection.${type}`, { returnObjects: true });

  const translations = allTranslations[region] ?? allTranslations.default;

  const getLocalTranslation = (key) => {
    const regionValue = _.get(allTranslations[region], key);
    return regionValue ?? _.get(allTranslations.default, key);
  };

  useEffect(() => {
    if (!group || !region || !type) navigate('/registration');
  }, [group, region, type, navigate]);

  return (
    <main
      className="min-h-screen bg-blue-999"
      style={{ backgroundImage: "url('/school-group-bg.png')" }}>
      <Header />
      <section
        id="school-group-selection"
        className="bg-cover w-full py-10 text-white max-w-7xl mx-auto px-3">
        <div className="flex justify-end pb-10 top-40 right-10">
          <Button
            className="uppercase px-4 font-bold self-end text-white"
            backgroundColor="bg-red-550"
            to="/login">
            {getLocalTranslation('login')}
          </Button>
        </div>
        <div className="">
          <h1 className="text-xl md:text-5xl uppercase font-bold">
            <span>{getLocalTranslation('title.part1')} </span>
            <span className="outline-text">{getLocalTranslation('title.part2')}</span>
            <span className="text-xs md:text-sm ml-3">{getLocalTranslation('subheading')}</span>
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10  gap-x-3 gap-y-10">
          {getLocalTranslation('card0.title') && (
            <CardTitleButton
              lightBackground
              title={getLocalTranslation('card0.title')}
              buttonTitle={getLocalTranslation('card0.buttonTitle')}
              to={'https://worldskills2024.com/animations-en-ville/'}
              internalLink>
              <div dangerouslySetInnerHTML={{ __html: getLocalTranslation('card0.content') }} />
            </CardTitleButton>
          )}
          {declarationOfInterestEnabledRegistrationType.includes(type) &&
            declarationOfInterestEnabledRegion.includes(region) && (
              <CardTitleButton
                title={getLocalTranslation('card1.title')}
                buttonTitle={getLocalTranslation('card1.buttonTitle')}
                to={`/declaration-of-interest?registration-group=${group}&region=${region}&registration-type=${type}&registration-purpose=interest-declaration`}
                internalLink>
                <div dangerouslySetInnerHTML={{ __html: getLocalTranslation('card1.content') }} />
              </CardTitleButton>
            )}
          <CardTitleButton
            title={getLocalTranslation('card2.title')}
            buttonTitle={getLocalTranslation('card2.buttonTitle')}
            to={`/school-registration?registration-group=${group}&region=${region}&registration-type=${type}&registration-purpose=school-registration`}
            internalLink>
            <div dangerouslySetInnerHTML={{ __html: getLocalTranslation('card2.content') }} />
          </CardTitleButton>

          <CardTitleButton
            title={getLocalTranslation('card3.title')}
            buttonTitle={getLocalTranslation('card3.buttonTitle')}
            to={`/class-registration-teacher?registration-group=${group}&region=${region}&registration-type=${type}&registration-purpose=class-registration`}
            internalLink>
            <div
              dangerouslySetInnerHTML={{
                __html: getLocalTranslation('card3.content')
              }}
            />
          </CardTitleButton>
          {translations?.card4 &&
            informationTicketEnabledRegion.includes(region) &&
            !informationTicketEnabledGroups.includes(group) && (
              <CardTitleButton
                title={getLocalTranslation('card4.title')}
                buttonTitle={getLocalTranslation('card4.buttonTitle')}
                nonClickable={!getLocalTranslation('card4.buttonLink')}
                to={getLocalTranslation('card4.buttonLink')}
                target="__blank">
                <div
                  dangerouslySetInnerHTML={{
                    __html: getLocalTranslation('card4.content')
                  }}
                />
              </CardTitleButton>
            )}
          {translations?.card5 && (
            <CardTitleButton
              title={getLocalTranslation('card5.title')}
              buttonTitle={getLocalTranslation('card5.buttonTitle')}
              nonClickable={!getLocalTranslation('card5.buttonLink')}
              to={getLocalTranslation('card5.buttonLink')}
              target="__blank">
              <div
                dangerouslySetInnerHTML={{
                  __html: getLocalTranslation('card5.content')
                }}
              />
            </CardTitleButton>
          )}

          <BookingCloseBox />
        </div>
      </section>
      <section className="text-white py-10 max-w-7xl mx-auto px-3">
        <h2 className="text-xl uppercase">{getLocalTranslation('prepare.title')}</h2>
        <p
          className="max-w-xl my-4"
          dangerouslySetInnerHTML={{ __html: getLocalTranslation('prepare.subheading') }}
        />
        <ol className="list-decimal list-inside">
          {getLocalTranslation('prepare.list')?.map((item, index) => (
            <li key={index} className="my-2" dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </ol>
        {learningSectionEnable.includes(type) && (
          <>
            <p className="py-4">{getLocalTranslation('prepare.plusInfo.title')}</p>
            <div className="grid grid-rows2 gap-4">
              {getLocalTranslation('prepare.plusInfo.link1.title') && (
                <Link
                  to="/preparation/orientation-groups"
                  className="border-red-550 border-2 flex items-center max-w-fit px-10 py-4">
                  <p>{getLocalTranslation('prepare.plusInfo.link1.title')}</p>
                  <span className="tilted-border-left ml-10">
                    {getLocalTranslation('prepare.plusInfo.link1.button')}
                  </span>
                </Link>
              )}
              {getLocalTranslation('prepare.plusInfo.link2.title') && (
                <Link
                  to="/preparation/soft-skills-groups"
                  className="border-red-550 border-2 flex items-center max-w-fit px-10 py-4">
                  <p>{getLocalTranslation('prepare.plusInfo.link2.title')}</p>
                  <span className="tilted-border-left ml-10">
                    {getLocalTranslation('prepare.plusInfo.link2.button')}
                  </span>
                </Link>
              )}
            </div>
          </>
        )}
        <div className="text-white mt-8 py-4">
          <h2 className="text-sm sm:text-2xl font-bold">{t('registration.support.title')}</h2>
          <p className="font-light mt-1">
            {t('registration.support.subheading')}
            <span className="font-bold">
              <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
            </span>{' '}
          </p>
        </div>
      </section>
    </main>
  );
}

export default SchoolGroupSelection;
