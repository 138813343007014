import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Button } from '../common';

import React, { useState } from 'react';

function OpenNotificationModel({ notes, isOpen = false, setOpen, onUpdate }) {
  const [comment, setComment] = useState('');

  const handlePostData = async () => {
    onUpdate(comment);
  };
  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999]">
        <div className="w-full max-w-[752px] max-h-full overflow-y-auto rounded-2xl bg-white shadow-lg p-8">
          <div>
            <Button
              data-testid="left-button"
              className="flex justify-center items-left items-center px-4"
              backgroundColor="bg-gray-800"
              type="button"
              onClick={() => {
                setOpen(false);
                setComment('');
              }}>
              <FaAngleLeft className="text-xl text-white" />
            </Button>
          </div>
          <div className="mt-11 text-xl font-bold leading-6 text-neutral-600">Ajouter de Notes</div>
          <div className="flex flex-row py-3.5 mt-11 relative">
            <textarea
              placeholder="Ajouter de Notes"
              value={comment}
              onChange={({ target: { value } }) => setComment(value)}
              className="rounded-[20px] resize-none w-full h-24 p-2 border bg-gray-200"></textarea>
            <Button
              data-testid="right-button"
              className="absolute right-2 bottom-6 px-4 py-2 text-white rounded"
              backgroundColor="bg-gray-800"
              onClick={() => {
                handlePostData();
                setOpen(false);
                setComment('');
              }}>
              <FaAngleRight className="text-xl text-white" />
            </Button>
          </div>
          <div className="max-h-[200px] overflow-y-auto">
            {notes.map((noteItem, index) => (
              <div key={index} className="flex gap-4 mt-4 ml-12">
                <div className="my-auto rounded-full bg-neutral-200 h-[42px] stroke-[2px] w-[42px]" />
                <div className="flex flex-col flex-1">
                  <div className="justify-center px-8 py-2.5 text-base font-semibold whitespace-nowrap rounded-2xl bg-stone-50">
                    {noteItem.note}
                  </div>
                  <div className="mt-1.5 mr-5 text-xs font-light tracking-normal">
                    {noteItem.user.email} {new Date(noteItem?.createdAt).toLocaleString()}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
}

export default OpenNotificationModel;
