import React from 'react';
import { Link } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';

const Button = ({
  children,
  backgroundColor = 'bg-white',
  className = '',
  clipedButton = false,
  internalLink = false,
  disabled = false,
  loading = false,
  ...props
}) => {
  return internalLink ? (
    <Link
      {...props}
      className={`max-w-max py-2 rounded-md h-12 ${backgroundColor} ${
        clipedButton ? '!px-4 container-clip container-clip-background' : 'px-2'
      } ${className} flex items-center`}>
      {children}
    </Link>
  ) : (
    <button
      {...props}
      disabled={disabled}
      className={`user-select-none outline-none py-2 rounded-md h-12 ${backgroundColor} ${
        clipedButton ? '!px-4 container-clip container-clip-background' : 'px-2'
      } ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
      {!loading && children}{' '}
      {loading && (
        <span className="flex w-full items-center justify-center" data-testid="loader">
          <ClockLoader size={20} color="#3b0764" />
        </span>
      )}
    </button>
  );
};

export default Button;
