import clsx from 'clsx';
import PropTypes from 'prop-types';

const TextSkeleton = ({ isShimmering, size }) => {
  const defaultSize = 'w-48';
  const sizes = {
    xxsmall: 'w-4',
    small: 'w-32',
    medium: 'w-48',
    large: 'w-64',
    xlarge: 'w-96'
  };
  const sizeClasses = sizes[size] || defaultSize;

  return (
    <div role="status" className={clsx('max-w-sm', { 'animate-pulse': isShimmering })}>
      <div className={clsx('h-2.5 bg-gray-300 rounded-full', sizeClasses)}></div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

TextSkeleton.propTypes = {
  isShimmering: PropTypes.bool,
  size: PropTypes.oneOf(['xxsmall', 'small', 'medium', 'large', 'xlarge'])
};

export default TextSkeleton;
