import { Link } from 'react-router-dom';

const CardTitledButton = ({
  title,
  buttonTitle,
  internalLink = false,
  to,
  children,
  nonClickable = false,
  target = '',
  lightBackground = false
}) => {
  return (
    <div
      className={`pentagon-box ${lightBackground && 'pentagon-box-light !text-blue-999'} text-white shadow-lg`}>
      <div className="p-10">
        <p className="uppercase pb-2 mb-4 font-bold text-lg">{title}</p>
        {children}
        {!nonClickable && (
          <div className="mt-4">
            {internalLink ? (
              <Link to={to} className="relative tilted-border-left uppercase">
                {buttonTitle}
              </Link>
            ) : (
              <a href={to} target={target} className="relative tilted-border-left uppercase">
                {buttonTitle}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardTitledButton;
