import React from 'react';
import { useTranslation } from 'react-i18next';

const BookingCloseBox = ({ isSimple = false }) => {
  const { t } = useTranslation();

  return isSimple ? (
    <div className="bg-[#DF89E5] text-white p-2 rounded-md max-w-2xl">
      <p
        className="font-thin text-sm leading-4"
        dangerouslySetInnerHTML={{ __html: t('bookingCloseBox.oneLine') }}
      />
    </div>
  ) : (
    <div className="bg-[#DF89E5] text-white p-4 rounded-md mt-5">
      <p className="mt-2" dangerouslySetInnerHTML={{ __html: t('bookingCloseBox.box') }} />
    </div>
  );
};

export default BookingCloseBox;
