import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  const footer = t('footer', { returnObjects: true });

  return (
    <>
      <div className="bg-purple-950">
        <div className="max-w-[100rem] px-[.9375rem] lg:px-[3.125rem] ">
          {/*  */}
          <div className="flex  flex-col lg:flex-row">
            <div className="flex-1 py-8 flex items-center justify-center">
              <ul className="flex gap-2 flex-wrap list-none px-0">
                <li>
                  <a
                    className="footer-social-link"
                    href={footer.fbLink}
                    title="Go to facebook"
                    target="_blank"
                    rel="nofollow noopener noreferrer">
                    <svg
                      fill="#fff"
                      width="35px"
                      height="35px"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.95 5.005l-3.306-.004c-3.206 0-5.277 2.124-5.277 5.415v2.495H10.05v4.515h3.317l-.004 9.575h4.641l.004-9.575h3.806l-.003-4.514h-3.803v-2.117c0-1.018.241-1.533 1.566-1.533l2.366-.001.01-4.256z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="footer-social-link"
                    href={footer.xLink}
                    title="Go to X"
                    target="_blank"
                    rel="nofollow noopener noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      fill="#fff"
                      width="35px"
                      height="35px"
                      viewBox="0 0 50 50">
                      <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>{' '}
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="footer-social-link"
                    href={footer.instaLink}
                    title="Go to Instagram"
                    target="_blank"
                    rel="nofollow noopener noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      fill="#fff"
                      width="35px"
                      height="35px"
                      viewBox="0 0 50 50">
                      <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"></path>
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    className="footer-social-link"
                    href={footer.tiktokLink}
                    title="Go to TikTok"
                    target="_blank"
                    rel="nofollow noopener noreferrer">
                    <svg
                      fill="#fff"
                      width="35px"
                      height="35px"
                      viewBox="0 0 256 256"
                      id="Flat"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M232,84v40a7.99977,7.99977,0,0,1-8,8,103.32406,103.32406,0,0,1-48.00049-11.70752L176,156A76,76,0,1,1,86.59766,81.17971,7.99952,7.99952,0,0,1,96,89.05569l-.00049,41.63916a7.99971,7.99971,0,0,1-4.56689,7.22607A20.00272,20.00272,0,1,0,120,156V28a7.99977,7.99977,0,0,1,8-8h40a7.99977,7.99977,0,0,1,8,8,48.05436,48.05436,0,0,0,48,48A7.99977,7.99977,0,0,1,232,84Z" />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    className="footer-social-link"
                    href={footer.linkedInLink}
                    title="Go to LinkedIn"
                    target="_blank"
                    rel="nofollow noopener noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      fill="#fff"
                      width="35px"
                      height="35px"
                      viewBox="0 0 50 50">
                      <path d="M 8 3.0097656 C 4.53 3.0097656 2.0097656 5.0892187 2.0097656 7.9492188 C 2.0097656 10.819219 4.59 12.990234 8 12.990234 C 11.47 12.990234 13.990234 10.870625 13.990234 7.890625 C 13.830234 5.020625 11.36 3.0097656 8 3.0097656 z M 3 15 C 2.45 15 2 15.45 2 16 L 2 45 C 2 45.55 2.45 46 3 46 L 13 46 C 13.55 46 14 45.55 14 45 L 14 16 C 14 15.45 13.55 15 13 15 L 3 15 z M 18 15 C 17.45 15 17 15.45 17 16 L 17 45 C 17 45.55 17.45 46 18 46 L 27 46 C 27.552 46 28 45.552 28 45 L 28 30 L 28 29.75 L 28 29.5 C 28 27.13 29.820625 25.199531 32.140625 25.019531 C 32.260625 24.999531 32.38 25 32.5 25 C 32.62 25 32.739375 24.999531 32.859375 25.019531 C 35.179375 25.199531 37 27.13 37 29.5 L 37 45 C 37 45.552 37.448 46 38 46 L 47 46 C 47.55 46 48 45.55 48 45 L 48 28 C 48 21.53 44.529063 15 36.789062 15 C 33.269062 15 30.61 16.360234 29 17.490234 L 29 16 C 29 15.45 28.55 15 28 15 L 18 15 z"></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-1">
              <div className="flex flex-col max-w-[35.5rem]">
                <h2 className="my-[1.25rem] text-white text-[0.975rem] lg:text-[1.875rem] font-[300]">
                  {footer.title}
                </h2>
                <form className="flex flex-col">
                  <label className="hover:text-gray-300 text-white text-base flex items-center">
                    {footer.emailText}
                  </label>
                  <input
                    className="custom-clip-path mt-[5px] bg-blue-999 h-[48px] border border-[#bbb] border-solid outline-none text-white px-5"
                    required></input>
                  <div>
                    <button className=" text-white text-[14px] mt-[10px] bg-[#333] border border-solid border-[#2E2E2E] px-[12px] py-[6px] rounded-[3px]">
                      {footer.subText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="my-[3.625rem] lg:my-[5.625rem] flex  flex-col lg:flex-row items-start gap-[1.25rem]  justify-between">
            <div className="lg:max-w-[28.4375rem] w-full">
              <div>
                <div className="flex gap-x-[1.5625rem] mb-[1.125rem]">
                  <div>
                    <img
                      src={
                        'https://worldskills2024.com/wp-content/themes/webqam/assets/images/footer-worldskills.png'
                      }
                      alt="Help"
                    />
                  </div>
                  <div className="w-[0.5px] bg-white "></div>
                  <div>
                    <img
                      src={
                        'https://worldskills2024.com/wp-content/themes/webqam/assets/images/footer-worldskills-france.png'
                      }
                      alt="Help"
                    />
                  </div>
                </div>
                <p className="text-base text-white font-medium">{footer.desctiptionText}</p>
              </div>
            </div>
            <div className="w-full lg:w-[calc(100%-590px)] flex-col md:flex-row justify-between flex gap-5">
              <div className="flex-1">
                <h3 className="hover:text-gray-300 text-white uppercase font-bold text-base flex items-center mb-2">
                  {footer.footerLink.menu1.title}
                </h3>
                <ul className="list-none px-0">
                  <li>
                    <a
                      className="hover:text-gray-300 text-white text-base flex items-center py-1"
                      href={footer.footerLink.menu1?.subLinks?.menu1?.url}>
                      {footer.footerLink.menu1?.subLinks?.menu1?.title}
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:text-gray-300 text-white text-base flex items-center py-1"
                      href={footer.footerLink.menu1?.subLinks?.menu2?.url}>
                      {footer.footerLink.menu1?.subLinks?.menu2?.title}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-1">
                <h3 className="hover:text-gray-300 text-white uppercase font-bold text-base flex items-center mb-[.625rem]">
                  {footer.footerLink.menu2?.title}
                </h3>
                <ul className="list-none px-0">
                  <li>
                    <a
                      className="hover:text-gray-300 text-white text-base flex items-center py-1"
                      href={footer.footerLink.menu2?.subLinks?.menu1?.url}>
                      {footer.footerLink.menu2?.subLinks?.menu1?.title}
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:text-gray-300 text-white text-base flex items-center py-1"
                      href={footer.footerLink?.menu2?.subLinks?.menu2?.url}>
                      {footer.footerLink.menu2?.subLinks?.menu2?.title}
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:text-gray-300 text-white text-base flex items-center py-1"
                      href={footer.footerLink.menu2?.subLinks?.menu3?.url}>
                      {footer.footerLink?.menu2?.subLinks?.menu3?.title}
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:text-gray-300 text-white text-base flex items-center py-1"
                      href={footer.footerLink.menu2?.subLinks?.menu4?.url}>
                      {footer.footerLink.menu2?.subLinks?.menu4?.title}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* bottom */}
          <div className="flex flex-col lg:flex-row opacity-40 hover:opacity-100 transition-all ease duration-300 py-4 gap-4 lg:gap-12 lg:p-5 border-t border-solid border-white">
            <p className="text-white text-sm m-0 font-medium">
              {footer.siteCreation}
              <a href="#" target="_blank" rel="noreferrer"></a>
            </p>
            <div className="flex gap-x-[.3125rem]">
              <a className="text-white text-sm font-medium" href={footer.tncURL}>
                {footer.tncText} -
              </a>
              <a className="text-white text-sm font-medium" href={footer.siteMapURL}>
                {footer.siteMapText} -
              </a>
              <a className="text-white text-sm font-medium" href={footer.privacyURL}>
                {footer.privacyText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
