import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Dropdown,
  Header,
  Loading,
  OrganizationStatsByVisitDay,
  OrganizationStatsSlots,
  TextSkeleton
} from '../../components';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { AdminContext } from '../../context/admin';
import { getAdminStats, getAllRegions } from '../../service';
import { registrationTypes } from '../../utils/values';
import { toast } from 'react-toastify';

function OrganizationListing() {
  const { t } = useTranslation();

  const translations = t('adminStats', { returnObjects: true });

  const [adminProfile, setAdminProfile] = useContext(AdminContext);

  const [organizationStats, setOrganizationStats] = useState({});
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const [regions, setRegions] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedRegistrationType, setSelectedRegistrationType] = useState();

  const { assignedRegions, assignedRegistrationTypes } = adminProfile;
  let validRegistrationTypes = registrationTypes.filter((type) =>
    assignedRegistrationTypes?.includes(type._id)
  );

  if (!assignedRegions && !selectedRegion) {
    setSelectedRegion('all');
  }

  if (assignedRegistrationTypes?.length === 1 && !selectedRegistrationType) {
    setSelectedRegistrationType(assignedRegistrationTypes[0]);
  } else if (assignedRegistrationTypes?.length > 1) {
    validRegistrationTypes = [
      registrationTypes?.find((type) => type._id === 'all'),
      ...validRegistrationTypes
    ];
    if (!selectedRegistrationType) {
      setSelectedRegistrationType('all');
    }
  }

  const getPageData = async () => {
    try {
      setIsLoadingStats(true);
      const response = await getAdminStats({
        region: selectedRegion,
        registrationType: selectedRegistrationType
      });

      setOrganizationStats(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingStats(false);
    }
  };

  const getsortedColumns = (columns = []) => {
    const sortedColumns = columns.sort((a, b) => {
      if (a._id === 'Tous les groupes') {
        return -1; // "Tous les groupes" comes first
      } else if (b._id === 'Tous les groupes') {
        return 1; // "Tous les groupes" comes first
      } else {
        return a._id?.localeCompare(b._id); // Sort the rest using localeCompare
      }
    });

    return sortedColumns;
  };

  useEffect(() => {
    // Destructure for clearer access to the properties
    const { adminConfig = {} } = adminProfile || {};
    const {
      selectedRegistrationType: adminSelectedRegistrationType,
      selectedRegion: adminSelectedRegion
    } = adminConfig;

    // Set the state directly from the admin's config, if available
    if (adminSelectedRegistrationType) {
      setSelectedRegistrationType(adminSelectedRegistrationType);
    }
    if (adminSelectedRegion) {
      setSelectedRegion(adminSelectedRegion);
    }

    // Define getRegions within useEffect or ensure it's defined with useCallback outside of useEffect
    const getRegions = async () => {
      try {
        const data = await getAllRegions();

        const adminRegions =
          assignedRegions && assignedRegions.length > 0
            ? assignedRegions
            : data.map((region) => region._id);

        let filteredRegions = data
          .filter((region) => adminRegions.includes(region._id))
          .map((region) => ({
            label: region.name_fr,
            value: region._id
          }));

        if (filteredRegions.length > 1) {
          filteredRegions = [{ value: 'all', label: 'All' }, ...filteredRegions];
        }

        setRegions(filteredRegions);

        // Only set selectedRegion if it hasn't been already set by adminConfig
        if (!adminSelectedRegion) {
          if (filteredRegions.length === 1) {
            setSelectedRegion(filteredRegions[0].value);
          } else if (filteredRegions.length > 1) {
            setSelectedRegion('all');
          }
        }
      } catch (error) {
        toast.error('Failed to get regions');
        console.error('Failed to get regions: ', error);
      }
    };

    if (adminProfile && !regions?.length) {
      getRegions();
    }
  }, [adminProfile, assignedRegions]);

  useEffect(() => {
    if (selectedRegion && selectedRegistrationType) {
      getPageData();
    }
  }, [selectedRegion, selectedRegistrationType]);

  const handleRegionSelect = ({ target: { value } }) => {
    setOrganizationStats({});
    setSelectedRegion(value);
    setAdminProfile({
      ...adminProfile,
      adminConfig: { ...adminProfile.adminConfig, selectedRegion: value }
    });
  };

  const handleRegistrationTypeSelection = (value) => {
    if (value !== selectedRegistrationType) {
      setOrganizationStats({});
      setSelectedRegistrationType(value);
      setAdminProfile({
        ...adminProfile,
        adminConfig: { ...adminProfile.adminConfig, selectedRegistrationType: value }
      });
    }
  };

  const storeLinkDetails = (url) => {
    setAdminProfile({
      ...adminProfile,
      adminConfig: { ...adminProfile.adminConfig, url }
    });
  };

  const LoadingState = () => {
    return (
      <section className="px-4 py-12 flex flex-row gap-4 overflow-x-auto">
        {[
          { id: '1', children: ['1', '2', '3'] },
          { id: '2', children: ['1'] },
          { id: '3', children: ['1', '2'] },
          { id: '4', children: ['1', '2'] }
        ].map((item) => (
          <div
            key={item.id}
            className={'p-3 w-[300px] flex-shrink-0 bg-white rounded transition-all'}>
            <div className="flex justify-between relative">
              <h3 className="text-xl font-bold text-gray-900 max-w-full">
                <TextSkeleton isShimmering />
              </h3>
            </div>
            <div className={'mt-4 ring-1 ring-inset ring-black rounded-md p-4'}>
              <ul className="list-none px-0">
                {item.children.map((item) => (
                  <li key={item} className={'mt-3'}>
                    <div
                      className={'block p-5 bg-gray-100 rounded-xl shadow-sm w-full hover:shadow'}>
                      <div className="flex items-start justify-between">
                        <h4 className="text-left font-bold break-words w-[88%] uppercase">
                          <TextSkeleton isShimmering size="small" />
                        </h4>
                      </div>
                      <div className="mt-4">
                        <div className="flex items-center justify-between">
                          <span className="text-sm">{translations?.totalEstablishments}</span>
                          <span className="text-sm slashed-zero">
                            <TextSkeleton isShimmering size="xxsmall" />
                          </span>
                        </div>
                        <div className="flex items-center justify-between mt-2">
                          <span className="text-sm">{translations?.totalStudents}</span>
                          <TextSkeleton isShimmering size="xxsmall" />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </section>
    );
  };

  return (
    <main className="min-h-screen text-black pb-10">
      <Header isAuthenticated adminHeader backgroundColor="bg-white" />
      <section className="container py-10 mx-auto">
        {/* FILTERS */}
        <div className="mb-12">
          {regions?.length > 1 && (
            <>
              <div className="text-black">
                <p className="font-light mt-4">Sélectionnez votre région :</p>
              </div>
              <div className="sm:w-1/2 w-12 mt-4 text-white">
                <Dropdown
                  placeholder="Sélectionnez votre région :"
                  onChange={handleRegionSelect}
                  options={regions}
                  selected={selectedRegion}
                  showEmptyValue={false}
                  inputLight
                />
              </div>
            </>
          )}
          {validRegistrationTypes?.length > 1 && (
            <div className="flex items-center gap-4 my-4">
              {validRegistrationTypes.map((registrationType) => (
                <button
                  key={registrationType._id}
                  className={clsx(
                    'px-4 py-2 rounded text-sm font-semibold hover:bg-gray-400 transition-all',
                    registrationType._id === selectedRegistrationType
                      ? 'bg-gray-400'
                      : 'bg-gray-100'
                  )}
                  onClick={() => handleRegistrationTypeSelection(registrationType._id)}>
                  {registrationType.label}
                </button>
              ))}
            </div>
          )}
        </div>
        {selectedRegistrationType !== 'all' && (
          <>
            <b>
              {
                validRegistrationTypes.find(
                  (registrationType) => registrationType._id === selectedRegistrationType
                )?.heading
              }
            </b>
            {isLoadingStats ? (
              <LoadingState />
            ) : (
              <section className="px-4 py-12 flex flex-row gap-4 overflow-x-auto">
                {organizationStats?.formStats?.map((category) => (
                  <div
                    key={category.name}
                    className={'p-3 w-[300px] flex-shrink-0 bg-white rounded transition-all'}>
                    <div className="flex justify-between relative">
                      <h3 className="text-xl font-bold text-gray-900 max-w-full">
                        {category.name}
                      </h3>
                    </div>
                    <div className={'mt-4 ring-1 ring-inset ring-black rounded-md p-4'}>
                      <ul className="list-none px-0">
                        {getsortedColumns(category?.data).map((item) => {
                          const url = `/admin/organization?categoryName=${
                            category.name
                          }&registrationPurpose=${category.registrationPurpose || ''}&type=${
                            item._id
                          }&registrationType=${selectedRegistrationType}&hasClasses=${category.hasClasses || false}&region=${selectedRegion || null}`;

                          return (
                            <li key={item._id} className={'mt-3'}>
                              <Link
                                // onClick={() => storeLinkDetails(url)}
                                // to={url}
                                type="button"
                                className={
                                  'block p-5 bg-gray-100 rounded-xl shadow-sm w-full hover:shadow'
                                }>
                                <div className="flex items-start justify-between">
                                  <h4 className="text-left font-bold break-words w-[88%] uppercase">
                                    {item._id === 'elementarySchool'
                                      ? '1ER DEGRÉ / PRIMAIRE'
                                      : item._id}
                                  </h4>
                                </div>
                                <div className="mt-4">
                                  <div className="flex items-center justify-between">
                                    <span className="text-sm">
                                      {translations?.totalEstablishments}
                                    </span>
                                    <span className="text-sm slashed-zero">
                                      {item.organizationCount}
                                    </span>
                                  </div>
                                  <div className="flex items-center justify-between mt-2">
                                    <span className="text-sm">{translations?.totalStudents}</span>
                                    <span className="text-sm slashed-zero">
                                      {item.studentCount}
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                ))}
              </section>
            )}
          </>
        )}
        <OrganizationStatsByVisitDay
          translations={translations}
          organizationStats={organizationStats}
        />
        <OrganizationStatsSlots organizationStats={organizationStats} />
      </section>
    </main>
  );
}

export default OrganizationListing;
