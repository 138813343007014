import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Header, CardButton, Button, BookingCloseBox } from '../components';

import { getAllRegions } from '../service';

function EstablishmentTrainingTypeSelection() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [currentRegion, setCurrentRegion] = useState({});

  const translations = t('establishmentTrainingType', { returnObjects: true });

  const query = new URLSearchParams(location.search);
  const group = query.get('registration-group').trim();
  const region = query.get('region').trim();

  useEffect(() => {
    if (!group || !region) navigate('/registration');

    const getRegions = async () => {
      const data = await getAllRegions();
      const filteredRegion = data.filter((item) => item._id === region);
      setCurrentRegion(filteredRegion[0]);
    };

    getRegions();
  }, [group, region, navigate]);

  return (
    <main className="min-h-screen">
      <Header />
      <section
        id="establishment-training-type-selection"
        className="bg-cover w-full min-h-screen justify-center items-center flex pt-10 md:pt-0"
        style={{
          backgroundImage: "url('/establishment-training-type-background.jpg')"
        }}>
        <div className="max-w-5xl w-full px-5">
          <div className="flex justify-end pb-10 absolute top-40 right-10">
            <Button
              className="uppercase px-4 font-bold self-end text-white"
              backgroundColor="bg-red-550"
              internalLink
              to="/login">
              {translations?.login}
            </Button>
          </div>
          <div className="text-white text-center pt-20">
            <h1 className="text-3xl sm:text-5xl uppercase font-bold">
              {translations?.title?.part1}{' '}
              <span className="outline-text">{currentRegion.name_fr || 'Nom de la région'}</span>
            </h1>
            <p className="font-light mt-4">{translations?.subheading}</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-16">
            <CardButton
              title={translations?.card1?.title}
              ActionText={translations?.card1?.actionText}
              to={`/school-group-selection?registration-group=${group}&region=${region}&registration-type=primary-school`}
              internalLink
            />
            <CardButton
              title={translations?.card2?.title}
              ActionText={translations?.card2?.actionText}
              to={`/school-group-selection?registration-group=${group}&region=${region}&registration-type=elementary-school`}
              internalLink
            />
            <CardButton
              title={translations?.card3?.title}
              ActionText={translations?.card3?.actionText}
              to={`/school-group-selection?registration-group=${group}&region=${region}&registration-type=agriculture-school`}
              internalLink
            />
            <CardButton
              title={translations?.card4?.title}
              ActionText={translations?.card4?.actionText}
              to={`/school-group-selection?registration-group=${group}&region=${region}&registration-type=cfa-mfr-school`}
              internalLink
            />
            <CardButton
              title={translations?.card5?.title}
              ActionText={translations?.card5?.actionText}
              to={`/school-group-selection?registration-group=${group}&region=${region}&registration-type=other-group`}
              internalLink
            />
            <div />
            <BookingCloseBox />
          </div>
          <div className="text-white">
            <h2 className="text-sm sm:text-2xl font-bold"> {t('registration.support.title')}</h2>
            <p className="font-light mt-1">
              {t('registration.support.subheading')}
              <span className="font-bold">
                <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
              </span>{' '}
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default EstablishmentTrainingTypeSelection;
