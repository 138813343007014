import clsx from 'clsx';

export const PlusButtonIcon = ({ className, onClick = () => {} }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="19" height="19" rx="5" fill="#515151" />
      <path
        d="M9.5 5L9.5 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M14 9.5L5 9.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CrossButtonIcon = ({ className, onClick = () => {} }) => {
  return (
    <svg
      className={clsx('cursor-pointer', className)}
      onClick={onClick}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.273438" y="0.273438" width="22.7279" height="22.7279" rx="5" fill="currentColor" />
      <path
        d="M14.8203 8.45312L8.45635 14.8171"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M14.8203 14.8203L8.45635 8.45635"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const VisibilityButtonIcon = ({ className, color = '#000000', onClick = () => {} }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 14C6 15.1046 5.10457 16 4 16C2.89543 16 2 15.1046 2 14L2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4L6 14Z"
        fill={color}
      />
      <path
        d="M14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10L10 4C10 2.89543 10.8954 2 12 2C13.1046 2 14 2.89543 14 4L14 10Z"
        fill={color}
      />
      <path
        d="M22 20C22 21.1046 21.1046 22 20 22C18.8954 22 18 21.1046 18 20L18 4C18 2.89543 18.8954 2 20 2C21.1046 2 22 2.89543 22 4L22 20Z"
        fill={color}
      />
    </svg>
  );
};

export const RadioIcon = ({ className, isChecked = false }) => {
  return (
    <svg
      className={className}
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="12" cy="11" rx="11.5" ry="11" fill={isChecked ? '#7C7D82' : '#E5E8ED'} />
      {isChecked && (
        <path
          d="M7.07031 11.1676L9.55387 13.5015C9.93877 13.8633 10.5386 13.8633 10.9235 13.5015L16.9275 7.85938"
          stroke="white"
          strokeLinecap="round"
        />
      )}
    </svg>
  );
};

export const FunnelIcon = ({ className, color = '#000000', onClick = () => {} }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.96875 2.46875C1.96875 2.2947 2.03789 2.12778 2.16096 2.00471C2.28403 1.88164 2.45095 1.8125 2.625 1.8125H18.375C18.549 1.8125 18.716 1.88164 18.839 2.00471C18.9621 2.12778 19.0312 2.2947 19.0312 2.46875V5.09375C19.0312 5.25559 18.9714 5.41172 18.8632 5.53213L13.125 11.9083V18.2188C13.1249 18.3564 13.0815 18.4906 13.0009 18.6022C12.9204 18.7139 12.8067 18.7974 12.6761 18.8409L8.73863 20.1534C8.64002 20.1862 8.53503 20.1952 8.43229 20.1795C8.32955 20.1638 8.232 20.124 8.14767 20.0632C8.06334 20.0025 7.99465 19.9226 7.94725 19.8301C7.89984 19.7376 7.87508 19.6352 7.875 19.5312V11.9083L2.13675 5.53213C2.02861 5.41172 1.96878 5.25559 1.96875 5.09375V2.46875ZM3.28125 3.125V4.84175L9.0195 11.2179C9.12764 11.3383 9.18747 11.4944 9.1875 11.6562V18.6204L11.8125 17.7463V11.6562C11.8125 11.4944 11.8724 11.3383 11.9805 11.2179L17.7188 4.84175V3.125H3.28125Z"
        fill={color}
      />
    </svg>
  );
};

export const InfoIcon = ({ className, color = '#000000', onClick = () => {} }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="9" stroke={color} strokeWidth="2" />
      <path
        d="M10.5 6C10.5 6.27614 10.2761 6.5 10 6.5C9.72386 6.5 9.5 6.27614 9.5 6C9.5 5.72386 9.72386 5.5 10 5.5C10.2761 5.5 10.5 5.72386 10.5 6Z"
        fill={color}
        stroke={color}
      />
      <path d="M10 15.5V8.5" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export const PlusIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="1" width="17" height="17" rx="4" stroke="white" strokeWidth="2" />
      <path
        d="M10 5L10 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 9.5L5.5 9.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MinusIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="1.5" width="17" height="18" rx="4" stroke="white" strokeWidth="2" />
      <path
        d="M14.5 10.5L5.5 10.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 13.125C24 18.7169 19.4669 23.25 13.875 23.25C8.28312 23.25 3.75 18.7169 3.75 13.125C3.75 7.53312 8.28312 3 13.875 3C19.4669 3 24 7.53312 24 13.125ZM7.96079 17.625L8.66789 16.9179L12.4608 13.125L8.66789 9.33211L7.96079 8.625L9.375 7.21079L10.0821 7.91789L13.875 11.7108L17.6679 7.91789L18.375 7.21079L19.7892 8.625L19.0821 9.33211L15.2892 13.125L19.0821 16.9179L19.7892 17.625L18.375 19.0392L17.6679 18.3321L13.875 14.5392L10.0821 18.3321L9.375 19.0392L7.96079 17.625Z"
        fill="#929292"
      />
    </svg>
  );
};

export const ExportIcon = ({ className, color = '#000000' }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 4.57812L8.96967 4.04779L9.5 3.51746L10.0303 4.04779L9.5 4.57812ZM10.25 11.3281C10.25 11.7423 9.91421 12.0781 9.5 12.0781C9.08579 12.0781 8.75 11.7423 8.75 11.3281L10.25 11.3281ZM5.21967 7.79779L8.96967 4.04779L10.0303 5.10845L6.28033 8.85846L5.21967 7.79779ZM10.0303 4.04779L13.7803 7.79779L12.7197 8.85845L8.96967 5.10845L10.0303 4.04779ZM10.25 4.57812L10.25 11.3281L8.75 11.3281L8.75 4.57812L10.25 4.57812Z"
        fill={color}
      />
      <path
        d="M4.25 12.8281L4.25 13.5781C4.25 14.4066 4.92157 15.0781 5.75 15.0781L13.25 15.0781C14.0784 15.0781 14.75 14.4066 14.75 13.5781V12.8281"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
