import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

export const exportData = (headers, data, name = 'Data-Export') => {
  try {
    const headerKeys = headers.map((header) => header.key);
    const wb = XLSX.utils.book_new();
    // Initialize the sheet with headers
    const worksheet = XLSX.utils.json_to_sheet([], {
      header: headers.map((header) => header.name)
    });

    // Process and add data to the "name" sheet, ensuring data matches the headers
    data.forEach((dataItem) => {
      // Create a new object that only includes keys present in headerKeys
      const filteredDataItem = headerKeys.reduce((acc, key) => {
        if (dataItem.hasOwnProperty(key)) {
          acc[headers.find((h) => h.key === key).name] = dataItem[key]; // Use the header name for the key
        }
        return acc;
      }, {});

      // Directly adding filtered data to the worksheet
      XLSX.utils.sheet_add_json(worksheet, [filteredDataItem], {
        skipHeader: true,
        origin: -1
      });
    });

    // Append the populated "name" worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, worksheet, name);

    // Write the workbook to a file
    XLSX.writeFile(wb, `${name}.xlsx`);
  } catch (error) {
    console.error('Failed to export data', error);
    toast.error('It eksportearjen fan gegevens is mislearre.');
  }
};
