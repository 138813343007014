import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';

const Checkbox = ({
  isChecked,
  onChange,
  isInline = false,
  darkLabel = false,
  name = '',
  id,
  label,
  error,
  touched,
  ...props
}) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div>
      <label htmlFor={id} className={`relative ${isInline ? 'flex gap-4 items-start' : ''}`}>
        <div className="container-clip h-12 w-20">
          <div className="clip-item bg-blue-999 h-full">
            {isChecked && <FaCheck className="text-red-550 text-5xl mx-auto " />}
          </div>
        </div>

        <p
          className={`text-left ${isInline ? ' ml-1 w-[calc(100%_-5.5rem)]' : 'ml-4'} ${
            darkLabel ? 'text-red-550' : 'text-white'
          }`}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </label>

      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);
          onChange(e);
        }}
        className="hidden"
        {...props}
      />
      {error && touched && (
        <p className="text text-red-500 absolute" data-testid="error-text">
          {t(error)}
        </p>
      )}
    </div>
  );
};

export default Checkbox;
