import { InfoIcon } from '../../assets';

function InfoBox({ children }) {
  return (
    <div className="flex items-center gap-5 bg-gray-200 rounded p-4 font-light">
      <InfoIcon className="w-12" />
      {children}
    </div>
  );
}

export default InfoBox;
