import React, { useState, createContext } from 'react';
export const AdminContext = createContext();
export const AdminContextProvider = ({ children }) => {
  const [adminProfile, setAdminProfile] = useState({
    loading: true
  });
  return (
    <AdminContext.Provider value={[adminProfile, setAdminProfile]}>
      {children}
    </AdminContext.Provider>
  );
};
