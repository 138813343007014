import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { PiWarningFill } from 'react-icons/pi';
import { FaAngleLeft } from 'react-icons/fa';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Header,
  Button,
  ResponsibleInformartionForm,
  ClassInformationForm
} from '../../components';

import { createNewClassSchema, otherGroupCreateNewClassSchema } from '../../utils/schema';
import { getClassByIdAsAdmin, updateClassByIdAsAdmin } from '../../service';
import { otherGroupForms } from '../../utils/values';

function EditClass() {
  const { id } = useParams();
  const location = useLocation();
  const [registrationType, setRegistrationType] = useState(false);
  const [isOtherGroupForm, setIsOtherGroupForm] = useState(false);

  const { t } = useTranslation();

  const translations = t('adminEditClass', { returnObjects: true });

  const params = new URLSearchParams(location.search);

  const organization = params.get('organization');

  const getPageData = async () => {
    try {
      const classObj = await getClassByIdAsAdmin(id);

      // check for other-group & foreign-group
      const classRegistrationType = classObj?.organization?.registrationType;
      setRegistrationType(classRegistrationType);

      const isOtherGroupForm = otherGroupForms.includes(classRegistrationType);
      setIsOtherGroupForm(isOtherGroupForm);

      const restructuredData = {
        responsible: [
          {
            firstName: classObj.teacher.firstName,
            lastName: classObj.teacher.lastName,
            email: classObj.teacher.email,
            phone: classObj.teacher.phone,
            position: classObj.teacher.position,
            _id: classObj.teacher._id,
            classes: [
              {
                _id: classObj._id,
                classLevel: classObj.classLevel,
                classCount: classObj.classCount,
                supervisorCount: classObj.supervisorCount,
                visitorsCount: classObj.visitorsCount,
                classNumber: classObj.classNumber,
                visitDay: `${classObj.visitDay}-responsible[${0}].classes[${0}].visitDay`,
                underContract: `responsible[${0}].classes[${0}].underContract-${classObj.underContract}`,
                estimatedArrivalTime: classObj.estimatedArrivalTime,
                estimatedTimeOnSite: classObj.estimatedTimeOnSite
              }
            ]
          }
        ]
      };

      formik.setValues(restructuredData);
      console.log(classObj);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const restructuredData = {
        teacher: {
          firstName: values.responsible[0].firstName,
          lastName: values.responsible[0].lastName,
          email: values.responsible[0].email,
          phone: values.responsible[0].phone,
          position: values.responsible[0].position,
          _id: values.responsible[0]._id
        },
        classLevel: values.responsible[0].classes[0].classLevel,
        classCount: values.responsible[0].classes[0].classCount,
        supervisorCount: isOtherGroupForm ? '' : values.responsible[0].classes[0].supervisorCount,
        visitorsCount: isOtherGroupForm
          ? values.responsible[0].classes[0].classCount
          : values.responsible[0].classes[0].visitorsCount,
        classNumber: values.responsible[0].classes[0].classNumber,
        visitDay: values.responsible[0].classes[0].visitDay.split('-')[0],
        underContract: values.responsible[0].classes[0].underContract.split('-')[1],
        estimatedArrivalTime: values.responsible[0].classes[0].estimatedArrivalTime,
        estimatedTimeOnSite: values.responsible[0].classes[0].estimatedTimeOnSite
      };

      await updateClassByIdAsAdmin(id, restructuredData);
      toast.success('Classe mise à jour avec succès');
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      responsible: [
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          position: '',
          classes: [
            {
              classLevel: '',
              classCount: '',
              supervisorCount: '',
              visitorsCount: '',
              classNumber: '',
              visitDay: '',
              underContract: '',
              estimatedArrivalTime: '',
              estimatedTimeOnSite: ''
            }
          ]
        }
      ]
    },
    validationSchema: isOtherGroupForm ? otherGroupCreateNewClassSchema : createNewClassSchema
  });

  return (
    <main className="min-h-screen bg-white text-black pb-10">
      <Header isAuthenticated adminHeader backgroundColor="bg-white" />
      <div className="container mt-5 mx-auto">
        <Button
          className="text-black flex items-center justify-center px-4"
          backgroundColor="bg-gray-200"
          type="button"
          internalLink
          to={'/admin/organization/' + organization}>
          <FaAngleLeft className="text-xl" />
        </Button>
      </div>
      <form onSubmit={formik.handleSubmit} className="container mt-5 mx-auto">
        {formik.values.responsible.map((_, index) => (
          <>
            <ResponsibleInformartionForm
              formik={formik}
              index={index}
              inputLight
              title={translations?.responsibleInformartionForm?.title}
              darkText
            />

            <ClassInformationForm
              title={translations?.classInformationForm?.title}
              inputLight
              responsibleIndex={index}
              otherGroupForm={isOtherGroupForm}
              formik={formik}
              darkText
            />
          </>
        ))}

        <section className="py-10 text-black">
          <p className="font-thin flex justify-start">
            <PiWarningFill className="text-yellow-500 mt-1 mr-1" />
            {translations?.warning}
          </p>
          <p>{translations?.info}</p>
        </section>
        <Button
          className="text-black px-4 font-normal uppercase my-6"
          backgroundColor="bg-gray-200"
          type="submit">
          <p className="ml-1">{translations?.submit}</p>
        </Button>
        <div
          className="grid grid-cols-1 gap-4 text-black"
          dangerouslySetInnerHTML={{ __html: translations?.bottomInfo }}
        />
      </form>
    </main>
  );
}

export default EditClass;
