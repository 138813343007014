import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Autocomplete = ({
  options = [],
  openByDefault = false,
  search,
  setSearch,
  onChange,
  value,
  label,
  inputLight = false,
  error,
  touched,
  ...props
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(openByDefault);

  const handleInputChange = (e) => {
    console.log('e.target.value', e.target.value);
    setSearch(e.target.value);
    setIsOpen(true);
  };

  const handleOptionClick = (value) => {
    setSearch(value);
    onChange({ target: { value: value, name: props.name } });
    setIsOpen(false);
  };

  return (
    <div className="relative w-full text-white">
      <div className="relative w-full">
        <label className="ml-0">{label}</label>
        <div className="container-clip mt-3">
          <input
            className={`focus-within:outline-none clip-item ${
              inputLight
                ? 'bg-white text-black disabled:bg-gray-200'
                : 'bg-blue-999 text-white disabled:bg-gray-900'
            }  px-3.5 py-2.5 pl-5 disabled:opacity-100`}
            {...props}
            value={search}
            onChange={handleInputChange}
          />
        </div>
        {error && touched && (
          <p className="text text-red-500 absolute" data-testid="error-text">
            {t(error)}
          </p>
        )}
      </div>
      {isOpen && (
        <ul className="absolute z-10 mt-1 w-full overflow-auto bg-blue-999 border border-gray-300 rounded-md shadow-lg list-none px-0">
          {options.map((option, index) => (
            <li
              key={index}
              className="px-4 py-2 cursor-pointer hover:bg-gray-950"
              onClick={() => handleOptionClick(option.value)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
