import { useTranslation } from 'react-i18next';
import { FaWindowClose } from 'react-icons/fa';

import { BookingCloseBox, Dropdown, Input, Radio } from '../common';
import { isEmpty } from 'lodash';

function ClassInformationForm({
  formik,
  responsibleIndex,
  title = 'Nombre d’élèves prévuS',
  inputLight = false,
  darkText = false,
  classCheckboxesLabel = '',
  otherGroupForm = false,
  isRegistrationForm = false,
  deletedClass = () => {}
}) {
  const { t } = useTranslation();

  let translations = t('classInformationForm', { returnObjects: true });

  translations = otherGroupForm ? translations['other-group'] : translations.default;

  const handleTotalvisitorsCount = (formik, responsibleIndex, index, props) => {
    if (!props) return;

    let classCount, supervisorCount;

    if (props.target.name === `responsible[${responsibleIndex}].classes[${index}].classCount`) {
      classCount = props.target.value;
    } else {
      classCount = formik.values.responsible[responsibleIndex].classes[index].classCount;
    }
    if (
      props.target.name === `responsible[${responsibleIndex}].classes[${index}].supervisorCount`
    ) {
      supervisorCount = props.target.value;
    } else {
      supervisorCount = formik.values.responsible[responsibleIndex].classes[index].supervisorCount;
    }

    if (!classCount || !supervisorCount) return;
    formik.setFieldValue(
      `responsible[${responsibleIndex}].classes[${index}].visitorsCount`,
      parseInt(classCount) + parseInt(supervisorCount)
    );
  };

  const handleDeleteSection = (index) => {
    const updatedClasses = [...formik.values.responsible[responsibleIndex].classes];
    const deleteItem = updatedClasses.splice(index, 1);
    deletedClass(deleteItem[0]._id);
    formik.setFieldValue(`responsible[${responsibleIndex}].classes`, updatedClasses);
  };

  const getHoursOptions = (index) => {
    const commonHours = [
      { label: '10.00', value: '10.00' },
      { label: '10.30', value: '10.30' },
      { label: '11.00', value: '11.00' },
      { label: '11.30', value: '11.30' },
      { label: '12.00', value: '12.00' },
      { label: '12.30', value: '12.30' },
      { label: '13.00', value: '13.00' },
      { label: '13.30', value: '13.30' },
      { label: '14.00', value: '14.00' },
      { label: '14.30', value: '14.30' },
      { label: '15.00', value: '15.00' },
      { label: '15.30', value: '15.30' },
      { label: '16.00', value: '16.00' }
    ];
    if (formik.values.responsible?.[responsibleIndex].classes?.[index]?.visitDay.includes('11.09'))
      return commonHours;
    else
      return [
        {
          label: '09.00',
          value: '09.00'
        },
        {
          label: '09.30',
          value: '09.30'
        },
        ...commonHours
      ];
  };

  return formik.values.responsible?.[responsibleIndex].classes?.map((_, index) => (
    <section
      key={index}
      id="student-form"
      className={`py-10 ${darkText ? 'text-black' : 'bg-opacity-30'}`}>
      <h1
        className={`text-center font-light uppercase  ${
          darkText ? 'bg-gray-200 text-black' : 'bg-gray-200 bg-opacity-30'
        } py-2`}>
        {title}
      </h1>
      {index > 0 && (
        <button
          type="button"
          className="mt-4 px-4 py-2 rounded"
          onClick={() => handleDeleteSection(index)}>
          <FaWindowClose />
        </button>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-5 items-end">
        <Input
          inputLight={inputLight}
          label={translations?.classLevel?.label}
          placeholder={translations?.classLevel?.placeholder}
          name={`responsible[${responsibleIndex}].classes[${index}].classLevel`}
          onChange={formik.handleChange}
          error={
            formik.errors.responsible &&
            formik.errors.responsible[responsibleIndex] &&
            formik.errors.responsible[responsibleIndex].classes &&
            formik.errors.responsible[responsibleIndex].classes[index] &&
            formik.errors.responsible[responsibleIndex].classes[index].classLevel
          }
          touched={
            formik.touched.responsible &&
            formik.touched.responsible[responsibleIndex] &&
            formik.touched.responsible[responsibleIndex].classes &&
            formik.touched.responsible[responsibleIndex].classes[index] &&
            formik.touched.responsible[responsibleIndex].classes[index].classLevel
          }
          value={formik.values.responsible[responsibleIndex].classes[index].classLevel}
        />
        <Dropdown
          inputLight={inputLight}
          label={translations?.classCount?.label}
          placeholder={translations?.classCount?.placeholder}
          name={`responsible[${responsibleIndex}].classes[${index}].classCount`}
          onChange={(props) => {
            formik.handleChange(props);
            handleTotalvisitorsCount(formik, responsibleIndex, index, props);
          }}
          error={
            formik.errors.responsible &&
            formik.errors.responsible[responsibleIndex] &&
            formik.errors.responsible[responsibleIndex].classes &&
            formik.errors.responsible[responsibleIndex].classes[index] &&
            formik.errors.responsible[responsibleIndex].classes[index].classCount
          }
          touched={
            formik.touched.responsible &&
            formik.touched.responsible[responsibleIndex] &&
            formik.touched.responsible[responsibleIndex].classes &&
            formik.touched.responsible[responsibleIndex].classes[index] &&
            formik.touched.responsible[responsibleIndex].classes[index].classCount
          }
          value={formik.values.responsible[responsibleIndex].classes[index].classCount}
          options={[...new Array(50)].map((_, i) => ({
            label: i.toString(),
            value: i.toString()
          }))}
        />
        {!otherGroupForm && (
          <Dropdown
            inputLight={inputLight}
            label={translations?.supervisorCount?.label}
            placeholder={translations?.supervisorCount?.placeholder}
            name={`responsible[${responsibleIndex}].classes[${index}].supervisorCount`}
            onChange={(props) => {
              formik.handleChange(props);
              handleTotalvisitorsCount(formik, responsibleIndex, index, props);
            }}
            error={
              formik.errors.responsible &&
              formik.errors.responsible[responsibleIndex] &&
              formik.errors.responsible[responsibleIndex].classes &&
              formik.errors.responsible[responsibleIndex].classes[index] &&
              formik.errors.responsible[responsibleIndex].classes[index].supervisorCount
            }
            touched={
              formik.touched.responsible &&
              formik.touched.responsible[responsibleIndex] &&
              formik.touched.responsible[responsibleIndex].classes &&
              formik.touched.responsible[responsibleIndex].classes[index] &&
              formik.touched.responsible[responsibleIndex].classes[index].supervisorCount
            }
            value={formik.values.responsible[responsibleIndex].classes[index].supervisorCount}
            options={[...new Array(20)].map((_, i) => ({
              label: i.toString(),
              value: i.toString()
            }))}
          />
        )}
        {!otherGroupForm && (
          <Input
            inputLight={inputLight}
            label={translations?.visitorsCount?.label}
            placeholder={translations?.visitorsCount?.placeholder}
            name={`responsible[${responsibleIndex}].classes[${index}].visitorsCount`}
            onChange={formik.handleChange}
            error={
              formik.errors.responsible &&
              formik.errors.responsible[responsibleIndex] &&
              formik.errors.responsible[responsibleIndex].classes &&
              formik.errors.responsible[responsibleIndex].classes[index] &&
              formik.errors.responsible[responsibleIndex].classes[index].visitorsCount
            }
            disabled
            touched={
              formik.touched.responsible &&
              formik.touched.responsible[responsibleIndex] &&
              formik.touched.responsible[responsibleIndex].classes &&
              formik.touched.responsible[responsibleIndex].classes[index] &&
              formik.touched.responsible[responsibleIndex].classes[index].visitorsCount
            }
            value={formik.values.responsible[responsibleIndex].classes[index].visitorsCount}
          />
        )}
        {!otherGroupForm && (
          <Input
            inputLight={inputLight}
            label={translations?.classNumber?.label}
            placeholder={translations?.classNumber?.placeholder}
            name={`responsible[${responsibleIndex}].classes[${index}].classNumber`}
            onChange={formik.handleChange}
            error={
              formik.errors.responsible &&
              formik.errors.responsible[responsibleIndex] &&
              formik.errors.responsible[responsibleIndex].classes &&
              formik.errors.responsible[responsibleIndex].classes[index] &&
              formik.errors.responsible[responsibleIndex].classes[index].classNumber
            }
            touched={
              formik.touched.responsible &&
              formik.touched.responsible[responsibleIndex] &&
              formik.touched.responsible[responsibleIndex].classes &&
              formik.touched.responsible[responsibleIndex].classes[index] &&
              formik.touched.responsible[responsibleIndex].classes[index].classNumber
            }
            value={formik.values.responsible[responsibleIndex].classes[index].classNumber}
          />
        )}

        <div className="flex flex-col items-start justify-end col-span-2 md:col-span-1 relative">
          <p className="mb-2 ml-4">{translations?.visitDay?.label}</p>
          {isRegistrationForm && (
            <div className="my-5">
              <BookingCloseBox isSimple />
            </div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
            <Radio
              inputLight={inputLight}
              label="me 11.09"
              isInline
              name={`responsible[${responsibleIndex}].classes[${index}].visitDay`}
              id={`11.09-responsible[${responsibleIndex}].classes[${index}].visitDay`}
              value={formik.values.responsible?.[responsibleIndex].classes?.[index]?.visitDay}
              onChange={formik.handleChange}
            />
            <Radio
              inputLight={inputLight}
              label="je 12.09"
              isInline
              disabled={isRegistrationForm}
              name={`responsible[${responsibleIndex}].classes[${index}].visitDay`}
              id={`12.09-responsible[${responsibleIndex}].classes[${index}].visitDay`}
              value={formik.values.responsible?.[responsibleIndex].classes?.[index]?.visitDay}
              onChange={formik.handleChange}
            />
            <Radio
              inputLight={inputLight}
              label="ve 13.09"
              isInline
              disabled={isRegistrationForm}
              name={`responsible[${responsibleIndex}].classes[${index}].visitDay`}
              id={`13.09-responsible[${responsibleIndex}].classes[${index}].visitDay`}
              value={formik.values.responsible?.[responsibleIndex].classes?.[index]?.visitDay}
              onChange={formik.handleChange}
            />
            <Radio
              inputLight={inputLight}
              label="sa 14.09"
              isInline
              name={`responsible[${responsibleIndex}].classes[${index}].visitDay`}
              id={`14.09-responsible[${responsibleIndex}].classes[${index}].visitDay`}
              value={formik.values.responsible?.[responsibleIndex].classes?.[index]?.visitDay}
              onChange={formik.handleChange}
            />
          </div>
          {formik.errors?.responsible?.[responsibleIndex]?.classes?.[index]?.visitDay &&
            formik.touched?.responsible?.[responsibleIndex]?.classes?.[index]?.visitDay && (
              <p className="text text-red-500 absolute -bottom-6 ">
                {t(formik.errors.responsible[responsibleIndex].classes?.[index]?.visitDay)}
              </p>
            )}
        </div>
        <div className="flex items-center justify-between relative">
          <p>{classCheckboxesLabel ? classCheckboxesLabel : translations?.underContract?.label}</p>
          <div className="flex">
            <Radio
              inputLight={inputLight}
              label="Oui"
              name={`responsible[${responsibleIndex}].classes[${index}].underContract`}
              id={`responsible[${responsibleIndex}].classes[${index}].underContract-yes`}
              darkLabel
              value={formik.values.responsible[responsibleIndex].classes[index].underContract}
              onChange={formik.handleChange}
            />
            <Radio
              inputLight={inputLight}
              label="Non"
              name={`responsible[${responsibleIndex}].classes[${index}].underContract`}
              id={`responsible[${responsibleIndex}].classes[${index}].underContract-no`}
              darkLabel
              value={formik.values.responsible[responsibleIndex].classes[index].underContract}
              onChange={formik.handleChange}
            />
          </div>
          {formik.errors?.responsible?.[responsibleIndex]?.classes?.[index]?.underContract &&
            formik.touched?.responsible?.[responsibleIndex]?.classes?.[index]?.underContract && (
              <p className="text text-red-500 absolute -bottom-6 ">
                {t(formik.errors.responsible[responsibleIndex].classes?.[index]?.underContract)}
              </p>
            )}
        </div>
        <div className="flex">
          <Dropdown
            inputLight={inputLight}
            label={translations?.estimatedArrivalTime?.label}
            placeholder={translations?.estimatedArrivalTime?.placeholder}
            name={`responsible[${responsibleIndex}].classes[${index}].estimatedArrivalTime`}
            selected={
              formik.values.responsible[responsibleIndex].classes[index].estimatedArrivalTime
            }
            error={
              formik.errors.responsible &&
              formik.errors.responsible[responsibleIndex] &&
              formik.errors.responsible[responsibleIndex].classes &&
              formik.errors.responsible[responsibleIndex].classes[index] &&
              formik.errors.responsible[responsibleIndex].classes[index].estimatedArrivalTime
            }
            touched={
              formik.touched.responsible &&
              formik.touched.responsible[responsibleIndex] &&
              formik.touched.responsible[responsibleIndex].classes &&
              formik.touched.responsible[responsibleIndex].classes[index] &&
              formik.touched.responsible[responsibleIndex].classes[index].estimatedArrivalTime
            }
            disabled={isEmpty(
              formik.values.responsible?.[responsibleIndex].classes?.[index]?.visitDay
            )}
            title={
              isEmpty(formik.values.responsible?.[responsibleIndex].classes?.[index]?.visitDay)
                ? 'Sélectionner ' + translations?.visitDay?.label
                : ''
            }
            options={getHoursOptions(index)}
            onChange={formik.handleChange}
          />
          <Dropdown
            inputLight={inputLight}
            label={translations?.estimatedTimeOnSite?.label}
            placeholder={translations?.estimatedTimeOnSite?.placeholder}
            name={`responsible[${responsibleIndex}].classes[${index}].estimatedTimeOnSite`}
            selected={
              formik.values.responsible[responsibleIndex].classes[index].estimatedTimeOnSite
            }
            error={
              formik.errors.responsible &&
              formik.errors.responsible[responsibleIndex] &&
              formik.errors.responsible[responsibleIndex].classes &&
              formik.errors.responsible[responsibleIndex].classes[index] &&
              formik.errors.responsible[responsibleIndex].classes[index].estimatedTimeOnSite
            }
            touched={
              formik.touched.responsible &&
              formik.touched.responsible[responsibleIndex] &&
              formik.touched.responsible[responsibleIndex].classes &&
              formik.touched.responsible[responsibleIndex].classes[index] &&
              formik.touched.responsible[responsibleIndex].classes[index].estimatedTimeOnSite
            }
            options={[
              { label: '2 heures', value: '2' },
              { label: '2.5 heures', value: '2.5' },
              { label: '3 heures', value: '3' },
              { label: '3.5 heures', value: '3.5' },
              { label: '4 heures', value: '4' },
              { label: '4.5 heures', value: '4.5' },
              { label: '5 heures', value: '5' },
              { label: '5.5 heures', value: '5.5' },
              { label: '6 heures', value: '6' }
            ]}
            onChange={formik.handleChange}
          />
        </div>
      </div>
    </section>
  ));
}

export default ClassInformationForm;
