import React from 'react';
import { useTranslation } from 'react-i18next';

const Input = ({ label, className = '', touched, error, inputLight, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="relative w-full">
        <label className="ml-0">{label}</label>
        <div className="container-clip mt-3">
          <input
            className={`focus-within:outline-none clip-item ${
              inputLight
                ? 'bg-white text-black disabled:bg-gray-200'
                : 'bg-blue-999 text-white disabled:bg-gray-900'
            }  px-3.5 py-2.5 pl-5 disabled:opacity-100`}
            {...props}
          />
        </div>
        {error && touched && (
          <p className="text text-red-500 absolute" data-testid="error-text">
            {t(error)}
          </p>
        )}
      </div>
    </>
  );
};

export default Input;
