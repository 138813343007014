import { useTranslation } from 'react-i18next';
import { IoMdRefresh } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import Button from './button';

function Header({
  isAuthenticated = false,
  title = '',
  adminHeader = false,
  backgroundColor = 'bg-purple-950'
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('adminToken');
    navigate('/');
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return isAuthenticated ? (
    <header className={`${backgroundColor}  h-32 w-full sticky top-0 z-40 px-2`}>
      <div className="flex justify-between items-center">
        <img
          src={adminHeader ? '/wsl-logo-light.svg' : '/wsl-logo.svg'}
          alt="wsf-logo"
          className="h-full py-4"
        />
        <p className="uppercase">{title}</p>
        <div className={`grid ${adminHeader ? 'grid-cols-1' : 'grid-cols-2'} gap-2`}>
          {!adminHeader && (
            <Button
              className="uppercase border-blue-999 border-2 px-4"
              backgroundColor="bg-blue-650"
              onClick={handleRefresh}>
              <span className="flex items-center">
                <IoMdRefresh className="rotate-90 text-lg mr-1" />
                {t('header.refresh')}
              </span>
            </Button>
          )}
          <Button
            className={`uppercase ${
              adminHeader ? 'border-gray-200' : 'border-red-550'
            }  border-2 px-4`}
            backgroundColor="bg-blue-650"
            onClick={logout}>
            {t('header.logout')}
          </Button>
        </div>
      </div>
    </header>
  ) : (
    <header className={`${backgroundColor} h-32 w-full sticky top-0 z-10 px-2`}>
      <img src="/wsl-logo.svg" alt="wsf-logo" className="h-full py-4" />
    </header>
  );
}

export default Header;
