import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { PiWarningFill } from 'react-icons/pi';
import { findIndex } from 'lodash';
import { Dropdown, Input, Radio, Button, BookingCloseBox } from '../common';

function StudentForm({
  formik,
  inputLight,
  darkText = false,
  otherGroupForm = false,
  isRegistrationForm = false
}) {
  const { t } = useTranslation();

  let translations = t('studentForm', { returnObjects: true });
  translations = otherGroupForm ? translations['other-group'] : translations.default;

  const fields = translations?.fields;

  const handleAddMoreVisits = () => {
    formik.setFieldValue('studentInformation', [
      ...formik.values.studentInformation,
      {
        studentLevel: '',
        studentCount: '',
        visitDay: ''
      }
    ]);
  };

  const accumelatedStudentCountBasedOnVisitDay = () => {
    const accumelatedStudentCountBasedOnVisitDay = [];
    for (const item of formik.values?.studentInformation) {
      if (
        findIndex(accumelatedStudentCountBasedOnVisitDay, {
          visitDay: item?.visitDay?.split('-').slice(0, -1).join('-')
        }) === -1
      ) {
        accumelatedStudentCountBasedOnVisitDay.push({
          visitDay: item?.visitDay?.split('-').slice(0, -1).join('-'),
          studentCount: parseInt(item.studentCount) || 0
        });
      } else {
        const index = findIndex(accumelatedStudentCountBasedOnVisitDay, {
          visitDay: item.visitDay?.split('-').slice(0, -1).join('-')
        });
        accumelatedStudentCountBasedOnVisitDay[index].studentCount += parseInt(
          item.studentCount || 0
        );
      }
    }
    return accumelatedStudentCountBasedOnVisitDay;
  };

  return (
    <section className={`py-10 ${darkText ? ' text-black' : 'text-white '}`}>
      <h1
        className={`text-center font-light uppercase py-2 ${
          darkText ? 'bg-gray-200 text-black' : 'bg-white bg-opacity-30 text-white'
        }`}>
        {translations?.title}
      </h1>
      {formik.values.studentInformation?.map(({}, index) => (
        <div
          key={index}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-5 mt-5 items-end">
          {!otherGroupForm && (
            <Input
              inputLight={inputLight}
              label={fields?.studentLevel?.label}
              placeholder={fields?.studentLevel?.placeholder}
              name={`studentInformation[${index}].studentLevel`}
              onChange={formik.handleChange}
              error={
                formik.errors.studentInformation &&
                formik.errors.studentInformation[index] &&
                formik.errors.studentInformation[index].studentLevel
              }
              touched={
                formik.touched.studentInformation &&
                formik.touched.studentInformation[index] &&
                formik.touched.studentInformation[index].studentLevel
              }
              value={formik.values.studentInformation[index].studentLevel}
            />
          )}
          <Dropdown
            inputLight={inputLight}
            label={fields?.studentCount?.label}
            placeholder={fields?.studentCount?.placeholder}
            name={`studentInformation[${index}].studentCount`}
            onChange={formik.handleChange}
            error={
              formik.errors.studentInformation &&
              formik.errors.studentInformation[index] &&
              formik.errors.studentInformation[index].studentCount
            }
            touched={
              formik.touched.studentInformation &&
              formik.touched.studentInformation[index] &&
              formik.touched.studentInformation[index].studentCount
            }
            value={formik.values.studentInformation[index].studentCount}
            options={[...new Array(300)].map((_, i) => ({
              label: i.toString(),
              value: i.toString()
            }))}
          />

          <div className="flex flex-col items-start justify-end col-span-1 sm:col-span-3">
            <p className="mb-4 ml-4">{fields?.visitDay?.label}</p>
            {isRegistrationForm && (
              <div className="my-5">
                <BookingCloseBox isSimple />
              </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 relative">
              <Radio
                inputLight={inputLight}
                darkLabel={inputLight}
                label="me 11.09"
                isInline
                name={`studentInformation[${index}].visitDay`}
                id={`11.09-${index}`}
                value={formik.values.studentInformation[index]?.visitDay}
                onChange={formik.handleChange}
              />
              <Radio
                inputLight={inputLight}
                darkLabel={inputLight}
                label="je 12.09"
                isInline
                disabled={isRegistrationForm}
                name={`studentInformation[${index}].visitDay`}
                id={`12.09-${index}`}
                value={formik.values.studentInformation[index]?.visitDay}
                onChange={formik.handleChange}
              />
              <Radio
                inputLight={inputLight}
                darkLabel={inputLight}
                label="ve 13.09"
                isInline
                disabled={isRegistrationForm}
                name={`studentInformation[${index}].visitDay`}
                id={`13.09-${index}`}
                value={formik.values.studentInformation[index]?.visitDay}
                onChange={formik.handleChange}
              />
              <Radio
                inputLight={inputLight}
                darkLabel={inputLight}
                label="sa 14.09"
                isInline
                name={`studentInformation[${index}].visitDay`}
                id={`14.09-${index}`}
                value={formik.values.studentInformation[index]?.visitDay}
                onChange={formik.handleChange}
              />
              {formik.errors?.studentInformation?.[index]?.visitDay &&
                formik.touched?.studentInformation?.[index]?.visitDay && (
                  <p className="text text-red-500 absolute top-16" data-testid="error-text">
                    {t(formik.errors?.studentInformation[index]?.visitDay)}
                  </p>
                )}
            </div>
          </div>
        </div>
      ))}
      <Button
        className={`text-black flex items-center justify-center my-6 ${
          darkText ? 'bg-gray-200' : 'bg-white'
        }`}
        onClick={handleAddMoreVisits}
        type="button">
        <FaPlus />
        <p className="ml-1"> {fields?.handleMoreVisits}</p>
      </Button>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 gap-4 py-6 ${
          darkText ? 'text-black' : 'text-white'
        }`}>
        {accumelatedStudentCountBasedOnVisitDay().map((item, index) => (
          <div key={index} className="flex items-center">
            <p>
              {fields?.accumulatedVisits} <span>{item?.visitDay}</span>
            </p>
            <div
              className={`container-clip container-clip-background ${
                darkText ? 'container-clip-gray' : 'container-clip-light'
              } !px-12 !py-4 container-clip:before bg-opacity-30 ml-6`}>
              {item.studentCount}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center">
        <p>{fields?.totalVisits}</p>
        <div
          className={`container-clip container-clip-background ${
            darkText ? 'container-clip-gray' : 'container-clip-light'
          } !px-12 !py-4 container-clip:before bg-opacity-30 ml-6`}>
          {formik.values.studentInformation.reduce(
            (acc, item) => acc + Number(item.studentCount),
            0
          )}
        </div>
      </div>
      <p
        className={`font-thin flex justify-center mt-10 ${darkText ? 'text-black' : 'text-white'}`}>
        <PiWarningFill className="text-yellow-500 mt-1 mr-1" />
        {fields?.warning}
      </p>
    </section>
  );
}

export default StudentForm;
