import axios from 'axios';

const masterOrganization = '650bd89479ba3ae92524955b';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      // localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);

const adminAxios = axios.create();

adminAxios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

adminAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem('adminToken');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

adminAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.removeItem('adminToken');
    }
    return Promise.reject(error);
  }
);

export const busAxios = axios.create();

busAxios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

busAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      try {
        localStorage.removeItem('busToken');
      } catch (error) {
        console.log(error);
      }

      window.location.href = '/bus';
    }
    return Promise.reject(error);
  }
);

export const fetchTranslations = () => {
  return axios.get('/public/translations/ticketing-portal');
};

export const login = async (body) => {
  const lang = localStorage.getItem('lang') || 'fr';
  const response = await axios.post(
    '/auth/ticketing-portal',
    {
      ...body,
      masterOrganization
    },
    { params: { lang } }
  );
  return response.data;
};

export const adminLogin = async (body) => {
  const response = await axios.post('/auth/ticketing-portal/admin', {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const validateMagic = async (body) => {
  const response = await axios.post('/auth/ticketing-portal-magic', {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const validateAdminMagic = async (body) => {
  const response = await axios.post('/auth/ticketing-portal/admin-magic', {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const getAllRegions = async () => {
  const response = await axios.get('public/regions?organization=650bd89479ba3ae92524955b');
  return response.data;
};

export const RegistrationOfSchool = async (body) => {
  const lang = localStorage.getItem('lang') || 'fr';
  const response = await axios.post(
    '/ticketing-portal/school-registration',
    {
      ...body,
      masterOrganization
    },
    {
      params: { lang }
    }
  );
  return response.data;
};
export const teacherRegistClass = async (body) => {
  const response = await axios.post('/ticketing-portal/teacher-class-registration', {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const getRegistrationOfSchool = async () => {
  const response = await axios.get('/ticketing-portal/school-registration');
  return response.data;
};

export const putRegistrationOfSchool = async (body) => {
  const response = await axios.put('/ticketing-portal/school-registration', {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const getTeacher = async () => {
  const response = await axios.get('/ticketing-portal/teacher');
  return response.data;
};

export const getAllClasses = async () => {
  const response = await axios.get('/ticketing-portal/class');
  return response.data;
};

export const getClassById = async (id) => {
  const response = await axios.get(`/ticketing-portal/class/${id}`);
  return response.data;
};

export const updateClassById = async (id, body) => {
  const response = await axios.put(`/ticketing-portal/class/${id}`, {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const createNewClass = async (body) => {
  const lang = localStorage.getItem('lang') || 'fr';
  const response = await axios.post(
    `/ticketing-portal/class`,
    {
      ...body,
      masterOrganization
    },
    { params: { lang } }
  );
  return response.data;
};

export const addClassToOrganizationViaToken = async (body) => {
  const lang = localStorage.getItem('lang') || 'fr';
  const response = await axios.post(
    `/ticketing-portal/register-organization-class`,
    {
      ...body,
      masterOrganization
    },
    { params: { lang } }
  );
  return response.data;
};

export const getClassDetailsViaToken = async (token) => {
  const response = await axios.get(`/ticketing-portal/class-token-details/${token}`);
  return response.data;
};

export const patchConfirmTranspotation = async () => {
  const response = await axios.patch(`/ticketing-portal/confirm-transpotation`);
  return response.data;
};

export const getAllOrganizations = async (params) => {
  const response = await adminAxios.get(`/ticketing-portal/admin/organization`, { params });
  return response.data;
};

export const getAllClassOfOrganizations = async (params) => {
  const response = await adminAxios.get(`/ticketing-portal/admin/organization/classes`, { params });
  return response.data;
};

export const getOrganizationDetailForAdmin = async (id) => {
  const response = await adminAxios.get(`/ticketing-portal/admin/organization/class/${id}`);
  return response.data;
};

export const getAdminProfile = async () => {
  const response = await adminAxios.get(`/ticketing-portal/admin/me`);
  return response.data;
};

export const putRegistrationOfSchoolForAdmin = async (id, body) => {
  const response = await adminAxios.put(`/ticketing-portal/admin/organization/${id}`, {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const getWSFRegionalInformationAxios = async (params) => {
  const response = await adminAxios.get(
    `https://data.education.gouv.fr/api/explore/v2.1/catalog/datasets/fr-en-annuaire-education/records`
  );
  return response.data;
};

export const getWSFRegionalInformation = async (params) => {
  const url =
    `https://data.education.gouv.fr/api/explore/v2.1/catalog/datasets/fr-en-annuaire-education/records?` +
    new URLSearchParams({ limit: 20, ...params });
  const response = await fetch(url, {
    method: 'GET'
  });
  const data = await response.json();
  return data;
};

export const postAdminResponse = async (body) => {
  const response = await adminAxios.post(`ticketing-portal/admin/organization/admin-email`, {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const getAdminStats = async (params) => {
  const response = await adminAxios.get(`ticketing-portal/admin/stats`, {
    params
  });
  return response.data;
};

export const getClassByIdAsAdmin = async (id) => {
  const response = await adminAxios.get(`/ticketing-portal/admin/class/${id}`);
  return response.data;
};

export const updateClassByIdAsAdmin = async (id, body) => {
  const response = await adminAxios.put(`/ticketing-portal/admin/class/${id}`, {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const getResponsibles = async () => {
  const response = await axios.get('/ticketing-portal/organization/responsibles');
  console.log(response);
  return response.data;
};

export const getResponsiblesById = async (id) => {
  const response = await axios.get(`/ticketing-portal/organization/responsibles/${id}`);
  console.log(response);
  return response.data;
};

export const getTeacherRegisterClass = async (body) => {
  const response = await axios.get('/ticketing-portal/teacher-class-registration');
  return response.data;
};

export const updateTeacherRegisterClass = async (body) => {
  const lang = localStorage.getItem('lang') || 'fr';
  const response = await axios.put(
    '/ticketing-portal/teacher-class-registration',
    {
      ...body,
      masterOrganization
    },
    { params: { lang } }
  );
  return response.data;
};

export const addNotes = async (body) => {
  const response = await adminAxios.post('/ticketing-portal/admin/notes', {
    ...body
  });
  return response.data;
};

export const getNotesByType = async (params) => {
  const response = await adminAxios.get(`/ticketing-portal/admin/notes`, {
    params
  });
  return response.data;
};

export const getNotesById = async (id) => {
  const response = await adminAxios.get(`/ticketing-portal/admin/notes?id=${id}`);
  return response.data;
};

export const deleteOrganization = async (id) => {
  const response = await adminAxios.delete(`/ticketing-portal/admin/organization/${id}`);
  return response.data;
};

export const busSignupSubmit = async (body) => {
  const response = await axios.post('/ticketing-portal/bus-booking/signup', {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const busLoginSubmit = async (body) => {
  const response = await axios.post('/auth/ticketing-portal/bus-booking', {
    ...body,
    masterOrganization
  });
  return response.data;
};

export const validateBusMagic = async (body) => {
  const response = await axios.post('/auth/ticketing-portal/bus-booking-magic', {
    ...body
  });
  return response.data;
};

export const getAllBusSlots = async () => {
  const response = await busAxios.get('/ticketing-portal/bus-booking/block');
  return response.data;
};

export const getAllSlotsInBlock = async (blockId) => {
  const response = await busAxios.get(`/ticketing-portal/bus-booking/slots/${blockId}`);
  return response.data;
};

export const bookBusSlots = async (body) => {
  const response = await busAxios.post('/ticketing-portal/bus-booking/slot', {
    ...body
  });
  return response.data;
};
