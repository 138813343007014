import { useTranslation } from 'react-i18next';
import { IoInformationCircleOutline } from 'react-icons/io5';

import { Input } from '../common';

function ResponsibleInformationForm({
  title = '',
  formik,
  index,
  inputLight,
  darkText,
  infoHelperText,
  isDisabledForm = false
}) {
  const { t } = useTranslation();

  const translations = t('responsibleInformationForm', { returnObjects: true });
  const fields = translations?.fields;

  return (
    <section className="py-10">
      <div
        className={`text-center font-light uppercase py-2 relative ${
          darkText ? 'bg-gray-200 text-black' : 'bg-gray-200 bg-opacity-30'
        }`}>
        {infoHelperText && (
          <button className="absolute left-3 top-0 bottom-0 my-auto group" type="button">
            <IoInformationCircleOutline className="text-3xl" />
            <p
              className="hidden absolute z-20 bg-white p-4 rounded-md text-black w-96 max-w-screen-sm text-left group-hover:block"
              dangerouslySetInnerHTML={{ __html: infoHelperText }}
            />
          </button>
        )}
        <h1 className="">{title}</h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-5">
        <Input
          inputLight={inputLight}
          label={fields?.firstName?.label}
          placeholder={fields?.firstName?.placeholder}
          name={`responsible[${index}].firstName`}
          onChange={formik.handleChange}
          error={
            formik.errors.responsible &&
            formik.errors.responsible[index] &&
            formik.errors.responsible[index].firstName
          }
          touched={
            formik.touched.responsible &&
            formik.touched.responsible[index] &&
            formik.touched.responsible[index].firstName
          }
          value={formik.values.responsible[index] && formik.values.responsible[index].firstName}
          disabled={isDisabledForm}
        />
        <Input
          inputLight={inputLight}
          label={fields?.lastName?.label}
          placeholder={fields?.lastName?.placeholder}
          name={`responsible[${index}].lastName`}
          onChange={formik.handleChange}
          error={
            formik.errors.responsible &&
            formik.errors.responsible[index] &&
            formik.errors.responsible[index].lastName
          }
          touched={
            formik.touched.responsible &&
            formik.touched.responsible[index] &&
            formik.touched.responsible[index].lastName
          }
          value={formik.values.responsible[index] && formik.values.responsible[index].lastName}
          disabled={isDisabledForm}
        />
        <Input
          inputLight={inputLight}
          label={fields?.position?.label}
          placeholder={fields?.position?.placeholder}
          name={`responsible[${index}].position`}
          onChange={formik.handleChange}
          error={
            formik.errors.responsible &&
            formik.errors.responsible[index] &&
            formik.errors.responsible[index].position
          }
          touched={
            formik.touched.responsible &&
            formik.touched.responsible[index] &&
            formik.touched.responsible[index].position
          }
          value={formik.values.responsible[index] && formik.values.responsible[index].position}
          disabled={isDisabledForm}
        />
        <Input
          inputLight={inputLight}
          label={fields?.email?.label}
          placeholder={fields?.email?.placeholder}
          name={`responsible[${index}].email`}
          onChange={formik.handleChange}
          error={
            formik.errors.responsible &&
            formik.errors.responsible[index] &&
            formik.errors.responsible[index].email
          }
          touched={
            formik.touched.responsible &&
            formik.touched.responsible[index] &&
            formik.touched.responsible[index].email
          }
          value={formik.values.responsible[index] && formik.values.responsible[index].email}
          disabled={isDisabledForm}
        />
        <Input
          inputLight={inputLight}
          label={fields?.phone?.label}
          placeholder={fields?.phone?.placeholder}
          name={`responsible[${index}].phone`}
          onChange={formik.handleChange}
          error={
            formik.errors.responsible &&
            formik.errors.responsible[index] &&
            formik.errors.responsible[index].phone
          }
          touched={
            formik.touched.responsible &&
            formik.touched.responsible[index] &&
            formik.touched.responsible[index].phone
          }
          value={formik.values.responsible[index] && formik.values.responsible[index].phone}
          disabled={isDisabledForm}
        />
      </div>
    </section>
  );
}

export default ResponsibleInformationForm;
