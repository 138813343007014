import clsx from 'clsx';
import React from 'react';

const labels = {
  yes: { name: 'Oui', color: 'bg-blue-100' },
  no: { name: 'Non', color: 'bg-green-100' },
  default: { color: 'bg-gray-100' }
};

const Badge = ({ type = '', label = '' }) => {
  return (
    <span
      className={clsx(
        'px-3 py-1 rounded-full',
        type === 'default' ? labels['default']?.color : labels[label]?.color
      )}>
      {type === 'default' ? label : labels[label]?.name}
    </span>
  );
};

export default Badge;
