import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import {
  Header,
  Button,
  EstablishmentForm,
  StudentInformationForm,
  ResponsibleInformartionForm
} from '../components';

import { SchoolRegistrationSchema } from '../utils/schema';
import { RegistrationOfSchool, getWSFRegionalInformation } from '../service';

import { typeQuries, typeEstablishmentDropdown } from '../utils/values';

function DeclarationOfInterest() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [postalcodeOptions, setPostalcodeOptions] = useState([]);
  const [establishmentNameoptions, setEstablishmentNameoptions] = useState([]);
  const [postalcodeSearch, setPostalcodeSearch] = useState('');
  const [manualAddress, setManualAddress] = useState(false);

  const params = new URLSearchParams(location.search);

  const registrationGroup = params.get('registration-group');
  const region = params.get('region');
  const registrationType = params.get('registration-type');
  const registrationPurpose = params.get('registration-purpose');

  const translations = t(`declarationOfInterest.${registrationType}`, { returnObjects: true });
  const toastMessages = t('toastMessages', { returnObjects: true });

  useEffect(() => {
    if (!registrationGroup || !region || !registrationType || !registrationPurpose) {
      toast.error('Une erreur est survenue');
      navigate('/registration');
    }
  }, [registrationGroup, region, registrationType, registrationPurpose, navigate]);

  const handleSubmit = async (values) => {
    try {
      const {
        underContract: underContractTemp,
        rep: repTemp,
        studentInformation: studentInformationTemp
      } = values;

      const rep = repTemp.split('-')[1];
      const underContract = underContractTemp.split('-')[1];

      const studentInformation = studentInformationTemp.map((student) => {
        const { visitDay, studentCount, studentLevel } = student;
        return {
          visitDay: visitDay.split('-')[0],
          studentCount,
          studentLevel: studentLevel.split('-')[0]
        };
      });

      await RegistrationOfSchool({
        ...values,
        rep,
        registrationGroup: registrationGroup.trim(),
        registrationType: registrationType.trim(),
        registrationPurpose: registrationPurpose.trim(),
        region: region === 'null' ? null : region.trim(),

        studentInformation,
        underContract
      });

      toast.success(toastMessages.declationOfInterest);
      formik.resetForm();
      // Manually reset optional fields to initial values
      formik.setFieldValue('responsible[1].firstName', '');
      formik.setFieldValue('responsible[1].lastName', '');
      formik.setFieldValue('responsible[1].email', '');
      formik.setFieldValue('responsible[1].phone', '');
      formik.setFieldValue('responsible[1].position', '');

      setPostalcodeOptions([]);
      setEstablishmentNameoptions([]);
      setPostalcodeSearch('');
    } catch (err) {
      const message = err?.response?.data?.message || toastMessages.error;
      console.log(err);
      toast.error(message);
    }
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      postalcode: '',
      name: '',
      city: '',
      address: '',
      department: '',
      type: '',
      underContract: '',
      rep: '',
      // responsible1
      responsible: [
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          position: ''
        }
        // {
        //   firstName: '',
        //   lastName: '',
        //   email: '',
        //   phone: '',
        //   position: '',
        // },
      ],
      studentInformation: [
        {
          studentLevel: '',
          studentCount: '',
          visitDay: ''
        }
      ]
    },
    validationSchema: SchoolRegistrationSchema
  });

  useEffect(() => {
    if (isEmpty(formik.values.type)) return;

    const where = typeQuries[formik.values.type];

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({ where });

        results.forEach((result) => {
          setPostalcodeOptions((prev) => [
            ...prev,
            {
              value: result.code_postal,
              label: result.code_postal
            }
          ]);
        });
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [formik.values.type]);

  useEffect(() => {
    if (isEmpty(postalcodeSearch) || isEmpty(formik.values.type)) return;

    const where = typeQuries[formik.values.type];

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({
          where: `startswith(code_postal,'${postalcodeSearch}') AND ${where}`
        });
        const currentPostalcodeOptions = [];

        for (const result of results) {
          if (!currentPostalcodeOptions.some((option) => option.value === result.code_postal))
            currentPostalcodeOptions.push({
              value: result.code_postal,
              label: result.code_postal
            });
        }
        currentPostalcodeOptions.sort((a, b) => a.value.localeCompare(b.value));
        setPostalcodeOptions(currentPostalcodeOptions);
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [postalcodeSearch]);

  useEffect(() => {
    if (isEmpty(formik.values.postalcode) || isEmpty(formik.values.type)) return;

    const where = typeQuries[formik.values.type];

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({
          where: `code_postal="${postalcodeSearch}" AND ${where}`
        });
        setEstablishmentNameoptions([]);

        results.forEach((result) => {
          setEstablishmentNameoptions((prev) => [
            ...prev,
            {
              value: result.nom_etablissement,
              label: result.nom_etablissement,
              ...result
            }
          ]);
        });
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [formik.values.postalcode]);

  useEffect(() => {
    if (
      isEmpty(formik.values.postalcode) ||
      isEmpty(formik.values.type) ||
      isEmpty(formik.values.name)
    )
      return;

    const slectedObject = establishmentNameoptions.find(
      (option) => option.value === formik.values.name
    );

    formik.setFieldValue('city', slectedObject?.nom_commune || '');
    formik.setFieldValue('address', slectedObject?.adresse_1 || '');
    console.log('slectedObject?.adresse_1', slectedObject?.adresse_1);
    if (isEmpty(slectedObject?.adresse_1)) setManualAddress(true);
    else setManualAddress(false);

    formik.setFieldValue('department', slectedObject?.code_departement);
  }, [formik.values.name]);

  useEffect(() => {
    formik.validateField('city');
    formik.validateField('address');
    formik.validateField('department');
  }, [formik.values.city, formik.values.address, formik.values.department]);

  const moveToFields = () => {
    // Find the first field with an error and focus on it
    const firstErrorField = Object.keys(formik.errors)[0];

    const errorFieldElement = document.getElementsByName(firstErrorField)[0];

    if (errorFieldElement) errorFieldElement.focus();
  };

  return (
    <main className="min-h-screen bg-blue-999 text-white pb-10">
      <Header />
      <form onSubmit={formik.handleSubmit} className="container mt-5 mx-auto">
        <section className="flex justify-end">
          <Button backgroundColor="bg-red-550" className="px-4 uppercase" internalLink to="/login">
            {translations?.login}
          </Button>
        </section>
        <section id="text-container" className="text-center py-10">
          <h1 className="text-4xl uppercase font-bold">{translations?.title1}</h1>
          <h1 className="text-4xl uppercase outline-text font-bold">{translations?.title2}</h1>
          <p className="text-center mt-5 font-light">{translations?.subheading}</p>
        </section>

        <EstablishmentForm
          formik={formik}
          typeDropdown={typeEstablishmentDropdown[registrationType]}
          postalcodeOptions={postalcodeOptions}
          postalcodeSearch={postalcodeSearch}
          setPostalcodeSearch={setPostalcodeSearch}
          establishmentNameoptions={establishmentNameoptions}
          manualAddress={manualAddress}
        />
        <StudentInformationForm formik={formik} isRegistrationForm />
        <ResponsibleInformartionForm
          formik={formik}
          index={0}
          title={translations?.responsibleInformartionForm1?.title}
          infoHelperText={translations?.responsibleInformartionForm1?.infoHelperText}
        />
        <ResponsibleInformartionForm
          formik={formik}
          index={1}
          title={translations?.responsibleInformartionForm2?.title}
          infoHelperText={translations?.responsibleInformartionForm2?.infoHelperText}
        />
        <section
          className="py-10"
          dangerouslySetInnerHTML={{
            __html: translations?.moveToFields?.content
          }}
        />

        <Button className="text-black px-4 font-bold" type="submit" onClick={moveToFields}>
          {translations?.moveToFields?.submit}
        </Button>
        <section className="text-white mt-8">
          <h2 className="text-sm sm:text-2xl font-bold">{t('registration.support.title')}</h2>
          <p className="font-light mt-1">
            {t('registration.support.subheading')}
            <span className="font-bold">
              <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
            </span>{' '}
          </p>
        </section>
      </form>
    </main>
  );
}

export default DeclarationOfInterest;
