import * as Yup from 'yup';

export const LoginSchema = Yup.object({
  email: Yup.string().email('fieldErrors.email').required('fieldErrors.required')
});

export const SchoolRegistrationSchema = Yup.object().shape({
  postalcode: Yup.string().required('fieldErrors.postalcode'),
  name: Yup.string().required('fieldErrors.name'),
  city: Yup.string().required('fieldErrors.city'),
  address: Yup.string().required('fieldErrors.address'),
  department: Yup.string().required('fieldErrors.department'),
  type: Yup.string().required('fieldErrors.type'),
  underContract: Yup.string().required('fieldErrors.underContract'),
  rep: Yup.string().required('fieldErrors.rep'),
  responsible: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required('fieldErrors.firstName'),
        lastName: Yup.string().required('fieldErrors.lastName'),
        email: Yup.string().email('fieldErrors.validEmail').required('fieldErrors.required'),
        phone: Yup.string().required('fieldErrors.phone'),
        position: Yup.string().required('fieldErrors.position')
      })
    )
    .min(1, 'fieldErrors.minResponsibles')
    .max(2, 'fieldErrors.maxResponsibles')
    .required('fieldErrors.responsibles'),
  studentInformation: Yup.array().of(
    Yup.object().shape({
      studentLevel: Yup.string().required('fieldErrors.studentLevel'),
      studentCount: Yup.string().required('fieldErrors.studentCount'),
      visitDay: Yup.string().required('fieldErrors.visitDay')
    })
  )
});

export const OtherGroupSchoolRegistrationSchema = Yup.object().shape({
  postalcode: Yup.string().required('fieldErrors.postalcode'),
  name: Yup.string().required('fieldErrors.name'),
  city: Yup.string().required('fieldErrors.city'),
  address: Yup.string().required('fieldErrors.address'),
  department: Yup.string().required('fieldErrors.department'),
  type: Yup.string().required('fieldErrors.type'),
  responsible: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required('fieldErrors.firstName'),
        lastName: Yup.string().required('fieldErrors.lastName'),
        email: Yup.string().email('fieldErrors.validEmail').required('fieldErrors.required'),
        phone: Yup.string().required('fieldErrors.phone'),
        position: Yup.string().required('fieldErrors.position')
      })
    )
    .min(1, 'fieldErrors.minResponsibles')
    .max(2, 'fieldErrors.maxResponsibles')
    .required('fieldErrors.responsibles'),
  studentInformation: Yup.array().of(
    Yup.object().shape({
      studentCount: Yup.string().required('fieldErrors.studentCount'),
      visitDay: Yup.string().required('fieldErrors.visitDay')
    })
  )
});

export const classRegistrationTeacherSchema = Yup.object().shape({
  postalcode: Yup.string().required('fieldErrors.postalcode'),
  name: Yup.string().required('fieldErrors.name'),
  city: Yup.string().required('fieldErrors.city'),
  address: Yup.string().required('fieldErrors.address'),
  department: Yup.string().required('fieldErrors.department'),
  type: Yup.string().required('fieldErrors.type'),
  underContract: Yup.string().required('fieldErrors.underContract'),
  rep: Yup.string().required('fieldErrors.rep'),
  responsible: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('fieldErrors.firstName'),
      lastName: Yup.string().required('fieldErrors.lastName'),
      email: Yup.string().email('fieldErrors.validEmail').required('fieldErrors.required'),
      phone: Yup.string().required('fieldErrors.phone'),
      position: Yup.string().required('fieldErrors.position'),
      classes: Yup.array().of(
        Yup.object().shape({
          classLevel: Yup.string().required('fieldErrors.classLevel'),
          classCount: Yup.number()
            .typeError('fieldErrors.classCountType')
            .required('fieldErrors.classCountRequired'),
          supervisorCount: Yup.number()
            .typeError('fieldErrors.supervisorCountType')
            .required('fieldErrors.supervisorCountRequired'),
          visitorsCount: Yup.number()
            .typeError('fieldErrors.visitorsCountType')
            .required('fieldErrors.visitorsCountRequired'),
          classNumber: Yup.string().required('fieldErrors.classNumber'),
          visitDay: Yup.string().required('fieldErrors.visitDay'),
          underContract: Yup.string().required('fieldErrors.underContract'),
          estimatedArrivalTime: Yup.string().required('fieldErrors.estimatedArrivalTime'),
          estimatedTimeOnSite: Yup.string().required('fieldErrors.estimatedTimeOnSite')
        })
      )
    })
  )
});

export const otherGroupClassRegistrationTeacherSchema = Yup.object().shape({
  postalcode: Yup.string().required('fieldErrors.postalcode'),
  name: Yup.string().required('fieldErrors.name'),
  city: Yup.string().required('fieldErrors.city'),
  address: Yup.string().required('fieldErrors.address'),
  department: Yup.string().required('fieldErrors.department'),
  type: Yup.string().required('fieldErrors.type'),
  responsible: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('fieldErrors.firstName'),
      lastName: Yup.string().required('fieldErrors.lastName'),
      email: Yup.string().email('fieldErrors.validEmail').required('fieldErrors.required'),
      phone: Yup.string().required('fieldErrors.phone'),
      position: Yup.string().required('fieldErrors.position'),
      classes: Yup.array().of(
        Yup.object().shape({
          classLevel: Yup.string().required('fieldErrors.classLevel'),
          classCount: Yup.number()
            .typeError('fieldErrors.classCountType')
            .required('fieldErrors.classCountRequired'),
          visitDay: Yup.string().required('fieldErrors.visitDay'),
          underContract: Yup.string().required('fieldErrors.underContract'),
          estimatedArrivalTime: Yup.string().required('fieldErrors.estimatedArrivalTime'),
          estimatedTimeOnSite: Yup.string().required('fieldErrors.estimatedTimeOnSite')
        })
      )
    })
  )
});

export const createNewClassSchema = Yup.object().shape({
  responsible: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('fieldErrors.firstName'),
      lastName: Yup.string().required('fieldErrors.lastName'),
      email: Yup.string().email('fieldErrors.validEmail').required('fieldErrors.required'),
      phone: Yup.string().required('fieldErrors.phone'),
      position: Yup.string().required('fieldErrors.position'),
      classes: Yup.array().of(
        Yup.object().shape({
          classLevel: Yup.string().required('fieldErrors.classLevel'),
          classCount: Yup.number()
            .typeError('fieldErrors.classCountType')
            .required('fieldErrors.classCountRequired'),
          supervisorCount: Yup.number()
            .typeError('fieldErrors.supervisorCountType')
            .required('fieldErrors.supervisorCountRequired'),
          visitorsCount: Yup.number()
            .typeError('fieldErrors.visitorsCountType')
            .required('fieldErrors.visitorsCountRequired'),
          classNumber: Yup.string().required('fieldErrors.classNumber'),
          visitDay: Yup.string().required('fieldErrors.visitDay'),
          underContract: Yup.string().required('fieldErrors.underContract'),
          estimatedArrivalTime: Yup.string().required('fieldErrors.estimatedArrivalTime'),
          estimatedTimeOnSite: Yup.string().required('fieldErrors.estimatedTimeOnSite')
        })
      )
    })
  )
});

export const otherGroupCreateNewClassSchema = Yup.object().shape({
  responsible: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('fieldErrors.firstName'),
      lastName: Yup.string().required('fieldErrors.lastName'),
      email: Yup.string().email('fieldErrors.validEmail').required('fieldErrors.required'),
      phone: Yup.string().required('fieldErrors.phone'),
      position: Yup.string().required('fieldErrors.position'),
      classes: Yup.array().of(
        Yup.object().shape({
          classLevel: Yup.string().required('fieldErrors.classLevel'),
          classCount: Yup.number()
            .typeError('fieldErrors.classCountType')
            .required('fieldErrors.classCountRequired'),
          visitDay: Yup.string().required('fieldErrors.visitDay'),
          underContract: Yup.string().required('fieldErrors.underContract'),
          estimatedArrivalTime: Yup.string().required('fieldErrors.estimatedArrivalTime'),
          estimatedTimeOnSite: Yup.string().required('fieldErrors.estimatedTimeOnSite')
        })
      )
    })
  )
});

export const adminMessageSchama = Yup.object({
  message: Yup.string().required('fieldErrors.required')
});

export const busSignupSchema = Yup.object({
  companyName: Yup.string().required('fieldErrors.companyName'),
  companyAddress: Yup.string().required('fieldErrors.companyAddress'),
  companyPostalcode: Yup.string().required('fieldErrors.companyPostalcode'),
  companyCity: Yup.string().required('fieldErrors.companyCity'),
  officePhone: Yup.string().required('fieldErrors.officePhone'),
  email: Yup.string().email('fieldErrors.validEmail').required('fieldErrors.email'),
  eventWhatsappNumber: Yup.string().required('fieldErrors.eventWhatsappNumber'),
  eventMobileNumber: Yup.string().required('fieldErrors.eventMobileNumber'),
  carrierUIDNumber: Yup.string().required('fieldErrors.carrierUIDNumber'),
  dataStorageConfirmation: Yup.boolean()
    .oneOf([true], 'fieldErrors.dataStorageConfirmation')
    .required('fieldErrors.dataStorageConfirmation'),
  autherizationConfirmation: Yup.boolean()
    .oneOf([true], 'fieldErrors.autherizationConfirmation')
    .required('fieldErrors.autherizationConfirmation')
});

export const busLoginSchema = Yup.object({
  email: Yup.string().email('fieldErrors.validEmail').required('fieldErrors.email')
});
