const OrganizationStatsByVisitDay = ({ translations, organizationStats }) => {
  if (!organizationStats?.formStats) return null;
  const allStats = JSON.parse(JSON.stringify(organizationStats?.formStats));

  const totalStats = allStats
    ?.filter((allStatsItem) => allStatsItem.includeInTotal)
    ?.map((allStatsItem) => {
      return allStatsItem.visitDays;
    })
    ?.reduce((acc, visitDays) => {
      Object.keys(visitDays).forEach((date) => {
        if (!acc[date]) {
          acc[date] = 0;
        }
        acc[date] += visitDays[date];
      });
      return acc;
    }, {});

  if (totalStats) {
    const totalItem = {
      name: 'all',
      visitDays: totalStats,
      visitDaysHeading:
        "Total Inscriptions sans classes + inscriptions avec classes + inscriptions d'unités individuelles"
    };

    allStats?.push(totalItem);
  }

  return (
    <>
      {allStats?.map((columnItem) => {
        // Ensure visitDays is initialized properly.
        const visitDays = columnItem.visitDays || {};
        const visitDates = ['11.09', '12.09', '13.09', '14.09'];
        const totalVisits = calculateTotalVisits(visitDays);

        return (
          <section className="mt-10 mb-6" key={columnItem.name}>
            <p className="font-bold mb-4 text-lg">{columnItem.visitDaysHeading}</p>
            <div className="px-4 py-12 flex flex-row gap-4 overflow-x-auto border border-black rounded-lg">
              {visitDates.map((visitDate) => (
                <VisitDay
                  key={visitDate}
                  visitDate={visitDate}
                  visitCount={visitDays[visitDate] || '0'}
                  translations={translations}
                />
              ))}
              <VisitDay
                key="Total"
                visitDate="Total"
                visitCount={totalVisits.toString()}
                translations={translations}
              />
            </div>
          </section>
        );
      })}
    </>
  );
};

const VisitDay = ({ visitDate, visitCount, translations }) => (
  <div className="p-3 w-[300px] flex-shrink-0 bg-white rounded transition-all">
    <div className="flex justify-between flex-col relative">
      <h3 className="text-xl font-bold text-gray-900 max-w-full">{visitDate}</h3>
      <div className="grid grid-cols-2 gap-6">
        <p>{translations.totalStudents}</p>
        <p>{visitCount}</p>
      </div>
    </div>
  </div>
);

const calculateTotalVisits = (visitDays) => {
  return Object.values(visitDays).reduce((total, current) => total + Number(current), 0);
};

export default OrganizationStatsByVisitDay;
