import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { PiWarningFill } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Header, Button, ResponsibleInformartionForm, ClassInformationForm } from '../components';

import { createNewClassSchema, otherGroupCreateNewClassSchema } from '../utils/schema';
import { addClassToOrganizationViaToken, getClassDetailsViaToken } from '../service';
import { otherGroupForms } from '../utils/values';

function AddClassToOrganization() {
  const { token } = useParams();

  const { t } = useTranslation();

  const [organizationData, setOrganizationData] = useState({});

  let translations = t('addClassToOrganization', { returnObjects: true });
  const toastMessages = t('toastMessages', { returnObjects: true });

  const isOtherGroupForm = otherGroupForms.includes(organizationData?.registrationType);

  translations = isOtherGroupForm ? translations?.['other-group'] : translations.default;

  useEffect(() => {
    const getPageData = async () => {
      try {
        const response = await getClassDetailsViaToken(token);
        setOrganizationData(response);
      } catch (error) {
        console.log(error);
      }
    };

    getPageData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const restructuredData = {
        token,
        responsible: values.responsible.map((responsible) => ({
          ...responsible,
          classes: responsible.classes.map((classItem) => ({
            ...classItem,
            visitDay: classItem.visitDay.split('-')[0],
            underContract: classItem.underContract.split('-')[1]
          }))
        }))
      };
      await addClassToOrganizationViaToken(restructuredData);

      toast.success(toastMessages.classGroupCreatedSuccess);
      formik.resetForm();
    } catch (error) {
      const errorMessage = error.response.data.message || toastMessages.error;
      toast.error(errorMessage);
      console.log(error);
    }
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      responsible: [
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          function: '',
          classes: [
            {
              classLevel: '',
              classCount: '',
              supervisorCount: '',
              visitorsCount: '',
              classNumber: '',
              visitDay: '',
              underContract: '',
              estimatedArrivalTime: '',
              estimatedTimeOnSite: ''
            }
          ]
        }
      ]
    },
    validationSchema: isOtherGroupForm ? otherGroupCreateNewClassSchema : createNewClassSchema
  });

  const addMoreResponsible = () => {
    formik.setFieldValue('responsible', [
      ...formik.values.responsible,
      {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        function: '',
        classes: [
          {
            classLevel: '',
            classCount: '',
            supervisorCount: '',
            visitorsCount: '',
            classNumber: '',
            visitDay: '',
            underContract: '',
            estimatedArrivalTime: '',
            estimatedTimeOnSite: ''
          }
        ]
      }
    ]);
  };

  const addMoreClassToLastResponsible = () => {
    const lastResponsible = formik.values.responsible.length - 1;
    formik.setFieldValue(`responsible[${lastResponsible}].classes`, [
      ...formik.values.responsible[lastResponsible].classes,
      {
        classLevel: '',
        classCount: '',
        supervisorCount: '',
        visitorsCount: '',
        classNumber: '',
        visitDay: '',
        underContract: '',
        estimatedArrivalTime: '',
        estimatedTimeOnSite: ''
      }
    ]);
  };

  return (
    <main className="min-h-screen bg-blue-999 text-white pb-10">
      <Header />
      <form onSubmit={formik.handleSubmit} className="container mt-5 mx-auto">
        <section id="text-container" className="text-center py-10">
          <h1 className="text-4xl font-bold uppercase">{translations?.title1}</h1>
          <h1 className="text-4xl uppercase outline-text font-bold">
            {translations?.title2} “{organizationData?.name}”
          </h1>
          <p className="text-center mt-5 font-light">{translations?.sub1}</p>
          <p className="text-center mt-5 font-light uppercase">{translations?.sub2}</p>
          <p className="text-center font-light">{translations?.sub3}</p>
        </section>

        {formik.values.responsible.map((_, index) => (
          <>
            <ResponsibleInformartionForm
              formik={formik}
              index={index}
              title={translations?.responsibleInformartionForm?.title}
              infoHelperText={translations?.responsibleInformartionForm?.infoHelperText}
            />

            <ClassInformationForm
              title={translations?.classInformationForm?.title}
              responsibleIndex={index}
              formik={formik}
              classCheckboxesLabel={translations?.classInformationForm?.classCheckboxesLabel}
              otherGroupForm={isOtherGroupForm}
            />
          </>
        ))}

        <section className="py-10">
          <p className="font-thin flex justify-center">
            <PiWarningFill className="text-yellow-500 mt-1 mr-1" />
            {translations?.warning}
          </p>
          {!isOtherGroupForm && (
            <Button
              className="text-black flex items-center justify-center my-6 uppercase"
              type="button"
              onClick={addMoreClassToLastResponsible}>
              <FaPlus />
              <p className="ml-1"> {translations?.addClass}</p>
            </Button>
          )}
        </section>
        <p>{translations?.bottomInfo}</p>
        <Button
          className="text-black flex items-center justify-center my-6 uppercase"
          type="submit">
          <p className="ml-1">{translations?.bottomButton}</p>
        </Button>
      </form>
    </main>
  );
}

export default AddClassToOrganization;
