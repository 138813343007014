import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Header, Button, Dropdown } from '../components';

import { getAllRegions } from '../service';

function RegionSelection() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const translations = t('regionSelection', { returnObjects: true });

  const [regions, setRegions] = useState([]);

  const query = new URLSearchParams(location.search);
  const group = query.get('registration-group').trim();

  useEffect(() => {
    if (group !== 'france') navigate('/registration');
    getRegions();
  }, [group, navigate]);

  const getRegions = async () => {
    const data = await getAllRegions();

    const filteredRegions = data.map((region) => ({
      label: region.name_fr,
      value: region._id
    }));

    setRegions(filteredRegions);
  };

  const handleRegionSelect = ({ target: { value } }) => {
    navigate(`/establishment-training-type?registration-group=${group}&region=${value}`);
  };

  return (
    <main className="min-h-screen">
      <Header />
      <section
        id="registration"
        className="bg-cover w-full min-h-screen justify-center items-center flex"
        style={{ backgroundImage: "url('/region-selection-background.png')" }}>
        <div className="max-w-5xl w-full px-5">
          <div className="flex justify-end pb-10 absolute top-40 right-10">
            <Button
              className="uppercase px-4 font-bold self-end text-white"
              backgroundColor="bg-red-550"
              internalLink
              to="/login">
              {translations?.login}
            </Button>
          </div>
          <div className="text-white">
            <h1 className="text-5xl uppercase font-bold">
              {translations?.title?.part1}{' '}
              <span className="outline-text">{translations?.title?.part2}</span>
            </h1>
            <p className="font-light mt-4">{translations?.subheading}</p>
          </div>
          <div className="sm:w-1/2 mt-10 text-white">
            <Dropdown
              label={translations?.dropdown?.label}
              placeholder={translations?.dropdown?.placeholder}
              onChange={handleRegionSelect}
              options={regions}
            />
          </div>
          <div className="text-white mt-8">
            <h2 className="text-sm sm:text-2xl font-bold">{t('registration.support.title')}</h2>
            <p className="font-light mt-1">
              {t('registration.support.subheading')}
              <span className="font-bold">
                <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
              </span>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default RegionSelection;
