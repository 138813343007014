import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoTrashOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { CrossButtonIcon } from '../../assets';
import { deleteOrganization } from '../../service';
import { Loading, Popover } from '../common';

const PopoverHeader = ({ closePopup }) => (
  <header className="rounded-t-lg flex items-center justify-between">
    <CrossButtonIcon className="text-[#515151] hover:text-black" onClick={closePopup} />
  </header>
);

const PopoverMain = ({ organizationId, onDelete, closePopup }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleOrganizationDeleteClick = async () => {
    if (!organizationId || isLoading) return;

    setIsLoading(true);
    try {
      await deleteOrganization(organizationId);
      toast.success(t('deleteOrganizationConfirmationPopover.feedback.success'));
      onDelete();
      closePopup();
    } catch (error) {
      toast.error(t('deleteOrganizationConfirmationPopover.feedback.error'));
      console.error('Failed to delete:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="flex flex-col items-center py-6">
      <div
        className="text-2xl font-bold mb-4"
        dangerouslySetInnerHTML={{
          __html: t('deleteOrganizationConfirmationPopover.title')
        }}
      />
      <div
        className="mb-8"
        dangerouslySetInnerHTML={{
          __html: t('deleteOrganizationConfirmationPopover.description')
        }}
      />
      <button
        className={clsx(
          'border px-6 py-2 rounded-full transition-all flex items-center',
          isLoading ? 'cursor-not-allowed hover:bg-gray-100' : 'hover:bg-red-500 hover:text-white'
        )}
        onClick={handleOrganizationDeleteClick}
        disabled={isLoading}>
        <Loading loading={isLoading} /> {t('deleteOrganizationConfirmationPopover.button')}
      </button>
    </main>
  );
};

function DeleteOrganizationConfirmationPopover({ organizationId, onDelete = () => {} }) {
  return (
    <Popover
      position="bottom right"
      size="sm"
      button={
        <button
          data-testid="popover-button"
          className="w-full min-h-full flex justify-center group py-4 px-3">
          <IoTrashOutline className="size-5 group-hover:text-red-500 transition-all" />
        </button>
      }>
      <PopoverHeader />
      <PopoverMain organizationId={organizationId} onDelete={onDelete} />
    </Popover>
  );
}

export default DeleteOrganizationConfirmationPopover;
