import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { RxCopy } from 'react-icons/rx';

import { Button, Header } from '../../components';

import { ProfileContext } from '../../context/profile';

import { getResponsibles } from '../../service';
import { otherGroupForms } from '../../utils/values';

function EditSchoolAccount() {
  const { t } = useTranslation();

  let translations = t('editSchoolAccount', { returnObjects: true });

  const [profile] = useContext(ProfileContext);
  const [linkCopied, setLinkedCopied] = useState(false);
  const [responsibles, setResponsibles] = useState([]);

  const isOtherGroupForm = otherGroupForms.includes(profile.organization?.registrationType);
  translations = isOtherGroupForm ? translations?.['other-group'] : translations?.default;

  const copyText = () => {
    setLinkedCopied(true);
    const text = `${window.location.protocol}//${window.location.host}/organization-class/${profile.organization?.formAccessToken}`;
    navigator.clipboard.writeText(text);

    setTimeout(() => {
      setLinkedCopied(false);
    }, 1000);
  };

  async function fetchData() {
    try {
      const responsibleData = await getResponsibles();
      setResponsibles(responsibleData.responsibles);
    } catch (error) {
      console.error('Error fetching responsible data:', error);
      throw error;
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <main className="min-h-screen bg-blue-999 text-white pb-10">
      <Header isAuthenticated title={translations?.headerTitle} backgroundColor="bg-blue-999" />
      <section className="container py-10 mx-auto">
        <h3
          className="my-2 uppercase text-3xl"
          dangerouslySetInnerHTML={{ __html: translations?.title }}
        />
        <p dangerouslySetInnerHTML={{ __html: translations?.subheading }} />
      </section>
      <section className="container py-4 mx-auto block md:flex items-center">
        <Button
          internalLink
          to="/school/provisional-establishment-account"
          className="text-black flex items-center justify-center my-6 uppercase"
          type="button">
          <p className="mr-1 whitespace-nowrap">{translations?.link1}</p>
          <FaAngleRight />
        </Button>
        <div className="ml-0 mt-4 md:mt-0 md:ml-4">
          <p className="mr-4">{translations?.sub2}</p>
        </div>
      </section>
      <section className="container mx-auto block md:flex items-center">
        <Button
          internalLink
          to="/school/classes"
          className="text-black flex items-center justify-center uppercase"
          type="button">
          <p className="mr-1 whitespace-nowrap">{translations?.link2}</p>
          <FaAngleRight />
        </Button>
        <div className="ml-0 mt-4 md:mt-0 md:ml-4">
          <p className="mr-4">{translations?.sub3}</p>
        </div>
      </section>

      <section className="container py-10 mx-auto">
        <div dangerouslySetInnerHTML={{ __html: translations?.sub4 }} />
        <Link
          to="/school/class-registration"
          target="_blank"
          className="border-red-550 border-2 flex items-center max-w-fit px-10 py-4 mt-8">
          <p className="ml-1 uppercase">{translations?.link3?.part1}</p>
          <span className="tilted-border-left ml-10">{translations?.link3.part2}</span>
        </Link>
        {responsibles[0]?._id && (
          <Link
            to={`/school/class-registration?id=${responsibles[0]?._id}`}
            target="_blank"
            className="border-red-550 border-2 flex items-center max-w-fit px-10 py-4 mt-8">
            <p className="ml-1 uppercase">{translations?.link4?.part1}</p>
            <span className="tilted-border-left ml-10">{translations?.link4?.part2}</span>
          </Link>
        )}
        {responsibles[1]?._id && (
          <Link
            to={`/school/class-registration?id=${responsibles[1]?._id}`}
            target="_blank"
            className="border-red-550 border-2 flex items-center max-w-fit px-10 py-4 mt-8">
            <p className="ml-1 uppercase">{translations?.link5?.part1}</p>
            <span className="tilted-border-left ml-10">{translations?.link5?.part2}</span>
          </Link>
        )}
      </section>
      <section className="container py-10 mx-auto">
        <div dangerouslySetInnerHTML={{ __html: translations?.sub5 }} />
        <div className="flex flex-row items-center my-3">
          <div className="bg-white px-4 py-3 rounded-md mr-2 text-gray-400 h-full truncate max-w-64">
            {`${window.location.protocol}//${window.location.host}/organization-class/${profile.organization?.formAccessToken}`}
          </div>
          <Button
            className={`text-black flex items-center justify-center px-4 ${
              linkCopied ? 'bg-red-900 animate-pulse' : 'bg-red-550'
            }`}
            backgroundColor="bg-red-550"
            type="button"
            onClick={copyText}>
            <RxCopy className="text-white" />
          </Button>
        </div>
      </section>
      <section className="container py-10 mx-auto text-white mt-4">
        <h2 className="text-sm sm:text-2xl font-bold">{t('registration.support.title')}</h2>
        <p className="font-light mt-1">
          {t('registration.support.subheading')}
          <span className="font-bold">
            <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
          </span>{' '}
        </p>
      </section>
    </main>
  );
}

export default EditSchoolAccount;
