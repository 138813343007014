import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { BugReport, FullScreenLoader } from './components';

import {
  AddClassToOrganization,
  AdminEditClass,
  AdminStats,
  ClassRegistrationTeacher,
  CreateNewClass,
  DeclarationOfInterest,
  EditClass,
  EditClassProfile,
  EditOrganizationAccountForAdmin,
  EditProvisionalEstablishmentAccount,
  EditSchoolAccount,
  EstablishmentTrainingType,
  Example,
  Login,
  Magic,
  OrganizationClass,
  OrganizationClasses,
  OrganizationDetail,
  OrientationGroups,
  RegionSelection,
  Registration,
  SchoolGruopSelection,
  SchoolRegistration,
  SoftSkillsGroups,
  TemporaryEstablishmentAccount,
  RegistrationForeign,
  BusSignup,
  BusLogin,
  RegisterSlots
} from './pages';

import { AdminContext, AdminContextProvider } from './context/admin';
import { ProfileContext } from './context/profile';
import { busAxios, fetchTranslations, getAdminProfile, getTeacher } from './service';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  const [profile, setProfileData] = useContext(ProfileContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  const fetchUserDetails = async () => {
    try {
      const dbProfile = await getTeacher();
      setProfileData({
        ...profile,
        loading: false,
        isAuth: true,
        ...dbProfile
      });
    } catch (error) {
      setProfileData({ ...profile, loading: false, isAuth: false });
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes('magic')) return;
    fetchUserDetails();
  }, []);

  const loadTranslations = async () => {
    const lang = localStorage.getItem('lang');

    if (!lang) {
      localStorage.setItem('lang', 'fr');
    }

    i18next.init({
      interpolation: { escapeValue: false },
      saveMissing: false,
      returnEmptyString: false
    });
    i18next.languages = ['en', 'fr', 'de', 'it'];
    i18next.use(initReactI18next).init({
      fallbackLng: 'fr',
      lng: lang,
      ns: ['translations'],
      defaultNS: 'translations'
    });

    const { data: { en, de, fr, it } = {} } = await fetchTranslations();

    i18next.addResourceBundle('en', 'translations', en);
    i18next.addResourceBundle('fr', 'translations', fr);
    i18next.addResourceBundle('de', 'translations', de);
    i18next.addResourceBundle('it', 'translations', it);

    if (window.location.pathname.includes('/admin')) {
      i18next.changeLanguage('fr');
    } else i18next.changeLanguage(lang); // Initial language

    setTranslationsLoaded(true);
  };

  useEffect(() => {
    if (translationsLoaded) return;

    loadTranslations();
  }, [translationsLoaded]);

  if (!translationsLoaded) return <FullScreenLoader />;

  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/admin-login" element={<Login />} />
        <Route path="/magic" element={<Magic fetchUserDetails={fetchUserDetails} />} />
        <Route path="/admin-magic" element={<Magic />} />
        <Route path="/driver-magic" element={<Magic />} />

        <Route path="/example" element={<Example />} />

        <Route path="/registration" element={<Registration />} />
        <Route path="/registration-foreign" element={<RegistrationForeign />} />
        <Route path="/region-selection" element={<RegionSelection />} />

        <Route path="/establishment-training-type" element={<EstablishmentTrainingType />} />
        <Route path="/school-group-selection" element={<SchoolGruopSelection />} />
        <Route path="/declaration-of-interest" element={<DeclarationOfInterest />} />
        <Route path="/school-registration" element={<SchoolRegistration />} />
        <Route path="/class-registration-teacher" element={<ClassRegistrationTeacher />} />
        <Route path="/organization-class/:token" element={<AddClassToOrganization />} />

        <Route
          path="/temporary-establishment-account"
          element={
            <Protected loading={profile.loading} isAuth={profile.isAuth}>
              {!profile.loading &&
              profile.organization?.registrationPurpose === 'interest-declaration' &&
              !profile.organization?.isApproved ? (
                <TemporaryEstablishmentAccount fetchUserDetails={fetchUserDetails} />
              ) : (
                <Navigate to="/school" />
              )}
            </Protected>
          }
        />

        <Route path="/school" element={<SchoolWrapper />}>
          <Route
            path=""
            element={
              <Protected loading={profile.loading} isAuth={profile.isAuth}>
                <EditSchoolAccount />
              </Protected>
            }
          />

          <Route
            path="classes"
            element={
              <Protected loading={profile.loading} isAuth={profile.isAuth}>
                <OrganizationClasses />
              </Protected>
            }
          />

          <Route
            path="provisional-establishment-account"
            element={
              <Protected loading={profile.loading} isAuth={profile.isAuth}>
                <EditProvisionalEstablishmentAccount />
              </Protected>
            }
          />

          <Route
            path="class-registration"
            element={
              <Protected loading={profile.loading} isAuth={profile.isAuth}>
                <CreateNewClass />
              </Protected>
            }
          />
          <Route
            path="edit-class/:id"
            element={
              <Protected loading={profile.loading} isAuth={profile.isAuth}>
                <EditClass />
              </Protected>
            }
          />
          <Route
            path="edit-class-profile"
            element={
              <Protected loading={profile.loading} isAuth={profile.isAuth}>
                <EditClassProfile />
              </Protected>
            }
          />
        </Route>

        <Route element={<AdminRoutesWrapper />}>
          <Route path="/admin" element={<AdminWrapper />}>
            <Route path="organization" element={<OrganizationClass />} />
            <Route path="" element={<AdminStats />} />
            <Route path="organization/:id" element={<OrganizationDetail />} />
            <Route path="organization-edit/:id" element={<EditOrganizationAccountForAdmin />} />
            <Route path="edit-class/:id" element={<AdminEditClass />} />
          </Route>
        </Route>

        <Route path="/bus">
          <Route path="signup" element={<BusSignup />} />
          <Route path="slots" element={<BusBookingWrapper />}>
            <Route path="" element={<RegisterSlots />} />
          </Route>
          <Route path="" element={<Login />} />
          <Route path="*" element={<Navigate to="/bus" />} />
        </Route>

        <Route path="/preparation">
          <Route path="orientation-groups" element={<OrientationGroups />} />
          <Route path="soft-skills-groups" element={<SoftSkillsGroups />} />
        </Route>

        <Route path="*" element={<Navigate to="/registration" />} />
      </Routes>

      <BugReport />
    </Router>
  );
}

export default App;

function Protected({ children, loading, isAuth = true }) {
  let token = localStorage.getItem('token');

  if (!token) return <Navigate to="/" replace />;

  if (loading) return <div />;

  if (!isAuth) window.location.href = '/';

  return children;
}

const SchoolWrapper = () => {
  const [profile] = useContext(ProfileContext);

  if (profile.loading) return <div />;

  if (
    profile.organization?.registrationPurpose === 'interest-declaration' &&
    !profile.organization?.isApproved
  )
    window.location.href = '/temporary-establishment-account';

  if (
    profile.organization?.registrationPurpose.toLowerCase() === 'class-registration' &&
    window.location.pathname !== '/school/edit-class-profile'
  )
    window.location.pathname = '/school/edit-class-profile';

  return <Outlet />;
};

const AdminRoutesWrapper = () => {
  return (
    <AdminContextProvider>
      <Outlet />
    </AdminContextProvider>
  );
};

const AdminWrapper = () => {
  const [loading, setLoading] = useState(false);
  const [adminProfile, setAdminProfile] = useContext(AdminContext);
  const adminToken = localStorage.getItem('adminToken');

  useEffect(() => {
    // get Admin Profile
    const checkAuth = async () => {
      try {
        const data = await getAdminProfile();
        setAdminProfile(data);
      } catch (error) {
        window.location.href = '/admin-login';
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (!adminToken) return <Navigate to="/admin-login" replace />;

  if (loading) return <div />;

  return <Outlet />;
};

const BusBookingWrapper = () => {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    try {
      const token = localStorage.getItem('busToken');
      if (token) busAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } catch (error) {
      console.log('LS unavailable');
    }

    const existingToken = busAxios.defaults.headers.common['Authorization'];
    if (!existingToken || existingToken === 'Bearer null') setAuth(false);
    else setAuth(true);

    setLoading(false);
  }, []);

  if (loading) return <FullScreenLoader />;

  if (!auth) return <Navigate to="/bus" />;

  return <Outlet />;
};
