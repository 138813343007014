import { useTranslation } from 'react-i18next';
import { Input, Dropdown, Button, Checkbox, Radio } from '../components';

function Example() {
  const { t } = useTranslation();

  return (
    <section>
      <Input placeholder={t('example.input.placeholder')} label={t('example.input.label')} />
      <Dropdown
        options={[
          { label: 'Wasif', value: 1 },
          { label: 'jameel', value: 2 }
        ]}
        label={t('example.dropdown.label')}
      />
      <div className="bg-blue-999 p-4 grid grid-cols-3 gap-6">
        <Button>{t('example.button1')}</Button>
        <Button clipedButton backgroundColor="bg-transparent">
          <span className="text-white">{t('example.button2')}</span>
        </Button>
        <div className="my-2" />
        <div className="flex">
          <Checkbox label={t('example.checkbox.yes')} darkLabel onChange={console.log} />
          <Checkbox label={t('example.checkbox.no')} darkLabel isChecked onChange={console.log} />
        </div>
        <div className="my-10 flex">
          <Checkbox label={t('example.checkbox.yes')} isInline onChange={console.log} />
          <Checkbox label={t('example.checkbox.no')} isInline isChecked onChange={console.log} />
        </div>
        <div className="my-10 flex flex-col">
          <p className="text-white">{t('example.radio')}</p>
          <div className="flex">
            <Radio
              label={t('example.checkbox.yes')}
              name="option"
              id="yes"
              isInline
              onChange={console.log}
            />
            <Radio
              label={t('example.checkbox.no')}
              name="option"
              id="no"
              isInline
              onChange={console.log}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Example;
