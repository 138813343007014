import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleLeft } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { ProfileContext } from '../../context/profile';

import { Button, ColumnVisibilityModel, FilterModel, Header } from '../../components';
import { getAllClasses } from '../../service';
import { exportData } from '../../utils/export';

import { ExportIcon, VisibilityButtonIcon } from '../../assets';
import { otherGroupForms } from '../../utils/values';

const initialTableHeaders = {
  fr: [
    { key: 'teacherName', name: 'Nom responsable' },
    { key: 'position', name: 'Fonction' },
    { key: 'email', name: 'Email' },
    { key: 'phone', name: 'Téléphone' },
    { key: 'classLevel', name: 'Niveau de la classe' },
    { key: 'classCount', name: 'Nombre d’élèves dans la classe' },
    { key: 'supervisorCount', name: 'Nombre de personnes encadrant la classe' },
    { key: 'visitorsCount', name: 'Nombre de visiteurs total' },
    { key: 'classNumber', name: 'Numérotez votre classe' },
    { key: 'visitDay', name: 'Jour de la visite envisagé' },
    { key: 'underContract', name: 'Déjeuner sur place' },
    { key: 'estimatedArrivalTime', name: 'Heure d’arrivée prévue au site' },
    { key: 'estimatedTimeOnSite', name: 'Temps prévu sur site' }
  ],
  en: [
    { key: 'teacherName', name: 'Responsible Name' },
    { key: 'position', name: 'Position' },
    { key: 'email', name: 'Email' },
    { key: 'phone', name: 'Phone' },
    { key: 'classLevel', name: 'Class Level' },
    { key: 'classCount', name: 'Number of Students in Class' },
    { key: 'supervisorCount', name: 'Number of People Supervising the Class' },
    { key: 'visitorsCount', name: 'Total Number of Visitors' },
    { key: 'classNumber', name: 'Number Your Class' },
    { key: 'visitDay', name: 'Day of the Envisaged Visit' },
    { key: 'underContract', name: 'Lunch on Site' },
    { key: 'estimatedArrivalTime', name: 'Estimated Arrival Time on Site' },
    { key: 'estimatedTimeOnSite', name: 'Estimated Time on Site' }
  ]
};

function OrganizationClasses() {
  const { t, i18n } = useTranslation();

  let translations = t('organizationClasses', { returnObjects: true });

  const [classes, setClasses] = useState([]);
  const [columnsModel, setColumnsModel] = useState(false);
  const [profile] = useContext(ProfileContext);

  const isOtherGroupForm = otherGroupForms.includes(profile.organization?.registrationType);
  translations = isOtherGroupForm ? translations?.['other-group'] : translations?.default;

  const [tableHeaders, setTableHeaders] = useState([
    ...initialTableHeaders[i18n.language].map((tableHeader) => ({
      ...tableHeader,
      isSelected: true
    }))
  ]);
  const [filterModel, setFilterModel] = useState(false);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [filteredHeaders, setFilteredHeaders] = useState([...tableHeaders]);

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    try {
      const classes = await getAllClasses();
      setClasses(classes);
      setFilteredClasses(classes);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterUpdate = (formValues) => {
    // Convert formValues into an array of filter criteria
    const criteria = Object.keys(formValues).reduce((acc, key) => {
      if (key.startsWith('dropdown_')) {
        const index = key.split('_')[1];
        const valueKey = `searchField_${index}`;
        // Ensure we have a pair before adding
        if (formValues[valueKey] !== undefined) {
          acc.push({ key: formValues[key], value: formValues[valueKey] });
        }
      }
      return acc;
    }, []);

    // Filter based on criteria
    const filteredClasses = classes.filter((classItem) =>
      criteria.every(({ key, value }) => {
        if (['position', 'email', 'phone'].includes(key)) {
          return classItem.teacher[key] === value;
        } else if (key === 'teacherName') {
          return `${classItem.teacher.firstName} ${classItem.teacher.lastName}` === value;
        } else return classItem[key] === value;
      })
    );

    setFilteredClasses(filteredClasses);

    setFilterModel(false);
  };

  const getClassesForExportAndFilters = () => {
    const formattedClasses = JSON.parse(JSON.stringify(classes));
    return formattedClasses.map((formattedClass) => {
      const { firstName, lastName, position, email, phone } = formattedClass.teacher;
      return {
        ...formattedClass,
        teacherName: `${firstName} ${lastName}`,
        position,
        email,
        phone
      };
    });
  };

  const handleDataExport = () => {
    exportData(tableHeaders, getClassesForExportAndFilters());
  };

  const handleColumnVisibilityUpdate = (updatedColumns) => {
    setTableHeaders(updatedColumns);
    setFilteredHeaders(updatedColumns.filter((updatedColumn) => updatedColumn.isSelected));
    setColumnsModel(false);
  };

  return (
    <main className="min-h-screen bg-blue-999 text-white pb-10">
      <Header isAuthenticated title={translations?.headerTitle} backgroundColor="bg-blue-999" />
      <span className="text-black">
        <FilterModel
          tableHeaders={tableHeaders}
          data={getClassesForExportAndFilters()}
          isOpen={filterModel}
          setOpen={setFilterModel}
          onFilterUpdate={handleFilterUpdate}
        />
        <ColumnVisibilityModel
          tableHeaders={tableHeaders}
          isOpen={columnsModel}
          setOpen={setColumnsModel}
          onUpdate={handleColumnVisibilityUpdate}
        />
      </span>
      <section className="container py-10 mx-auto">
        <div>
          <Button
            className="text-black flex items-center justify-center px-4"
            type="button"
            internalLink
            to="/school">
            <FaAngleLeft className="text-xl" />
          </Button>
        </div>
        <div className="flex items-center gap-5 bg-opacity-10 bg-gray-100 rounded p-4 font-light my-5">
          <FiInfo className="text-white text-5xl" />
          {translations?.info}
        </div>
        <div className="flex items-center justify-between my-5">
          <div className="grid grid-cols-2 gap-3">
            <Button
              className="text-black flex items-center justify-center px-4 gap-2"
              type="button"
              backgroundColor="bg-white"
              onClick={() => setColumnsModel(true)}>
              <VisibilityButtonIcon /> {translations?.columns}
            </Button>
            <Button
              className="text-black flex items-center justify-center px-4"
              type="button"
              onClick={() => setFilterModel(true)}>
              {translations?.filter}
            </Button>
          </div>
          <Button
            className="text-black flex items-center justify-center px-4"
            type="button"
            onClick={handleDataExport}>
            <ExportIcon className="size-6 mr-1" />
            {translations?.export}
          </Button>
        </div>
        <section className="overflow-x-auto mt-10">
          <table className="min-w-full">
            <thead>
              <tr className="bg-white">
                {filteredHeaders?.map((tableHeader, tableHeaderIndex) => (
                  <th
                    key={tableHeader.key}
                    scope="col"
                    className={clsx(
                      'px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 border-2 whitespace-nowrap',
                      {
                        'pl-4 pr-3 sm:pl-0': tableHeaderIndex === 0,
                        'px-3 border-2 ': tableHeaderIndex !== 0
                      }
                    )}>
                    {tableHeader.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white">
              {filteredClasses.map((classObj) => (
                <tr key={classObj._id}>
                  {filteredHeaders.map(({ key }) => (
                    <td
                      className={clsx(
                        'whitespace-nowrap px-3 py-4 text-sm border-2 bg-[#6c5c85] text-white',
                        {
                          'bg-white !text-black': key === 'teacherName'
                        }
                      )}>
                      {key === 'teacherName' ? (
                        <Link to={`/school/edit-class/${classObj?._id}`}>
                          {classObj.teacher?.firstName} {classObj.teacher?.lastName}
                        </Link>
                      ) : ['position', 'email', 'phone'].includes(key) ? (
                        classObj.teacher?.[key]
                      ) : (
                        classObj?.[key]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </section>
    </main>
  );
}

export default OrganizationClasses;
