import { useMemo } from 'react';

export function extractTimes(organizationStats) {
  const estimatedArrivalTimes = [];
  const departureTimes = [];

  if (
    !organizationStats?.studentCountByVisitDay ||
    organizationStats.studentCountByVisitDay.length === 0
  ) {
    return [];
  }

  organizationStats.studentCountByVisitDay.forEach((visitDay) => {
    visitDay.estimatedArrivalTimes.forEach((arrivalTime) => {
      estimatedArrivalTimes.push(arrivalTime.estimatedArrivalTime);
    });
  });

  organizationStats.departureTimeByVisitDay.forEach((visitDay) => {
    visitDay.departureTimes.forEach((departureTime) => {
      departureTimes.push(departureTime.departureTime);
    });
  });

  return [...new Set([...estimatedArrivalTimes, ...departureTimes])].sort();
}

// Main component to display the section if there are no assigned regions
const OrganizationStatsSlots = ({ organizationStats }) => {
  const timeSlots = useMemo(() => extractTimes(organizationStats), [organizationStats]);

  if (
    !organizationStats?.studentCountByVisitDay ||
    organizationStats.studentCountByVisitDay.length === 0
  ) {
    return null;
  }

  const sortedStudentCounts = organizationStats.studentCountByVisitDay.sort((a, b) =>
    a._id.localeCompare(b._id)
  );

  return (
    <section className="mt-10 mb-6">
      <p className="font-bold mb-4 text-lg">
        Inscriptions avec classes + inscriptions d’unités individuelles
      </p>
      <div className="px-4 py-12 flex gap-2 border border-black rounded-lg overflow-x-auto">
        {sortedStudentCounts.map((countItem) => (
          <section className="p-3 w-[300px] flex-shrink-0" key={countItem._id}>
            <p className="font-bold text-lg">{countItem._id}</p>
            <p className="mb-4">Total personnes : {countItem.studentCount}</p>
            <ul className="mt-2 bg-[#F1F1F1] rounded-lg p-4 list-none px-0">
              <li className="grid grid-col-3 gap-1 grid-flow-col text-center mb-2 text-sm font-bold">
                <span>Heure</span>
                <span>Arrivée</span>
                <span>pique-nique</span>
                <span>Départ</span>
              </li>
              {timeSlots.map((slotItem) => {
                const currentArrialObject =
                  organizationStats.studentCountByVisitDay
                    .find((item) => item._id === countItem._id)
                    .estimatedArrivalTimes.find((time) => time.estimatedArrivalTime === slotItem) ||
                  {};

                const currentDepartureObject =
                  organizationStats.departureTimeByVisitDay
                    .find((item) => item._id === countItem._id)
                    .departureTimes.find((time) => time.departureTime === slotItem) || {};

                return (
                  <ArrivalTimeItem
                    key={`${countItem._id}-${slotItem}`}
                    time={slotItem || '0'}
                    count={currentArrialObject?.studentCount || '0'}
                    lunchCount={currentArrialObject?.lunchCount || '0'}
                    departureCount={currentDepartureObject?.studentCount || '0'}
                  />
                );
              })}
            </ul>
          </section>
        ))}
      </div>
    </section>
  );
};

// Utility function to convert "HH.MM" format to "HH:MM"
const convertTimeFormat = (time) => time.replace('.', ':');

// Component for displaying each arrival time with the student count
const ArrivalTimeItem = ({ time, count, lunchCount, departureCount }) => (
  <li className="grid grid-cols-4 gap-3 text-center text-sm px-4">
    <span>{convertTimeFormat(time)}</span>
    <span>{count || '0'}</span>
    <span>{lunchCount}</span>
    <span>{departureCount || '0'}</span>
  </li>
);

export default OrganizationStatsSlots;
