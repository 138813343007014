import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Dropdown, Input, Radio, SearchField } from '../common';

function EstablishmentForm({
  formik = {},
  inputLight = false,
  typeDropdown,
  postalcodeOptions = [],
  postalcodeSearch,
  setPostalcodeSearch = () => {},
  establishmentNameoptions,
  manualPostalcode = false,
  manualName = false,
  manualCity = false,
  manualAddress = false,
  isDepartmentDropdown = false,
  departmentDropdownOptions = [],
  otherGroupForm = false,
  foreignGroupForm = false
}) {
  const { t } = useTranslation();

  const translations = t('establishmentForm', { returnObjects: true });

  const getTranslationString = (key) =>
    foreignGroupForm
      ? key.replace('{{group}}', 'foreign-group')
      : otherGroupForm
        ? key.replace('{{group}}', 'other-group')
        : key.replace('{{group}}', 'default');

  return (
    <section id="establishment-form" className="py-10">
      <div className="text-center font-light uppercase">
        <h1>{translations?.title}</h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-5">
        <Dropdown
          inputLight={inputLight}
          label={t(getTranslationString('establishmentForm.fields.{{group}}.type.label'))}
          placeholder={t(
            getTranslationString('establishmentForm.fields.{{group}}.type.placeholder')
          )}
          name="type"
          selected={formik?.values?.type || 'placeholder'}
          options={typeDropdown}
          onChange={formik.handleChange}
          error={formik?.errors?.type}
          touched={formik?.touched?.type}
        />
        {manualPostalcode ? (
          <Input
            inputLight={inputLight}
            label={t(getTranslationString('establishmentForm.fields.{{group}}.postalcode.label'))}
            placeholder={t(
              getTranslationString('establishmentForm.fields.{{group}}.postalcode.placeholder')
            )}
            name="postalcode"
            onChange={formik.handleChange}
            value={formik?.values?.postalcode}
            error={formik?.errors?.postalcode}
            touched={formik?.touched?.postalcode}
            options={postalcodeOptions}
          />
        ) : (
          <SearchField
            inputLight={inputLight}
            label={t(getTranslationString('establishmentForm.fields.{{group}}.postalcode.label'))}
            placeholder={t(
              getTranslationString('establishmentForm.fields.{{group}}.postalcode.placeholder')
            )}
            autoComplete="off"
            name="postalcode"
            onChange={formik.handleChange}
            value={formik?.values?.postalcode}
            error={formik?.errors?.postalcode}
            touched={formik?.touched?.postalcode}
            options={postalcodeOptions}
            search={postalcodeSearch}
            setSearch={setPostalcodeSearch}
          />
        )}

        {manualName ? (
          <Input
            inputLight={inputLight}
            label={t(getTranslationString('establishmentForm.fields.{{group}}.name.label'))}
            // placeholder={fields?.generic?.writeHere}
            placeholder={t(
              getTranslationString('establishmentForm.fields.{{group}}.generic.writeHere')
            )}
            name="name"
            onChange={formik.handleChange}
            value={formik?.values?.name}
            error={formik?.errors?.name}
            touched={formik?.touched?.name}
          />
        ) : (
          <Dropdown
            inputLight={inputLight}
            label={t(getTranslationString('establishmentForm.fields.{{group}}.name.label'))}
            placeholder={t(
              getTranslationString('establishmentForm.fields.{{group}}.name.placeholder')
            )}
            name="name"
            onChange={formik.handleChange}
            value={formik?.values?.name}
            error={formik?.errors?.name}
            touched={formik?.touched?.name}
            options={establishmentNameoptions}
            disabled={isEmpty(formik?.values?.postalcode)}
          />
        )}
        <Input
          inputLight={inputLight}
          label={t(getTranslationString('establishmentForm.fields.{{group}}.city.label'))}
          placeholder={
            manualCity
              ? t(getTranslationString('establishmentForm.fields.{{group}}.generic.writeHere'))
              : t(getTranslationString('establishmentForm.fields.{{group}}.city.placeholder'))
          }
          name="city"
          onChange={formik.handleChange}
          value={formik?.values?.city}
          error={formik?.errors?.city}
          touched={formik?.touched?.city}
          disabled={!manualCity}
        />
        <Input
          inputLight={inputLight}
          label={t(getTranslationString('establishmentForm.fields.{{group}}.address.label'))}
          placeholder={
            manualAddress
              ? t(getTranslationString('establishmentForm.fields.{{group}}.generic.writeHere'))
              : t(getTranslationString('establishmentForm.fields.{{group}}.address.placeholder'))
          }
          name="address"
          onChange={formik.handleChange}
          value={formik?.values?.address}
          error={formik?.errors?.address}
          touched={formik?.touched?.address}
          disabled={!manualAddress}
        />
        {isDepartmentDropdown ? (
          <Dropdown
            inputLight={inputLight}
            label={t(getTranslationString('establishmentForm.fields.{{group}}.department.label'))}
            placeholder={t(
              getTranslationString('establishmentForm.fields.{{group}}.department.placeholder')
            )}
            name="department"
            onChange={formik.handleChange}
            value={formik?.values?.department}
            error={formik?.errors?.department}
            touched={formik?.touched?.department}
            options={departmentDropdownOptions}
          />
        ) : (
          <Input
            inputLight={inputLight}
            label={t(getTranslationString('establishmentForm.fields.{{group}}.department.label'))}
            placeholder={t(
              getTranslationString('establishmentForm.fields.{{group}}.department.placeholder')
            )}
            name="department"
            onChange={formik.handleChange}
            value={formik?.values?.department}
            error={formik?.errors?.department}
            touched={formik?.touched?.department}
            disabled
          />
        )}
        {!otherGroupForm && (
          <div className="flex flex-col items-start justify-between relative">
            <p>
              {t(getTranslationString('establishmentForm.fields.{{group}}.underContract.label'))}
            </p>
            <div className="flex mt-5">
              <Radio
                inputLight={inputLight}
                label={t(
                  getTranslationString('establishmentForm.fields.{{group}}.underContract.yes')
                )}
                name="underContract"
                id="underContract-yes"
                darkLabel
                value={formik?.values?.underContract}
                onChange={formik.handleChange}
              />
              <Radio
                inputLight={inputLight}
                label={t(
                  getTranslationString('establishmentForm.fields.{{group}}.underContract.no')
                )}
                name="underContract"
                id="underContract-no"
                darkLabel
                value={formik?.values?.underContract}
                onChange={formik.handleChange}
              />
            </div>
            {formik?.errors?.underContract && formik.touched?.underContract && (
              <p className="text text-red-500 absolute top-32" data-testid="error-text">
                {t(formik?.errors?.underContract)}
              </p>
            )}
          </div>
        )}
        {!otherGroupForm && (
          <div className="flex flex-col items-start justify-between relative">
            <p>{t(getTranslationString('establishmentForm.fields.{{group}}.rep.label'))}</p>
            <div className="flex mt-5">
              <Radio
                inputLight={inputLight}
                label={t(getTranslationString('establishmentForm.fields.{{group}}.rep.yes'))}
                id="rep-yes"
                name="rep"
                value={formik?.values?.rep}
                onChange={formik.handleChange}
                darkLabel
              />
              <Radio
                inputLight={inputLight}
                label={t(getTranslationString('establishmentForm.fields.{{group}}.rep.no'))}
                id="rep-no"
                name="rep"
                value={formik?.values?.rep}
                onChange={formik.handleChange}
                darkLabel
              />
            </div>
            {formik?.errors?.rep && formik.touched?.rep && (
              <p className="text text-red-500 absolute top-32" data-testid="error-text">
                {t(formik?.errors?.rep)}
              </p>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default EstablishmentForm;
