import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const CardButton = ({
  title,
  description = '',
  ActionText,
  internalLink = false,
  to,
  ...props
}) => {
  return internalLink ? (
    <Link
      {...props}
      to={to}
      className="text-white px-4 py-6 bg-opacity-40 min-h-40 rounded-xl flex flex-col justify-between hover:bg-white/25 backdrop-blur-sm bg-white/30">
      <ButtonInner title={title} description={description} ActionText={ActionText} />
    </Link>
  ) : (
    <a
      href={to}
      {...props}
      className="text-white px-4 py-6 bg-opacity-40 min-h-40 rounded-xl flex flex-col justify-between hover:bg-white/25 backdrop-blur-sm bg-white/30">
      <ButtonInner title={title} description={description} ActionText={ActionText} />
    </a>
  );
};

const ButtonInner = ({ title, description, ActionText }) => (
  <>
    <p className="font-bold">{title}</p>
    {description && <p className="text-sm">{description}</p>}
    <span className="flex justify-between font-bold text-[#FFC8F3]">
      <p>{ActionText}</p>
      <FaAngleRight />
    </span>
  </>
);

export default CardButton;
