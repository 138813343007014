import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FaCheck } from 'react-icons/fa';

import { Header, Button, FullScreenLoader, Popup, Popover } from '../../components';

import { bookBusSlots, getAllBusSlots, getAllSlotsInBlock } from '../../service';

import { PlusIcon, MinusIcon } from '../../assets';

function RegisterSlots() {
  const { t } = useTranslation();

  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedSlotBlocks, setSelectedSlotBlocks] = useState([]);
  const [loading, setLoading] = useState(false);

  const translations = t('registerSlots', { returnObjects: true });

  const loadSlotsInBlock = async (id) => {
    try {
      setLoading(true);
      const data = await getAllSlotsInBlock(id);
      setSelectedSlotBlocks(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedSlot) return;

    setSelectedSlotBlocks([]);
    loadSlotsInBlock(selectedSlot);
  }, [selectedSlot]);

  // Get available slots
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await getAllBusSlots();

        setSlots(data);
        setSelectedSlot(data[0]._id);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <main className="min-h-screen bg-blue-999 text-white pb-10">
      <Header />

      <section id="text-container" className="text-center py-10">
        <h1
          className="text-4xl font-bold uppercase"
          dangerouslySetInnerHTML={{ __html: translations?.title1 }}
        />
        <h1
          className="text-4xl uppercase outline-text font-bold"
          dangerouslySetInnerHTML={{ __html: translations?.title2 }}
        />
        <div dangerouslySetInnerHTML={{ __html: translations?.info }} />
      </section>

      <section id="slots-container" className="container mx-auto px-3">
        <div className="flex gap-4 flex-wrap items-center justify-center">
          {slots.map(({ _id, dayId, timefrom, timeTill, timing }) => (
            <button
              key={_id}
              onClick={() => setSelectedSlot(_id)}
              className={`${
                selectedSlot === _id ? 'bg-red-550' : 'bg-transparent'
              } border-red-550 border-[1px] select-none text-white px-4 py-1 rounded-md flex flex-col items-center justify-center gap-1`}>
              <span className="text-2xl font-bold text-center">{dayId}</span>
              <span
                className="text-lg font-normal text-center"
                dangerouslySetInnerHTML={{
                  __html: translations?.timing?.[timing?.toLowerCase()]
                }}
              />
              <span className="text-base font-normal text-center">
                {timefrom} - {timeTill}
              </span>
            </button>
          ))}
        </div>

        {selectedSlot && selectedSlotBlocks?.length > 0 && (
          <>
            {selectedSlotBlocks.map((slot) => (
              <SingleSlotBlock
                key={slot._id}
                slot={slot}
                dayId={slots.find((s) => s._id === selectedSlot).dayId}
                reloadSlotData={() => loadSlotsInBlock(selectedSlot)}
              />
            ))}
          </>
        )}
      </section>

      <div className="container mt-5 mx-auto px-3">
        <div className="text-white mt-8">
          <h2 className="text-sm sm:text-2xl font-bold">{t('registration.support.title')}</h2>
          <p className="font-light mt-1">
            {t('registration.support.subheading')}
            <span className="font-bold">
              <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
            </span>
          </p>
        </div>
      </div>

      {loading && <FullScreenLoader transparent={true} />}
    </main>
  );
}

export default RegisterSlots;

const SingleSlotBlock = ({ slot, dayId, reloadSlotData }) => {
  const { _id, gateSlots, timeFrom, timeTill } = slot;

  return (
    <div className="mt-8">
      <p className="bg-[#FFFFFF4D] px-4 py-3 rounded-md text-white text-lg font-bold text-center w-full">
        {timeFrom} - {timeTill}
      </p>

      {gateSlots.map((gate) => (
        <SingleGateSlot
          key={gate._id}
          slot={_id}
          gate={gate}
          dayId={dayId}
          time={`${timeFrom} - ${timeTill}`}
          reloadSlotData={reloadSlotData}
        />
      ))}
    </div>
  );
};

const SingleGateSlot = ({ slot, gate, dayId, time, reloadSlotData }) => {
  const { t } = useTranslation();

  const translations = t('registerSlots', { returnObjects: true });
  const toastMessages = t('toastMessages', { returnObjects: true });

  const [numberOfSlots, setNumberOfSlots] = useState(0);
  const [confirmBooking, setConfirmBooking] = useState(false);
  const [loading, setLoading] = useState(false);

  const { _id, bookedSlots, totalSlots, gate: gateLocation } = gate;

  const updateBookingSlotsNumber = (func) => {
    try {
      const avaialbleSlots = totalSlots - bookedSlots;

      const nextSlots = numberOfSlots + Number(func);

      if (nextSlots < 0 || nextSlots > avaialbleSlots) return;

      setNumberOfSlots(nextSlots);
    } catch (error) {
      console.log(error);
    }
  };

  const submitBooking = async () => {
    if (numberOfSlots === 0) return;

    try {
      setLoading(true);
      await bookBusSlots({ slot: slot, gate: _id, numberOfSlots });
      toast.success(toastMessages?.busBookingSuccess);
    } catch (error) {
      console.log(error);
      toast.error(toastMessages?.busBookingError ?? toastMessages?.error);
    } finally {
      setConfirmBooking(false);
      setLoading(false);
      setNumberOfSlots(0);
      reloadSlotData();
    }
  };

  return (
    <div className="border-white border-[1px] rounded-md px-6 py-3 mt-2">
      <div className="flex gap-2 items-center justify-between mb-4 flex-wrap">
        <p className="text-white flex gap-2">
          <span
            className="uppercase"
            dangerouslySetInnerHTML={{
              __html: translations?.gateLocation?.[gateLocation?.toLowerCase()]
            }}
          />
          <span>
            ({bookedSlots}/{totalSlots}{' '}
            <span dangerouslySetInnerHTML={{ __html: translations?.alreadyBooked }} />)
          </span>
        </p>

        <div className="flex gap-3 items-center flex-wrap">
          <div className="flex gap-1 items-center">
            <input
              value={numberOfSlots}
              onChange={(e) => updateBookingSlotsNumber(e.target.value)}
              disabled
              className="bg-[#F6F6F64D] px-4 py-2 rounded-md text-white text-left w-20"
            />
            <div className="flex gap-1">
              <button onClick={() => updateBookingSlotsNumber(+1)}>
                <PlusIcon />
              </button>
              <button onClick={() => updateBookingSlotsNumber(-1)}>
                <MinusIcon />
              </button>
            </div>
          </div>

          <Button
            className="bg-white px-4 py-2 h-auto rounded-md text-black"
            onClick={() => {
              if (numberOfSlots > 0) setConfirmBooking(true);
            }}
            loading={loading}
            disabled={loading}>
            <span dangerouslySetInnerHTML={{ __html: translations?.bookGate }} />
          </Button>
        </div>
      </div>

      <div className="flex gap-2 flex-wrap mt-6">
        {Array.from({ length: bookedSlots }, (_, i) => (
          <div key={i} className="container-clip container-clip-no-bg h-12 w-20">
            <div className="clip-item bg-[#727272] h-full flex items-center justify-start">
              <FaCheck className="text-white text-4xl mx-auto " />
            </div>
          </div>
        ))}
        {Array.from({ length: totalSlots - bookedSlots }, (_, i) => (
          <div key={i} className="container-clip h-12 w-20">
            <div className="clip-item bg-blue-999 h-full flex items-center justify-start" />
          </div>
        ))}
      </div>

      <Popup open={confirmBooking} setOpen={setConfirmBooking}>
        <div className="text-black flex flex-col gap-2 w-full">
          <h2
            className="text-3xl text-center font-bold"
            dangerouslySetInnerHTML={{
              __html: translations?.confirmBooking?.heading ?? 'sdf sdf sdf '
            }}
          />

          <div className="bg-[#F6F6F6] py-3 px-5 rounded-md text-center">
            <p className="text-lg">
              <span
                className="font-bold"
                dangerouslySetInnerHTML={{ __html: translations?.confirmBooking?.gateLocation }}
              />{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: translations?.gateLocation?.[gateLocation?.toLowerCase()]
                }}
              />
            </p>
            <p className="text-lg">
              <span
                className="font-bold"
                dangerouslySetInnerHTML={{ __html: translations?.confirmBooking?.date }}
              />{' '}
              {dayId} Septembre 2024
            </p>
            <p className="text-lg">
              <span
                className="font-bold"
                dangerouslySetInnerHTML={{ __html: translations?.confirmBooking?.timeSlot }}
              />{' '}
              {time}
            </p>
            <p className="text-lg">
              <span
                className="font-bold"
                dangerouslySetInnerHTML={{ __html: translations?.confirmBooking?.numberOfSlots }}
              />{' '}
              {numberOfSlots}
            </p>
          </div>

          <Button
            backgroundColor="bg-red-550"
            className="text-white"
            onClick={submitBooking}
            loading={loading}
            disabled={loading}>
            <span
              className="font-bold"
              dangerouslySetInnerHTML={{ __html: translations?.confirmBooking?.confirm }}
            />
          </Button>
          <Button
            backgroundColor="bg-transparent"
            className="border-red-550 border-[1px] text-red-550"
            onClick={() => setConfirmBooking(false)}
            loading={loading}
            disabled={loading}>
            <span
              className="font-bold"
              dangerouslySetInnerHTML={{ __html: translations?.confirmBooking?.cancel }}
            />
          </Button>
        </div>
      </Popup>
    </div>
  );
};
