import { useTranslation } from 'react-i18next';
import { FaAngleLeft } from 'react-icons/fa';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import Swal from 'sweetalert2';

import { Button } from '../common';

import { adminMessageSchama } from '../../utils/schema';

function AdminResponseModel({ isOpen = true, setOpen, responsibleEmail, handleSubmit }) {
  const { t } = useTranslation();

  const translations = t('adminResponseModel', { returnObjects: true });

  const confirmSubmit = async (values) => {
    Swal.fire({
      title: 'Confirm before sending',
      input: 'Please confirm that you want to send this message',
      confirmButtonText: 'Send',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit(values);
        setOpen(false);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      message: ''
    },
    validationSchema: adminMessageSchama,
    onSubmit: confirmSubmit
  });

  useEffect(() => {
    if (isOpen) formik.resetForm();
  }, [isOpen]);
  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center max-w-xl min-w-64 mx-auto z-50">
        <div className="bg-white shadow rounded-2xl w-full">
          <div className="bg-gray-100 flex">
            <div className="flex items-center">
              <Button
                className="flex items-center justify-center px-4 text-white"
                backgroundColor="bg-gray-800"
                type="button"
                onClick={() => setOpen(false)}>
                <FaAngleLeft className="text-xl" />
              </Button>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-base leading-6 text-gray-900">
              {translations?.titl1} <span className="font-semibold">{translations?.title2}</span>
            </h3>
            <div className="mt-2 text-sm text-gray-500">
              <p>
                {translations?.to} {responsibleEmail}
              </p>
            </div>
            <form
              className="mt-5 flex flex-col items-center text-black"
              onSubmit={formik.handleSubmit}>
              <div className="w-full">
                <label htmlFor="message" className="">
                  {translations?.insertMessage?.label}
                </label>
                <textarea
                  type="text"
                  name="message"
                  placeholder={translations?.insertMessage?.placeholder}
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  className="block w-full rounded-xl bg-gray-100 py-1.5 px-4"
                  rows={5}
                />
                {formik.errors.message && formik.touched.message && (
                  <p className="text text-red-500 absolute" data-testid="error-text">
                    {formik.errors.message}
                  </p>
                )}
              </div>
              <div className="flex justify-center mt-4 self-end">
                <Button
                  type="submit"
                  className="text-white px-6 rounded-full"
                  backgroundColor="bg-gray-600">
                  {translations?.submit}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
}

export default AdminResponseModel;
