import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  Header,
  Button,
  EstablishmentForm,
  StudentInformationForm,
  ResponsibleInformartionForm
} from '../../components';

import {
  getRegistrationOfSchool,
  putRegistrationOfSchool,
  getWSFRegionalInformation,
  patchConfirmTranspotation
} from '../../service';

import { SchoolRegistrationSchema } from '../../utils/schema';

import { typeQuries, typeDropdown, typeEstablishmentDropdown } from '../../utils/values';
import { ProfileContext } from '../../context/profile';

function TemporaryEstablishmentAccount({ fetchUserDetails }) {
  const { t, i18n } = useTranslation();

  const [profile] = useContext(ProfileContext);

  const [postalcodeOptions, setPostalcodeOptions] = useState([]);
  const [establishmentNameoptions, setEstablishmentNameoptions] = useState([]);
  const [postalcodeSearch, setPostalcodeSearch] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);

  const translations = t(`temporaryEstablishmentAccount.${profile.organization.registrationType}`, {
    returnObjects: true
  });
  const toastMessages = t('toastMessages', { returnObjects: true });

  const handleSubmit = async (values) => {
    try {
      const {
        underContract: underContractTemp,
        rep: repTemp,
        studentInformation: studentInformationTemp
      } = values;

      const rep = repTemp.split('-')[1];
      const underContract = underContractTemp.split('-')[1];

      const studentInformation = studentInformationTemp.map((student) => {
        const { visitDay, studentCount, studentLevel } = student;
        return {
          visitDay: visitDay.split('-')[0],
          studentCount,
          studentLevel: studentLevel.split('-')[0]
        };
      });

      await putRegistrationOfSchool({
        ...values,
        studentInformation,
        rep,
        underContract
      });

      toast.success(toastMessages.recordUpdated);
      getPageData();
    } catch (err) {
      const message = err?.response?.data?.message || toastMessages.error;
      console.log(err);
      toast.error(message);
    }
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      postalcode: '',
      name: '',
      city: '',
      address: '',
      department: '',
      type: '',
      underContract: '',
      rep: '',
      // responsible1
      responsible: [
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          position: ''
        },
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          position: ''
        }
      ],
      studentInformation: [
        {
          studentLevel: '',
          studentCount: '',
          visitDay: ''
        }
      ]
    },
    validationSchema: SchoolRegistrationSchema
  });

  const getPageData = async () => {
    try {
      const {
        underContract: tempUnderContract = '',
        rep: tempRep = '',
        studentInformation: tempStudentInformation,
        ...data
      } = await getRegistrationOfSchool();

      const underContract = 'underContract-' + tempUnderContract;
      const rep = 'rep-' + tempRep;
      setPostalcodeSearch(data.postalcode);

      const studentInformation = tempStudentInformation.map((student, index) => {
        return {
          studentLevel: student.studentLevel,
          studentCount: student.studentCount,
          visitDay: student.visitDay + '-' + index
        };
      });

      formik.setValues({
        ...data,
        studentInformation,
        underContract,
        rep
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(() => {
    if (isEmpty(formik.values.type)) return;

    const where = typeQuries[formik.values.type];

    if (!firstLoad) {
      setPostalcodeSearch('');
      formik.setFieldValue('postalcode', '');
      formik.setFieldValue('name', '');
      formik.setFieldValue('city', '');
      formik.setFieldValue('address', '');
      formik.setFieldValue('department', '');
    }

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({ where });

        results.forEach((result) => {
          setPostalcodeOptions((prev) => [
            ...prev,
            {
              value: result.code_postal,
              label: result.code_postal
            }
          ]);
        });
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [formik.values.type]);

  useEffect(() => {
    if (isEmpty(postalcodeSearch) || isEmpty(formik.values.type)) return;

    const where = typeQuries[formik.values.type];

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({
          where: `startswith(code_postal,'${postalcodeSearch}') AND ${where}`
        });
        const currentPostalcodeOptions = [];

        for (const result of results) {
          if (!currentPostalcodeOptions.some((option) => option.value === result.code_postal))
            currentPostalcodeOptions.push({
              value: result.code_postal,
              label: result.code_postal
            });
        }
        currentPostalcodeOptions.sort((a, b) => a.value.localeCompare(b.value));
        setPostalcodeOptions(currentPostalcodeOptions);
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [postalcodeSearch]);

  useEffect(() => {
    if (isEmpty(formik.values.postalcode) || isEmpty(formik.values.type)) return;

    const where = typeQuries[formik.values.type];

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({
          where: `code_postal="${postalcodeSearch}" AND ${where}`
        });
        setEstablishmentNameoptions([]);

        results.forEach((result) => {
          setEstablishmentNameoptions((prev) => [
            ...prev,
            {
              value: result.nom_etablissement,
              label: result.nom_etablissement,
              ...result
            }
          ]);
        });
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [formik.values.postalcode]);

  useEffect(() => {
    if (
      isEmpty(formik.values.postalcode) ||
      isEmpty(formik.values.type) ||
      isEmpty(formik.values.name) ||
      isEmpty(establishmentNameoptions)
    )
      return;

    const slectedObject = establishmentNameoptions.find(
      (option) => option.value === formik.values.name
    );

    formik.setFieldValue('city', slectedObject?.nom_commune || '');
    formik.setFieldValue('address', slectedObject?.adresse_1 || '');
    formik.setFieldValue('department', slectedObject?.code_departement);
    setFirstLoad(false);
  }, [formik.values.name, establishmentNameoptions]);

  const handleTranspotationConfirmed = async () => {
    try {
      await patchConfirmTranspotation();
      toast.success(toastMessages.finalRegistrationSuccess);
      fetchUserDetails();
    } catch (err) {
      const message = err?.response?.data?.message || toastMessages.error;
      console.log(err);
      toast.error(message);
    }
  };

  return (
    <main className="min-h-screen bg-blue-999 text-white pb-10">
      <Header
        isAuthenticated
        title="compte d’établissement temporaire"
        backgroundColor="bg-blue-999"
      />
      <form onSubmit={formik.handleSubmit} className="container mt-5 mx-auto">
        <section
          id="text-container"
          className="py-10 text-base"
          dangerouslySetInnerHTML={{ __html: translations?.topInfo }}
        />

        <EstablishmentForm
          formik={formik}
          typeDropdown={
            profile.organization?.registrationType === 'foreign-group'
              ? typeEstablishmentDropdown[
                  `${profile.organization?.registrationType}-${i18n.language}`
                ]
              : typeEstablishmentDropdown[profile.organization?.registrationType]
          }
          postalcodeOptions={postalcodeOptions}
          postalcodeSearch={postalcodeSearch}
          setPostalcodeSearch={setPostalcodeSearch}
          establishmentNameoptions={establishmentNameoptions}
        />
        <StudentInformationForm formik={formik} isRegistrationForm />
        <ResponsibleInformartionForm
          formik={formik}
          index={0}
          title={translations?.responsibleInformartionForm1?.title}
        />
        <ResponsibleInformartionForm
          formik={formik}
          index={1}
          title={translations?.responsibleInformartionForm2?.title}
        />
        <Button className="text-black px-4 font-normal uppercase" type="submit">
          {translations?.submit}
        </Button>
        <section className="py-10" dangerouslySetInnerHTML={{ __html: translations?.midInfo }} />
        <Button
          className="text-black px-4 font-normal uppercase"
          type="button"
          onClick={handleTranspotationConfirmed}>
          {translations?.submit2}
        </Button>
        <section className="py-10" dangerouslySetInnerHTML={{ __html: translations?.bottomInfo }} />
        <section className="container py-10 mx-auto text-white mt-4">
          <h2 className="text-sm sm:text-2xl font-bold"> {t('registration.support.title')}</h2>
          <p className="font-light mt-1">
            {t('registration.support.subheading')}
            <span className="font-bold">
              <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
            </span>{' '}
          </p>
        </section>
      </form>
    </main>
  );
}

export default TemporaryEstablishmentAccount;
