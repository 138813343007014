import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FaPlus } from 'react-icons/fa';
import { PiWarningFill } from 'react-icons/pi';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import {
  Header,
  Button,
  EstablishmentForm,
  ResponsibleInformartionForm,
  ClassInformationForm
} from '../../components';

import {
  classRegistrationTeacherSchema,
  otherGroupClassRegistrationTeacherSchema
} from '../../utils/schema';

import {
  getWSFRegionalInformation,
  updateTeacherRegisterClass,
  getTeacherRegisterClass
} from '../../service';

import {
  typeQuries,
  typeEstablishmentDropdown,
  departmentDropdownOptions,
  manualFormFieldsGroups,
  otherGroupForms
} from '../../utils/values';
import { ProfileContext } from '../../context/profile';

function EditClassRegistrationTeacher() {
  const { t, i18n } = useTranslation();

  const translations = t('editClassRegistrationTeacher', {
    returnObjects: true
  });
  const toastMessages = t('toastMessages', { returnObjects: true });

  const [profile] = useContext(ProfileContext);

  const [postalcodeOptions, setPostalcodeOptions] = useState([]);
  const [establishmentNameoptions, setEstablishmentNameoptions] = useState([]);
  const [postalcodeSearch, setPostalcodeSearch] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);
  const [deletedCalsses, setDeletedClasses] = useState([]);

  const isManualField = manualFormFieldsGroups.includes(profile.organization?.registrationType);

  const manualPostalcode = isManualField;
  const manualName = isManualField;
  const manualCity = isManualField;
  const manualAddress = isManualField;
  const isDepartmentDropdown = isManualField;

  const isOtherGroupForm = otherGroupForms.includes(profile.organization?.registrationType);

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    try {
      const {
        underContract: tempUnderContract = '',
        rep: tempRep = '',
        studentInformation: tempStudentInformation = [],
        responsible = [],
        ...data
      } = await getTeacherRegisterClass();

      const underContract = 'underContract-' + tempUnderContract;
      const rep = 'rep-' + tempRep;
      setPostalcodeSearch(data.postalcode);

      formik.setValues({
        ...data,

        underContract,
        rep,
        responsible: responsible.map((responsible, respIndex) => ({
          ...responsible,
          classes: responsible.classes.map((classItem, classIndex) => ({
            ...classItem,
            visitDay: `${classItem.visitDay}-responsible[${respIndex}].classes[${classIndex}].visitDay`,
            underContract: `responsible[${respIndex}].classes[${classIndex}].underContract-${classItem.underContract}`
          }))
        }))
      });
    } catch (err) {
      console.log('err===========>', err);
      const message = err?.response?.data?.message || 'Une erreur est survenue';
      toast.error(message);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { underContract: underContractTemp, rep: repTemp } = values;

      const rep = repTemp.split('-')[1] || undefined;
      const underContract = underContractTemp.split('-')[1] || undefined;

      const restructuredData = {
        ...values,
        responsible: values.responsible.map((responsible) => ({
          ...responsible,
          teacherId: responsible._id,
          classes: responsible.classes.map((classItem) => ({
            ...classItem,
            visitDay: classItem.visitDay.split('-')[0],
            underContract: classItem.underContract.split('-')[1]
          }))
        })),
        rep,
        underContract,
        rep,
        deletedCalsses
      };

      await updateTeacherRegisterClass(restructuredData);
      getPageData();

      toast.success(toastMessages.recordUpdated);
    } catch (err) {
      const message = err?.response?.data?.message || toastMessages.error;
      toast.error(message);
    }
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      postalcode: '',
      name: '',
      city: '',
      address: '',
      department: '',
      type: '',
      underContract: '',
      rep: '',
      responsible: [
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          position: '',
          classes: [
            {
              classLevel: '',
              classCount: '',
              supervisorCount: '',
              visitorsCount: '',
              classNumber: '',
              visitDay: '',
              underContract: '',
              estimatedArrivalTime: '',
              estimatedTimeOnSite: ''
            }
          ]
        }
      ]
    },
    validationSchema: isOtherGroupForm
      ? otherGroupClassRegistrationTeacherSchema
      : classRegistrationTeacherSchema
  });

  const addMoreClassToLastResponsible = () => {
    const lastResponsible = formik.values.responsible.length - 1;
    formik.setFieldValue(`responsible[${lastResponsible}].classes`, [
      ...formik.values.responsible[lastResponsible].classes,
      {
        classLevel: '',
        classCount: '',
        supervisorCount: '',
        visitorsCount: '',
        classNumber: '',
        visitDay: '',
        underContract: '',
        estimatedArrivalTime: '',
        estimatedTimeOnSite: ''
      }
    ]);
  };

  useEffect(() => {
    if (isEmpty(formik.values.type) || manualPostalcode) return;

    const where = typeQuries[formik.values.type];

    if (!firstLoad) {
      setPostalcodeSearch('');
      formik.setFieldValue('postalcode', '');
      formik.setFieldValue('name', '');
      formik.setFieldValue('city', '');
      formik.setFieldValue('address', '');
      formik.setFieldValue('department', '');
    }

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({ where });

        results.forEach((result) => {
          setPostalcodeOptions((prev) => [
            ...prev,
            {
              value: result.code_postal,
              label: result.code_postal
            }
          ]);
        });
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [formik.values.type]);

  useEffect(() => {
    if (isEmpty(postalcodeSearch) || isEmpty(formik.values.type) || manualPostalcode) return;

    const where = typeQuries[formik.values.type];

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({
          where: `startswith(code_postal,'${postalcodeSearch}') AND ${where}`
        });
        const currentPostalcodeOptions = [];

        for (const result of results) {
          if (!currentPostalcodeOptions.some((option) => option.value === result.code_postal))
            currentPostalcodeOptions.push({
              value: result.code_postal,
              label: result.code_postal
            });
        }
        currentPostalcodeOptions.sort((a, b) => a.value.localeCompare(b.value));
        setPostalcodeOptions(currentPostalcodeOptions);
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [postalcodeSearch]);

  useEffect(() => {
    if (isEmpty(formik.values.postalcode) || isEmpty(formik.values.type) || manualName) return;

    const where = typeQuries[formik.values.type];

    const fetchData = async () => {
      try {
        const { results } = await getWSFRegionalInformation({
          where: `code_postal="${postalcodeSearch}" AND ${where}`
        });
        setEstablishmentNameoptions([]);

        results.forEach((result) => {
          setEstablishmentNameoptions((prev) => [
            ...prev,
            {
              value: result.nom_etablissement,
              label: result.nom_etablissement,
              ...result
            }
          ]);
        });
      } catch (err) {
        console.log('err===========>', err);
        const message = err?.response?.data?.message || 'Une erreur est survenue';
        toast.error(message);
      }
    };

    fetchData();
  }, [formik.values.postalcode]);

  useEffect(() => {
    if (
      isEmpty(formik.values.postalcode) ||
      isEmpty(formik.values.type) ||
      isEmpty(formik.values.name) ||
      isEmpty(establishmentNameoptions) ||
      manualCity
    )
      return;

    const slectedObject = establishmentNameoptions.find(
      (option) => option.value === formik.values.name
    );

    formik.setFieldValue('city', slectedObject?.nom_commune || '');
    formik.setFieldValue('address', slectedObject?.adresse_1 || '');
    formik.setFieldValue('department', slectedObject?.code_departement);
    setFirstLoad(false);
  }, [formik.values.name, establishmentNameoptions]);

  const moveToFields = () => {
    // Find the first field with an error and focus on it
    const firstErrorField = Object.keys(formik.errors)[0];

    const errorFieldElement = document.getElementsByName(firstErrorField)[0];

    if (errorFieldElement) errorFieldElement.focus();
  };

  const handleDeletedClass = (deletedId) => {
    setDeletedClasses([...deletedCalsses, deletedId]);
  };

  return (
    <main className="min-h-screen bg-blue-999 text-white pb-10">
      <Header isAuthenticated />
      <form onSubmit={formik.handleSubmit} className="container mt-5 mx-auto">
        <section id="text-container" className="text-center py-10">
          <h1
            className="text-4xl font-bold uppercase"
            dangerouslySetInnerHTML={{ __html: translations?.title }}
          />
          <h1
            className="text-4xl uppercase outline-text font-bold"
            dangerouslySetInnerHTML={{ __html: translations.title2 }}
          />
          <p
            className="text-center mt-5 font-light"
            dangerouslySetInnerHTML={{ __html: translations?.sub1 }}
          />
          <p
            className="text-left mt-5 font-light"
            dangerouslySetInnerHTML={{ __html: translations?.sub2 }}
          />
        </section>
        <EstablishmentForm
          formik={formik}
          manualPostalcode={manualPostalcode}
          manualName={manualName}
          manualCity={manualCity}
          manualAddress={manualAddress}
          isDepartmentDropdown={isDepartmentDropdown}
          departmentDropdownOptions={
            departmentDropdownOptions[profile.organization.registrationType]
          }
          typeDropdown={
            profile.organization.registrationType === 'foreign-group'
              ? typeEstablishmentDropdown[
                  `${profile.organization.registrationType}-${i18n.language}`
                ]
              : typeEstablishmentDropdown[profile.organization.registrationType]
          }
          postalcodeOptions={postalcodeOptions}
          postalcodeSearch={postalcodeSearch}
          setPostalcodeSearch={setPostalcodeSearch}
          establishmentNameoptions={establishmentNameoptions}
          foreignGroupForm={profile.organization.registrationType === 'foreign-group'}
          otherGroupForm={isOtherGroupForm}
        />
        {formik.values.responsible.map((_, index) => (
          <>
            <ResponsibleInformartionForm
              formik={formik}
              index={index}
              title={translations?.responsibleInformartionForm?.title}
            />

            <ClassInformationForm
              title={translations?.classInformationForm?.title}
              responsibleIndex={index}
              formik={formik}
              otherGroupForm={isOtherGroupForm}
              deletedClass={handleDeletedClass}
            />
          </>
        ))}

        <section className="py-10">
          <p className="font-thin flex justify-center">
            <PiWarningFill className="text-yellow-500 mt-1 mr-1" />
            {translations?.warning}
          </p>

          {/* {!isOtherGroupForm && ( */}
          <Button
            className="text-black flex items-center justify-center my-6 uppercase"
            type="button"
            onClick={addMoreClassToLastResponsible}>
            <FaPlus />
            <p className="ml-1"> {translations?.addClass}</p>
          </Button>
          {/* )} */}
        </section>
        <div dangerouslySetInnerHTML={{ __html: translations?.bottomInfo }} />
        <Button
          className="text-black flex items-center justify-center my-6 uppercase"
          onClick={moveToFields}
          type="submit">
          <p className="ml-1">{translations?.moveToFields}</p>
        </Button>
        <section className="text-white mt-8">
          <h2 className="text-sm sm:text-2xl font-bold">{t('registration.support.title')}</h2>
          <p className="font-light mt-1">
            {t('registration.support.subheading')}
            <span className="font-bold">
              <a href="mailto: group@worldskillslyon2024.com"> group@worldskillslyon2024.com</a>
            </span>{' '}
          </p>
        </section>
      </form>
    </main>
  );
}

export default EditClassRegistrationTeacher;
