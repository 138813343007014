const Loading = ({ loading = false }) => {
  if (!loading) return null;

  return (
    <svg
      data-testid="loading"
      className="mr-1.5"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="20 20 60 60"
      preserveAspectRatio="xMidYMid">
      <circle
        cx="50"
        cy="50"
        r="24"
        strokeWidth="7"
        stroke="#353535"
        strokeDasharray="37.69911184307752 37.69911184307752"
        fill="none"
        strokeLinecap="round">
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 50;360 50 50"></animateTransform>
      </circle>
      <circle
        cx="50"
        cy="50"
        r="16"
        strokeWidth="7"
        stroke="#666666"
        strokeDasharray="25.132741228718345 25.132741228718345"
        strokeDashoffset="25.132741228718345"
        fill="none"
        strokeLinecap="round">
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 50;-360 50 50"></animateTransform>
      </circle>
    </svg>
  );
};

export default Loading;
