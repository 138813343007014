import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CiMail } from 'react-icons/ci';
import { FaAngleLeft, FaAngleRight, FaBell, FaCheck } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  AdminResponseModel,
  Badge,
  Button,
  ColumnVisibilityModel,
  FilterModel,
  Header,
  InfoBox,
  OpenNotificationModel
} from '../../components';

import clsx from 'clsx';
import { toast } from 'react-toastify';
import { ExportIcon, FunnelIcon, VisibilityButtonIcon } from '../../assets';
import { AdminContext } from '../../context/admin';
import {
  addNotes,
  getNotesById,
  getOrganizationDetailForAdmin,
  postAdminResponse
} from '../../service';
import { exportData } from '../../utils/export';
import { otherGroupForms } from '../../utils/values';
import { calculateDepartureTime } from '../../utils/utilFunctions';

export const initialTableHeaders = [
  { key: 'organizationName', name: 'NOM DE L’ÉTABLISSEMENT' },
  { key: 'teacherName', name: 'Informations/classe' },
  { key: 'position', name: 'Fonction' },
  { key: 'email', name: 'Email' },
  { key: 'phone', name: 'Téléphone' },
  { key: 'classLevel', name: 'Niveau de la classe', otherGroupsTitle: 'NOM DU GROUPE' },
  {
    key: 'classCount',
    name: 'Nombre d’élèves dans la classe',
    otherGroupsTitle: 'NOMBRE DE PERSONNES'
  },
  {
    key: 'supervisorCount',
    name: 'Nombre de personnes encadrant la classe',
    hideForOtherGroups: true
  },
  { key: 'visitorsCount', name: 'Nombre de visiteurs total', hideForOtherGroups: true },
  { key: 'classNumber', name: 'Numérotez votre classe', hideForOtherGroups: true },
  { key: 'visitDay', name: 'Jour de la visite envisagé' },
  { key: 'underContract', name: 'Déjeuner sur place' },
  { key: 'estimatedArrivalTime', name: 'Heure d’arrivée prévue au site' },
  { key: 'estimatedTimeOnSite', name: 'Temps prévu sur site' },
  { key: 'departureTime', name: 'HEURE DE DÉPART' }
];

function OrganizationDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const translations = t('organizationDetail', { returnObjects: true });

  const [classes, setClasses] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [organization, setOrganization] = useState({});
  const [columnsModel, setColumnsModel] = useState(false);
  const [notificationModel, setNotificationModel] = useState(false);
  const [adminProfile] = useContext(AdminContext);

  const [filterModel, setFilterModel] = useState(false);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [notesData, setNotesData] = useState([]);

  const [tableHeaders, setTableHeaders] = useState([]);
  const [filteredHeaders, setFilteredHeaders] = useState([]);

  useEffect(() => {
    if (organization?.registrationType) {
      const isOtherGroups = otherGroupForms.includes(organization?.registrationType);

      const tH = initialTableHeaders
        .filter((tableHeader) =>
          isOtherGroups && tableHeader.hideForOtherGroups ? null : tableHeader
        )
        .map((tableHeader) => {
          const updatedHeaderName =
            isOtherGroups && tableHeader.otherGroupsTitle
              ? tableHeader.otherGroupsTitle
              : tableHeader.name;

          return {
            ...tableHeader,
            name: updatedHeaderName,
            isSelected: true
          };
        });
      setTableHeaders(tH);
      setFilteredHeaders([...tH]);
    }
  }, [organization]);

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    try {
      const { organization, classes } = await getOrganizationDetailForAdmin(id);
      const classWithDepartureTime = classes.map((classItem) => ({
        ...classItem,
        departureTime: calculateDepartureTime(
          classItem.estimatedArrivalTime,
          classItem.estimatedTimeOnSite
        )
      }));
      setOrganization(organization);
      setClasses(classWithDepartureTime);
      setFilteredClasses(classWithDepartureTime);
      const notes = await getNotesById(id);
      setNotesData(notes);
    } catch (error) {
      console.log(error);
    }
  };

  const handleModelSubmit = async (values) => {
    try {
      await postAdminResponse({ ...values, _id: id });
      setShowModel(false);
      toast.success('Réponse envoyée avec succès');
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterUpdate = (formValues) => {
    // Convert formValues into an array of filter criteria
    const criteria = Object.keys(formValues).reduce((acc, key) => {
      if (key.startsWith('dropdown_')) {
        const index = key.split('_')[1];
        const valueKey = `searchField_${index}`;
        // Ensure we have a pair before adding
        if (formValues[valueKey] !== undefined) {
          acc.push({ key: formValues[key], value: formValues[valueKey] });
        }
      }
      return acc;
    }, []);

    // Filter based on criteria
    const filteredClasses = classes.filter((classItem) =>
      criteria.every(({ key, value }) => {
        if (['position', 'email', 'phone'].includes(key)) {
          return classItem.teacher[key] === value;
        } else if (key === 'teacherName') {
          return `${classItem.teacher.firstName} ${classItem.teacher.lastName}` === value;
        } else return classItem[key] === value;
      })
    );

    setFilteredClasses(filteredClasses);

    setFilterModel(false);
  };

  const handleDataExport = () => {
    exportData(tableHeaders, getClassesForExportAndFilters());
  };

  const getClassesForExportAndFilters = () => {
    const formattedClasses = JSON.parse(JSON.stringify(classes));
    return formattedClasses.map((formattedClass) => {
      const { firstName, lastName, position, email, phone } = formattedClass.teacher;
      return {
        ...formattedClass,
        teacherName: `${firstName} ${lastName}`,
        position,
        email,
        phone
      };
    });
  };

  const handleColumnVisibilityUpdate = (updatedColumns) => {
    setTableHeaders(updatedColumns);
    setFilteredHeaders(updatedColumns.filter((updatedColumn) => updatedColumn.isSelected));
    setColumnsModel(false);
  };

  const handleNotificationSubmit = async (values) => {
    console.log(values);
    try {
      const reqBody = {
        organization: id,
        note: values
      };
      await addNotes(reqBody);
      const notes = await getNotesById(id);
      setNotesData(notes);
      toast.success('Réponse envoyée avec succès');
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackClick = () => {
    let url = '/admin';

    if (adminProfile?.adminConfig?.url) {
      url = adminProfile.adminConfig.url;
    }

    navigate(url);
  };

  return (
    <main className="min-h-screen text-black pb-10">
      <AdminResponseModel
        isOpen={showModel}
        setOpen={setShowModel}
        handleSubmit={handleModelSubmit}
        responsibleEmail={organization.responsible
          ?.filter((resp) => resp.role === 'responsible')
          .map((resp) => resp.email)
          .join(',')}
      />
      <FilterModel
        tableHeaders={tableHeaders}
        data={getClassesForExportAndFilters()}
        isOpen={filterModel}
        setOpen={setFilterModel}
        onFilterUpdate={handleFilterUpdate}
      />
      <ColumnVisibilityModel
        tableHeaders={tableHeaders}
        isOpen={columnsModel}
        setOpen={setColumnsModel}
        onUpdate={handleColumnVisibilityUpdate}
      />
      <OpenNotificationModel
        notes={notesData.notes}
        isOpen={notificationModel}
        setOpen={setNotificationModel}
        onUpdate={handleNotificationSubmit}
      />
      <Header isAuthenticated adminHeader backgroundColor="bg-white" />
      <section className="container py-10 mx-auto">
        <div className="flex items-center justify-between my-5">
          <div className="flex items-center">
            <Button
              className="text-black flex items-center justify-center px-4"
              backgroundColor="bg-gray-200"
              type="button"
              // internalLink
              onClick={handleBackClick}>
              <FaAngleLeft className="text-xl" />
            </Button>
            <p className="text-2xl ml-2">
              {organization.name}{' '}
              <span className="text-sm bg-grey-300">
                {organization?.createdAt && new Date(organization?.createdAt).toLocaleString()}
              </span>
            </p>
          </div>
        </div>
        <div className="my-8 flex items-center">
          {organization.registrationPurpose === 'interest-declaration' && (
            <>
              <p className="text-xl mr-2">{translations?.title}</p>
              <span className="bg-gray-500 rounded-full p-1.5">
                <FaCheck className="text-white" />
              </span>
            </>
          )}
          {organization.registrationPurpose === 'school-registration' && (
            <p className="text-xl mr-2">{translations?.individualBookingsTitle}</p>
          )}
        </div>
        <InfoBox>{translations?.info}</InfoBox>
        <div className="my-8 flex items-center justify-between">
          <div className="grid grid-cols-3 gap-4">
            <div className="flex item-center gap-4">
              <Button
                className="text-black flex items-center justify-center px-4 gap-2"
                type="button"
                backgroundColor="bg-gray-200"
                onClick={() => setNotificationModel(true)}>
                <FaBell />
              </Button>
              <Button
                className="text-black flex items-center justify-center px-4 gap-2"
                type="button"
                backgroundColor="bg-gray-200"
                onClick={() => setColumnsModel(true)}>
                <VisibilityButtonIcon /> {translations?.columns}
              </Button>
              <Button
                className="text-black flex items-center justify-center px-4 gap-2"
                backgroundColor="bg-gray-200"
                type="button"
                onClick={() => setFilterModel(true)}>
                <FunnelIcon /> {translations?.filter}
              </Button>
              <Button
                className="text-black flex items-center justify-center px-4"
                backgroundColor="bg-gray-200"
                type="button"
                onClick={handleDataExport}>
                <ExportIcon className="size-6 mr-1" />
                {translations?.export}
              </Button>
              <Button
                className="text-black flex items-center justify-center px-4"
                backgroundColor="bg-gray-200"
                type="button"
                onClick={() => setShowModel(true)}>
                <CiMail className="font-bold text-xl" />
              </Button>
            </div>
          </div>
          <Button
            className="text-black flex items-center justify-center px-4"
            backgroundColor="bg-gray-200"
            internalLink
            to={`/admin/organization-edit/${id}`}
            type="button">
            {translations?.editOrg}
            <FaAngleRight className="text-xl mr-1" />
          </Button>
          {/* <Button
            className="text-black flex items-center justify-center px-4 uppercase"
            backgroundColor="bg-gray-200"
            type="button"
          >
            Actualisez les données
          </Button> */}
        </div>
        <section className="overflow-x-auto">
          <section className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-200">
                  {filteredHeaders?.map((tableHeader, tableHeaderIndex) => (
                    <th
                      key={tableHeader.key}
                      scope="col"
                      className={clsx(
                        'py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 border-gray-400 whitespace-nowrap border-2',
                        {
                          'pl-3 pr-3': tableHeaderIndex === 0,
                          'px-3 border-2 ': tableHeaderIndex !== 0
                        }
                      )}>
                      {tableHeader.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {filteredClasses.map((classObj) => (
                  <tr key={classObj._id}>
                    {filteredHeaders.map(({ key }, index) => (
                      <td
                        key={key}
                        className={clsx(
                          'whitespace-nowrap text-sm border-2 border-gray-400  text-black',
                          {
                            'bg-gray-200': key === 'teacherName',
                            'px-3 py-4 ': key !== 'teacherName'
                          }
                        )}>
                        <TableCellContent id={id} itemKey={key} classObj={classObj} />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </section>
      </section>
    </main>
  );
}

function TableCellContent({ id, itemKey, classObj }) {
  if (itemKey === 'teacherName') {
    return (
      <Link
        className="block px-3 py-4"
        to={`/admin/edit-class/${classObj?._id}?organization=${id}`}>
        {classObj.teacher?.firstName} {classObj.teacher?.lastName}
      </Link>
    );
  }

  if (['position', 'email', 'phone'].includes(itemKey)) {
    return classObj.teacher?.[itemKey];
  }

  if (['underContract'].includes(itemKey)) {
    return <Badge label={classObj[itemKey]} />;
  }

  // Default rendering for other keys
  return classObj[itemKey];
}

export default OrganizationDetail;
