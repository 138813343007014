import React from 'react';

import { Checkbox, Input } from '../common';

const BusSignupForm = ({ formik, translations }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-12 mt-5 items-start">
      <Input
        inputLight={false}
        label={translations?.companyName?.label}
        placeholder={translations?.companyName?.placeholder}
        name={'companyName'}
        onChange={formik.handleChange}
        error={formik?.errors?.companyName}
        touched={formik?.touched?.companyName}
        value={formik?.values?.companyName}
      />
      <Input
        inputLight={false}
        label={translations?.companyAddress?.label}
        placeholder={translations?.companyAddress?.placeholder}
        name={'companyAddress'}
        onChange={formik.handleChange}
        error={formik?.errors?.companyAddress}
        touched={formik?.touched?.companyAddress}
        value={formik?.values?.companyAddress}
      />
      <Input
        inputLight={false}
        label={translations?.companyPostalcode?.label}
        placeholder={translations?.companyPostalcode?.placeholder}
        name={'companyPostalcode'}
        onChange={formik.handleChange}
        error={formik?.errors?.companyPostalcode}
        touched={formik?.touched?.companyPostalcode}
        value={formik?.values?.companyPostalcode}
      />
      <Input
        inputLight={false}
        label={translations?.companyCity?.label}
        placeholder={translations?.companyCity?.placeholder}
        name={'companyCity'}
        onChange={formik.handleChange}
        error={formik?.errors?.companyCity}
        touched={formik?.touched?.companyCity}
        value={formik?.values?.companyCity}
      />
      <Input
        inputLight={false}
        label={translations?.officePhone?.label}
        placeholder={translations?.officePhone?.placeholder}
        name={'officePhone'}
        onChange={formik.handleChange}
        error={formik?.errors?.officePhone}
        touched={formik?.touched?.officePhone}
        value={formik?.values?.officePhone}
      />
      <Input
        inputLight={false}
        label={translations?.email?.label}
        placeholder={translations?.email?.placeholder}
        name={'email'}
        onChange={formik.handleChange}
        error={formik?.errors?.email}
        touched={formik?.touched?.email}
        value={formik?.values?.email}
      />
      <Input
        inputLight={false}
        label={translations?.eventWhatsappNumber?.label}
        placeholder={translations?.eventWhatsappNumber?.placeholder}
        name={'eventWhatsappNumber'}
        onChange={formik.handleChange}
        error={formik?.errors?.eventWhatsappNumber}
        touched={formik?.touched?.eventWhatsappNumber}
        value={formik?.values?.eventWhatsappNumber}
      />
      <Input
        inputLight={false}
        label={translations?.eventMobileNumber?.label}
        placeholder={translations?.eventMobileNumber?.placeholder}
        name={'eventMobileNumber'}
        onChange={formik.handleChange}
        error={formik?.errors?.eventMobileNumber}
        touched={formik?.touched?.eventMobileNumber}
        value={formik?.values?.eventMobileNumber}
      />
      <Input
        inputLight={false}
        label={translations?.carrierUIDNumber?.label}
        placeholder={translations?.carrierUIDNumber?.placeholder}
        name={'carrierUIDNumber'}
        onChange={formik.handleChange}
        error={formik?.errors?.carrierUIDNumber}
        touched={formik?.touched?.carrierUIDNumber}
        value={formik?.values?.carrierUIDNumber}
      />
      <p dangerouslySetInnerHTML={{ __html: translations?.extraInfoBottom }} />
      <Checkbox
        isInline={true}
        darkLabel={false}
        id={'dataStorageConfirmation'}
        label={translations?.dataStorageConfirmation?.label}
        isChecked={formik?.values?.dataStorageConfirmation}
        onChange={(e) => {
          formik.setFieldValue('dataStorageConfirmation', e.target.checked);
        }}
        touched={formik?.touched?.dataStorageConfirmation}
        error={formik?.errors?.dataStorageConfirmation}
      />
      <Checkbox
        isInline={true}
        darkLabel={false}
        id={'autherizationConfirmation'}
        label={translations?.autherizationConfirmation?.label}
        isChecked={formik?.values?.autherizationConfirmation}
        onChange={(e) => {
          formik.setFieldValue('autherizationConfirmation', e.target.checked);
        }}
        touched={formik?.touched?.autherizationConfirmation}
        error={formik?.errors?.autherizationConfirmation}
      />
    </div>
  );
};

export default BusSignupForm;
