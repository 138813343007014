import React from 'react';
import { useTranslation } from 'react-i18next';

const Dropdown = ({
  showEmptyValue = true,
  options = [],
  label,
  selected,
  inputLight,
  error,
  touched,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative w-full">
      <label className="ml-0">{label}</label>
      <div className="container-clip mt-3">
        <select
          className={`clip-item select-arrow ${
            inputLight
              ? 'bg-white text-black disabled:bg-gray-200'
              : 'bg-blue-999 text-white disabled:bg-gray-900'
          }  p-3 pl-5 disabled:opacity-100`}
          value={selected}
          {...props}>
          {[...(showEmptyValue ? [{ label: '', value: '' }] : []), ...options].map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error && touched && (
          <p className="text text-red-500 absolute" data-testid="error-text">
            {t(error)}
          </p>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
